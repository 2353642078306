import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionSummary, AccordionDetails, Button, Grid, Typography, LinearProgress} from '@material-ui/core';
import {Table} from '@kbi/component-library';
import {ExpandMore, Search} from '@material-ui/icons';
import {ContactModal} from '../views/Contacts/';

class ContactsPanel extends Component {
  state = {
    addContact: false,
    expanded: false,
  };
  render() {
    const {expanded} = this.state;
    const {classes, contacts, currentUser, AccountId, history} = this.props;
    const core = {
      contactTable: {
        columns: [
          {accessor: 'Active', Header: 'Active', type: 'boolean'},
          {accessor: 'Name.Full', Header: 'Name'},
          {accessor: 'Phone.Office', Header: 'Primary Phone'},
          {accessor: 'Email.Work', Header: 'Work Email'},
          {accessor: 'Title', Header: 'Title'},
        ],
        sortInitial: [{id: 'Name.Full', desc: false}],
        data: contacts,
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'View Contact',
            onClick: ({rowData}) => history.push(`/contacts/${rowData.ContactId}`),
          },
        ],
      },
      modalContactNew: {
        currentUser,
        selectedAccountId: AccountId,
        close: () => this.setState({addContact: false}),
      },
      addContactButton: {
        variant: 'outlined',
        onClick: (e) => {
          e.stopPropagation();
          this.setState({addContact: true});
        },
      },
    };

    const details = () => {
      if (!contacts) {
        return <LinearProgress />;
      }
      else if (contacts.length === 0) {
        return (
          <div>
            <AccordionDetails>
              <Grid container justify='space-between'>
                <Typography variant='subtitle1'>No contacts found for this account.</Typography>
              </Grid>
            </AccordionDetails>
          </div>
        );
      }
      else {
        return (
          <AccordionDetails style={{padding: '0px'}}>
            <Grid container>
              <Grid item xs={12}>
                <Table {...core.contactTable} />
              </Grid>
            </Grid>
          </AccordionDetails>
        );
      }
    };

    return (
      <Accordion expanded={expanded} onChange={() => this.setState({expanded: !expanded})}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container justify='space-between'>
            <div>
              <Typography className={classes.heading}>Contacts</Typography>
            </div>
            {expanded ? (
              <div>
                <Button {...core.addContactButton}>Add Contact</Button>
              </div>) : null}
          </Grid>
        </AccordionSummary>
        {details()}
        {this.state.addContact && <ContactModal {...core.modalContactNew} />}
      </Accordion>
    );
  }
}

ContactsPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.object,
  AccountId: PropTypes.string.isRequired,
  AccountName: PropTypes.string.isRequired,
  history: PropTypes.object,
};

export default ContactsPanel;
