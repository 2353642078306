/* eslint-disable no-console */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import green from '@material-ui/core/colors/green';
import {withStyles} from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  CircularProgress,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import {SaveAlt} from '@material-ui/icons';
import EmailCollapse from './EmailCollapse';
import {Functions} from 'config.js';
import FileSaver from 'file-saver';

class EmailDisplayModal extends Component {
  state = {downLoading: {}};

  handleClose() {
    this.props.close();
  };

  handleDownloadClick(messageId, accountId) {
    this.setState({downLoading: {[messageId]: true}});
    Functions.httpsCallable('getRawEmailData')({
      accessToken: this.props.users.find(user => user.nylasId === accountId).nylasAccessToken,
      emailId: messageId,
    })
      .then(res => {
        this.setState({downLoading: {[messageId]: false}});
        const blob = new Blob([res.data], {
          type: 'message/rfc2822 ',
        });
        FileSaver.saveAs(blob, `outlookEmail${messageId}.eml`);
      })
      .catch(err => {
        this.setState({downLoading: {[messageId]: true}});
        console.error('Email download error: ', err);
      });
  };

  render() {
    const {modalOpen, emails, title, classes} = this.props;
    const core = {
      dialog: {
        open: modalOpen,
        scroll: 'paper',
        transitionDuration: {exit: 0},
        maxWidth: 'md',
        fullWidth: true,
      },
    };
    const details = () => {
      if (!emails) {
        return (
          <Fragment>
            <DialogTitle>
              <LinearProgress />
            </DialogTitle>
            <DialogContent />
            <DialogActions>
              <Button onClick={this.handleClose} color='secondary'>
                Cancel
              </Button>
            </DialogActions>
          </Fragment>
        );
      }
      else if (emails.length === 0) {
        return (
          <Fragment>
            <DialogTitle>{`Subject: ${title}`}</DialogTitle>
            <DialogContent>
              <Typography variant='body1'>This email chain has been deleted by the owner.</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleClose()} color='primary'>
                Back
              </Button>
            </DialogActions>
          </Fragment>
        );
      }
      else {
        return (
          <Fragment>
            <DialogTitle>{`Subject: ${title}`}</DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                {emails.map((email, i) => (
                  <Fragment key={i}>
                    <Grid item sm={1} xs={6}>
                      <Tooltip style={{height: 48}} title='Download Email'>
                        <Button onClick={() => this.handleDownloadClick(email.id, email['account_id'])}>
                          <SaveAlt />
                          {this.state.downLoading[email.id] && (
                            <CircularProgress size={24} className={classes.buttonProgress} />
                          )}
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item sm={11} xs={12}>
                      <EmailCollapse email={email} />
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleClose()} color='primary'>
                Back
              </Button>
            </DialogActions>
          </Fragment>
        );
      }
    };
    return <Dialog {...core.dialog}>{details()}</Dialog>;
  }
}

const styles = theme => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

EmailDisplayModal.propTypes = {
  close: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  emails: PropTypes.array,
  title: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EmailDisplayModal);
