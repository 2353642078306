/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, {useEffect, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Collapse,
  MenuItem,
} from '@material-ui/core';
import {SubmitConfirmation} from 'components/';
import {Alert, Formik} from '@kbi/component-library';
import {withFormik} from 'formik';
import {object, string} from 'yup';
import {Firestore} from 'config.js';
const {SelectField, FormButton, SubmitButton} = Formik;

const stageArray = ['basic', 'success'];
const reportOptions = ['Profiles Pending Approval'];

const NewReportModal = ({values, open, close, changeView, ...formikBag}) => {
  const [listOfQueryResults, setlistOfQueryResults] = useState(null);
  const [resultsFromSubcollectionQueries] = useState([]);
  const [filteredListOfQueries, setFilteredListOfQueries] = useState([]);
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');

  const compareResultsWithSubcollectionResults = useCallback(() => {
    if (listOfQueryResults) {
      // loops over the query results, and compares them against the subcollection query results.
      // if they are not equal, it removes them from the filterd list of queries
      const filteredArray = [...listOfQueryResults];
      resultsFromSubcollectionQueries.forEach(result => {
        for (let index = 0; index < filteredArray.length; index++) {
          if (values.reportType === 'inventoryItems') {
            if (filteredArray[index].generatorId !== result &&
              filteredArray[index].PurchaseOrderRef !== result &&
              filteredArray[index].ShippingDocRef !== result) {
              filteredArray.splice(index, 1);
              index--;
            }
          }
          else {
            if (listOfQueryResults[index].id !== result) {
              filteredArray.splice(index, 1);
              index--;
            }
          }
        }
      });
      setFilteredListOfQueries(filteredArray);
    }
  }, [listOfQueryResults, resultsFromSubcollectionQueries, values.reportType]);
  useEffect(compareResultsWithSubcollectionResults, [listOfQueryResults]);
  const changeModalStage = useCallback(() => {
    if (listOfQueryResults) {
      formikBag.setSubmitting(false);
      if (!filteredListOfQueries.length) {
        setFormError('The report you tried to generate did not contain any documents.');
        formikBag.setStatus({});
      }
      else {
        setStage(stage + 1);
        setFormError('');
      }
    }
  }, [filteredListOfQueries.length, formikBag, listOfQueryResults, stage]);
  useEffect(changeModalStage, [filteredListOfQueries]);
  useEffect(() => {
    if (stage === 0 && formikBag.status && formikBag.status.success) {
      nonFormikSubmit(values);
    }
  }, [formikBag.status, stage, values]);

  const profilesPendingApprovalQuery = useCallback(() => {
    const queryRef = Firestore.collectionGroup('Material-Profiles').where('ApprovedBy', '==', '').where('UploadedBy.Name', '>', '');
    return queryRef;
  }, []);

  const sendQuery = useCallback(queryRef => {
    queryRef.get().then(snap => {
      const arrayOfResults = [];
      snap.forEach(doc => {
        let formattedItem = null;
        const item = doc.data();
        if (values.reportType === 'Profiles Pending Approval') {
          formattedItem = {
            ...item,
            id: doc.id,
            EffectiveDate: item.EffectiveDate.toDate(),
            Expiration: item.Expiration.toDate(),
            accountId: doc.ref.parent.parent.id,
          };
        }
        arrayOfResults.push(formattedItem);
      });
      if (!arrayOfResults.length) {
        setFormError('No items matched the report you created. Please try again with a different report.');
      }
      setlistOfQueryResults(arrayOfResults);
    });
  }, [values.reportType]);
  const handleClose = () => {
    if (filteredListOfQueries?.length) changeView({view: values.reportType, data: filteredListOfQueries});
    close();
  };
  const nonFormikSubmit = useCallback(async (formikValues) => {
    let queryRef = null;
    if (formikValues.reportType === 'Profiles Pending Approval') {
      queryRef = profilesPendingApprovalQuery();
    }
    sendQuery(queryRef);
  }, [profilesPendingApprovalQuery, sendQuery]);

  const core = {
    dialog: {
      open: open,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
    },
    submitConfirmation: {
      text: 'Report successfully created.',
      stage: stageArray[stage],
    },
  };

  return (
    <Dialog {...core.dialog}>
      {stageArray[stage] !== 'success' && <DialogTitle>New Report</DialogTitle>}
      <DialogContent>
        <Collapse in={stageArray[stage] === 'basic'}>
          <DialogContentText>Select fields to create a custom report.</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectField required name='reportType' label='Report Type'>
                {reportOptions.map((optionString, index) => (
                  <MenuItem key={index} value={optionString}>{optionString}</MenuItem>
                ))}
              </SelectField>
            </Grid>
          </Grid>
        </Collapse>
        <SubmitConfirmation {...core.submitConfirmation} />
        <Alert in={Boolean(formError)} text={formError} severity='error' />
      </DialogContent>
      {stageArray[stage] === 'success' ? (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <FormButton variant='text' color='primary' onClick={handleClose}>Close</FormButton>
        </DialogActions>
      ) : (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <FormButton variant='text' color='secondary' onClick={handleClose}>Cancel</FormButton>
          <div>
            <SubmitButton color='primary' variant='text' onClick={formikBag.handleSubmit}>
              Submit
            </SubmitButton>
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};

NewReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  changeView: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  queryParams: PropTypes.object,
};

export default withFormik({
  mapPropsToValues: props => ({
    reportType: '',
  }),
  validationSchema: object().shape({
    reportType: string().required('Report Type is a required field.'),
  }),
  handleSubmit: (values, {...actions}) => {
    actions.setStatus({success: true});
  },
})(NewReportModal);
