import React, {Fragment} from 'react';
import {Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

const SupplementalPricingTable = props => {
  const {selectedSupplementalPricing, classes, StyledTypography} = props;
  const materials = useSelector(state => state.firestore.materials);
  const types = useSelector(state => state.firestore.types);
  // this flattens array of all materials and their respective types into an array of arrays
  // [[materialRef, typeRef], [materialRef, typeRef]...]
  const combinedArrayToRender = Object.entries(selectedSupplementalPricing).map(materialTypeArray => {
    const combinedArray = [];
    materialTypeArray[1].forEach(typeId => {
      combinedArray.push([materialTypeArray[0], typeId]);
    });
    return combinedArray;
  }).reduce((a, b) => a.concat(b), []);

  function createTypeList(materialTypeArray, index) {
    const typeRef = types[materialTypeArray[0]].find(type => type.id === materialTypeArray[1]);
    return (
      <Grid container className={classes.avoidPageBreak} key={index}>
        <Grid item xs={4} className={`${classes.borderBottom} ${classes.borderRight}`}>
          <StyledTypography variant='body2'>
            {materials.ref[materialTypeArray[0]].UnitDetails.MaterialName}
          </StyledTypography>
        </Grid>
        <Grid item xs={4} className={`${classes.borderBottom} ${classes.borderRight}`}>
          <StyledTypography variant='body2'>{typeRef.TypeName}</StyledTypography>
        </Grid>
        <Grid item xs={2} className={`${classes.borderBottom} ${classes.borderRight}`}>
          <StyledTypography variant='body2'>{typeRef.BillType}</StyledTypography>
        </Grid>
        <Grid item xs={2} className={classes.borderBottom}>
          <StyledTypography variant='body2'>
            {!isNaN(typeRef.Price) && '$'}
            {typeRef.Price}
          </StyledTypography>
        </Grid>
      </Grid>
    );
  }
  const createTableContainer = (pageIndex) => {
    const tableCore = {
      pageDelimeterProps: {
        className: pageIndex === 0 ? '' : 'newPage',
      },
    };
    return (
      <div {...tableCore.pageDelimeterProps}>
        <div
          className={`${classes.borderTop} ${classes.borderLeft} ${classes.newSection} ${classes.borderRight} ${classes.textChildrenPadding}`}
        >
          <Grid item xs={12}>
            <StyledTypography variant='subtitle2' className={`${classes.bold} ${classes.titleSection}`}>
              {pageIndex === 0 ? 'Default Pricing Details' : 'Default Pricing Details Continued'}
            </StyledTypography>
          </Grid>
          <Grid container className={classes.tableHeader}>
            <Grid item xs={4} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Material
              </StyledTypography>
            </Grid>
            <Grid item xs={4} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Type
              </StyledTypography>
            </Grid>
            <Grid item xs={2} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Bill Type
              </StyledTypography>
            </Grid>
            <Grid item xs={2} className={classes.borderBottom}>
              <StyledTypography variant='body2' className={classes.bold}>
                Amount
              </StyledTypography>
            </Grid>
          </Grid>
          {combinedArrayToRender.slice((pageIndex * 38), (pageIndex === 0 ? 37 : Infinity)).map((materialTypeArray, index) => {
            return createTypeList(materialTypeArray, index);
          })}
        </div>
      </div>
    );
  };
  const renderAllPages = () => {
    if (combinedArrayToRender.length > 37) {
      return [createTableContainer(0), <div key='randomKeyString' className={classes.pageBreak} />, createTableContainer(1)];
    }
    else {
      return createTableContainer(0);
    }
  };

  return (
    <Fragment>
      <div className='newPage'>
        <StyledTypography variant='h6' className={`${classes.newSection}`} paragraph>
          SUPPLEMENTAL MATERIAL FEES
        </StyledTypography>
        <StyledTypography variant='body2'>
          In the event KBI identifies materials shipped or delivered by Customer, pursuant to this Purchase Order, that
          are not listed and priced in this Purchase Order, then the below Supplemental Material Fees shall apply.
        </StyledTypography>
      </div>
      {renderAllPages()}
    </Fragment>
  );
};

SupplementalPricingTable.propTypes = {
  selectedSupplementalPricing: PropTypes.objectOf(PropTypes.array).isRequired,
  classes: PropTypes.object.isRequired,
  StyledTypography: PropTypes.object.isRequired,
};

export default SupplementalPricingTable;
