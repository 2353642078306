import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Formik, Alert} from '@kbi/component-library';
import {formatSingleLineAddress, formatDoubleLineAddress, formatTripleLineAddress} from '@kbi/utility-library';
import {SubmitConfirmation} from 'components/';
import {Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Grid, Button, MenuItem} from '@material-ui/core';
import {AddressForm} from 'components/';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {countries} from '../../views/Accounts/lists.js';
import {Firestore} from 'config.js';

const {FormikForm, SubmitButton, FormButton, TextField, AutoComplete, RadioGroup,
  PhoneNumberField, validatePhoneField, SelectField} = Formik;
const epaIdRegex = /^[a-zA-Z]{2}[a-zA-Z0-9]{1}\d{9}$/;
validatePhoneField();

const LocationInfoModalFormik = ({open, close, selectedLocation, currentAccount}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const currentUser = useSelector(state => state.auth.currentUser);

  const handleBack = () => {
    return setStage(stage - 1);
  };
  const dialogProps = {
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
    fullWidth: true,
  };
  const submitConfirmation = {
    text: selectedLocation ? 'Location successfully updated.' : 'Location successfully added.',
    stage: stage === 2 ? 'success' : 'not success',
  };
  const formikProps = {
    initialValues: {
      locationName: selectedLocation?.LocationName || '',
      phonePrimary: selectedLocation?.Phone.Primary || '',
      phoneEmergency: selectedLocation?.Phone.Emergency ||'',
      phoneFax: selectedLocation?.Phone.Fax || '',
      addressCountry: selectedLocation?.Address.Country || 'United States',
      addressLine1: selectedLocation?.Address.Line1 || '',
      addressLine2: selectedLocation?.Address.Line2 || '',
      addressCity: selectedLocation?.Address.City || '',
      addressState: selectedLocation?.Address.State || '',
      addressPostal: selectedLocation?.Address.PostalCode || '',
      addressProvince: selectedLocation?.Address.Province || '',
      addressPrefecture: selectedLocation?.Address.Prefecture || '',
      addressUnformatted: selectedLocation?.Address.SingleLine || '',
      active: selectedLocation?.Active !== undefined ? selectedLocation.Active : true,
      primaryLocation: selectedLocation?.PrimaryLocation !== undefined ? selectedLocation.PrimaryLocation : true,
      epaId: selectedLocation?.EpaId || '',
      naicsId: selectedLocation?.NaicsId || '',
      sicId: selectedLocation?.SidIc || '',
      firstOrThirdParty: selectedLocation?.FirstOrThirdParty || 'First',
    },
    validationSchema: (() => {
      if (stage === 0) {
        return yup.object().shape({
          active: yup.boolean().required('Active is a required field.'),
          firstOrThirdParty: yup.string().required('You must choose first or third party.'),
          locationName: yup.string().required('Location Name is a required field.'),
          addressCountry: yup.string().required('Country is a required field.'),
          addressLine1: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico' || value === 'Japan' || value === 'South Korea' || value === 'Canada',
            then: yup.string().required('Address Line 1 is a required field.'),
            otherwise: yup.string(),
          }),
          addressLine2: yup.string(),
          addressCity: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico' || value === 'South Korea' || value === 'Canada',
            then: yup.string().required('City is a required field.'),
            otherwise: yup.string(),
          }),
          addressState: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico',
            then: yup.string().required('State is a required field.'),
            otherwise: yup.string(),
          }),
          addressPostal: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico' || value === 'Canada',
            then: yup.string().required('Postal is a required field.'),
            otherwise: yup.string(),
          }),
          addressProvince: yup.string().when('addressCountry', {
            is: value => value === 'Japan' || value === 'South Korea' || value === 'Canada',
            then: yup.string().required('Province is a required field.'),
            otherwise: yup.string(),
          }),
          addressSubdivision: yup.string().when('addressCountry', {
            is: value => value === 'South Korea',
            then: yup.string().required('Subdivision is a required field.'),
            otherwise: yup.string(),
          }),
          addressPrefecture: yup.string().when('addressCountry', {
            is: value => value === 'Japan',
            then: yup.string().required('Prefecture is a required field.'),
            otherwise: yup.string(),
          }),
          addressUnformatted: yup.string().when('addressCountry', {
            is: value => value !== 'United States' && value !== 'Mexico' && value !== 'Japan' && value !== 'South Korea' && value !== 'Canada',
            then: yup.string().required('Address is a required field.'),
            otherwise: yup.string(),
          }),
        });
      }
      else if (stage === 1) {
        return yup.object().shape({
          primaryLocation: yup.boolean().required('Headquarters is a required field.'),
          phonePrimary: yup.string().validPhoneNumber('Must be a valid phone number.'),
          phoneFax: yup.string().validPhoneNumber('Must be a valid phone number.'),
          phoneEmergency: yup.string().validPhoneNumber('Must be a valid phone number.'),
          epaId: yup.string().required('EPA Id is a required field. (N/A is accepted).').test({
            name: 'format epaid',
            test: value => {
              if (value) {
                if (value === 'N/A') return true;
                else if (epaIdRegex.test(value)) return true;
                else return false;
              }
              else return true;
            },
            message: 'EPA Id is not formatted correctly.',
          }),
        });
      }
    })(),
    onSubmit: (values, actions) => {
      if (stage === 0) {
        setStage(stage + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
        return;
      }
      const dataToSubmit = createDataForFirestore(values, currentAccount);
      let locationRef = Firestore.collection('CRM-Accounts')
        .doc(currentAccount.AccountId)
        .collection('Locations');

      selectedLocation ? locationRef = locationRef.doc(selectedLocation.id) : locationRef = locationRef.doc();
      if (selectedLocation) {
        dataToSubmit.System = {
          ...selectedLocation.System,
          ModifiedOn: new Date(),
          ModifiedBy: currentUser.displayName,
        };
      }
      else {
        dataToSubmit.System = {
          CreatedBy: currentUser.displayName,
          CreatedOn: new Date(),
        };
      }

      locationRef.set(dataToSubmit, {merge: true}).then(() => {
        actions.setSubmitting(false);
        setStage(stage + 1);
      }).catch(error => {
        actions.setSubmitting(false);
        setFormError('There was an error during submission. Please try again.');
      });
    },
  };
  const countryField = {
    name: 'addressCountry',
    label: 'Country',
    required: true,
    options: countries,
    optionKey: 'value',
    onChange: ({form}) => {
      form.setFieldValue('addressLine1', '');
      form.setFieldValue('addressLine2', '');
      form.setFieldValue('addressCity', '');
      form.setFieldValue('addressState', '');
      form.setFieldValue('addressPostal', '');
      form.setFieldValue('addressProvince', '');
      form.setFieldValue('addressSubdivision', '');
      form.setFieldValue('addressPrefecture', '');
      form.setFieldValue('addressUnformatted', '');
    },
  };

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formikProps}>
        {() => (
          <Fragment>
            {stage !== 2 && <DialogTitle>{selectedLocation ? 'Edit Location' : 'Add New Location'}</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <RadioGroup name='firstOrThirdParty' label='First or Third Party?' radios={radioOptions} />
                  </Grid>
                  {selectedLocation && <Grid item xs={3}>
                    <SelectField name='active' label='Active'>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </SelectField>
                  </Grid>}
                  <Grid item xs={selectedLocation ? 9 : 12}>
                    <TextField name='locationName' label='Location Name' />
                  </Grid>
                  <Grid item xs={12}>
                    <AutoComplete {...countryField} />
                  </Grid>
                  <AddressForm />
                </Grid>
              </Collapse>
              <Collapse in={stage === 1}>
                <DialogContentText>Enter contact information for location:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <SelectField name='primaryLocation' label='Headquarters?'>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneNumberField name='phonePrimary' label='Primary No.' />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneNumberField name='phoneFax' label='Fax No.' />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneNumberField name='phoneEmergency' label='Emergency No.' />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField name='epaId' label='EPA ID' required />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField name='naicsId' label='NAICS' />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField name='sicId' label='SIC' />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} />
            </DialogContent>
            {stage === 2 ? (
              <DialogActions>
                <Button variant='text' onClick={close} color='primary'>Close</Button>
              </DialogActions>
            ) : (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <div>
                  <FormButton variant='text' color='primary' disabled={stage === 0} onClick={handleBack}>Back</FormButton>
                  <SubmitButton variant='text' color='primary'>{stage === 1 ? 'Submit' : 'Next'}</SubmitButton>
                </div>
              </DialogActions>
            )}

          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

const radioOptions = [
  {label: 'First Party - A location directly owned and managed by the account', value: 'First'},
  {label: 'Third Party - A location managed, but not owned by the account', value: 'Third'},
];
function createDataForFirestore(values, currentAccount) {
  const submitData = {
    LocationName: values.locationName,
    Active: values.active,
    Phone: {
      Primary: values.phonePrimary,
      Fax: values.phoneFax,
      Emergency: values.phoneEmergency,
    },
    FirstOrThirdParty: values.firstOrThirdParty,
    PrimaryLocation: values.primaryLocation,
    EpaId: values.epaId,
    SicId: values.sicId,
    NaicsId: values.naicsId,
    AccountRef: currentAccount.AccountId,
  };
  if (values.addressCountry === 'United States' || values.addressCountry === 'Mexico') {
    submitData.Address = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      City: values.addressCity,
      State: values.addressState,
      PostalCode: values.addressPostal,
      Country: values.addressCountry,
    };
  }
  else if (values.addressCountry === 'Japan') {
    submitData.Address = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      Prefecture: values.addressPrefecture,
      Province: values.addressProvince,
      Country: values.addressCountry,
    };
  }
  else if (values.addressCountry === 'South Korea') {
    submitData.Address = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      City: values.addressCity,
      Subdivision: values.addressSubdivision,
      Province: values.addressProvince,
      Country: values.addressCountry,
    };
  }
  else if (values.addressCountry === 'Canada') {
    submitData.Address = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      City: values.addressCity,
      Province: values.addressProvince,
      PostalCode: values.addressPostal,
      Country: values.addressCountry,
    };
  }
  else {
    submitData.Address = {
      Unformatted: values.addressUnformatted,
      Country: values.addressCountry,
    };
  }
  submitData.Address.SingleLine = formatSingleLineAddress(submitData.Address);
  submitData.Address.DoubleLine = formatDoubleLineAddress(submitData.Address);
  submitData.Address.TripleLine = formatTripleLineAddress(submitData.Address);
  return submitData;
}

LocationInfoModalFormik.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedLocation: PropTypes.object,
  currentAccount: PropTypes.object.isRequired,
};

export default LocationInfoModalFormik;
