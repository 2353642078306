import {Auth} from 'config.js';
import {SET_CURRENT_USER, UNSET_CURRENT_USER} from './types.js';

export const acGetCurrentUser = dispatch => {
  return new Promise((resolve, reject) => {
    if (Auth.currentUser) {
      Auth.currentUser.getIdTokenResult().then(idTokenResult => {
        const currentUser = {
          email: idTokenResult.claims.email,
          displayName: idTokenResult.claims.name,
          roleCatalyst: idTokenResult.claims.roleCatalyst,
          roleEHS: idTokenResult.claims.roleEHS,
          roleCRM: idTokenResult.claims.roleCRM,
          rolePortalAdmin: idTokenResult.claims.rolePortalAdmin,
          roleTracking: idTokenResult.claims.roleTracking,
          outlookUser: idTokenResult.claims.outlookUser,
          nylasAccessToken: idTokenResult.claims.nylasAccessToken,
          nylasId: idTokenResult.claims.nylasId,
          nylasFolderId: idTokenResult.claims.nylasFolderId,
          token: idTokenResult.token,
          uid: idTokenResult.claims.user_id,
        };
        dispatch({type: SET_CURRENT_USER, payload: currentUser});
        resolve();
      });
    }
    else {
      reject(new Error());
    }
  });
};
export const acLogoutUser = () => {
  return {type: UNSET_CURRENT_USER};
};
