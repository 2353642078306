import React, {Fragment} from 'react';
import {Formik} from '@kbi/component-library';
import {Grid, MenuItem} from '@material-ui/core';
import {
  unitesStatesStates,
  mexicoStates,
  southKoreaProvinces,
  canadaProvinces,
  prefectures,
} from '../views/Accounts/lists';
import {useFormikContext} from 'formik';
const {AutoComplete, TextField, SelectField} = Formik;

const AddressForm = props => {
  const {values} = useFormikContext();
  const core = {
    autoUnitesStates: {
      optionKey: 'value',
      label: 'State',
      autoSelect: true,
      required: true,
      options: unitesStatesStates,
      name: 'addressState',
    },
    autoMexicoStates: {
      optionKey: 'value',
      label: 'State',
      autoSelect: true,
      required: true,
      options: mexicoStates,
      name: 'addressState',
    },
    autoJapan: {
      optionKey: 'value',
      label: 'Prefecture',
      autoSelect: true,
      required: true,
      options: prefectures,
      name: 'addressPrefecture',
    },
  };
  return (
    <Fragment>
      {values.addressCountry === 'United States' || values.addressCountry === 'Mexico' ? (
        <Fragment>
          <Grid item xs={12} sm={6}>
            <TextField name='addressLine1' label='Address Line 1' required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name='addressLine2' label='Line 2' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField name='addressCity' label='City' required />
          </Grid>
          <Grid item xs={12} sm={4}>
            {values.addressCountry === 'United States' ? (
              <AutoComplete {...core.autoUnitesStates} />
            ) : (
              <AutoComplete {...core.autoMexicoStates} />
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField name='addressPostal' label='Postal' required />
          </Grid>
        </Fragment>
      ) : values.addressCountry === 'Canada' ? (
        <Fragment>
          <Grid item xs={12} sm={6}>
            <TextField name='addressLine1' label='Address Line 1' required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name='addressLine2' label='Line 2' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField name='addressCity' label='City' required />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectField name='addressProvince' required label='Province'>
              {canadaProvinces.map(province => (
                <MenuItem key={province} value={province}>
                  {province}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField name='addressPostal' label='Postal' required />
          </Grid>
        </Fragment>
      ) : values.addressCountry === 'South Korea' ? (
        <Fragment>
          <Grid item xs={12} sm={6}>
            <TextField name='addressLine1' label='Address Line 1' required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name='addressLine2' label='Line 2' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField name='addressSubdivision' label='Subdivision' required />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField name='addressCity' label='City' required />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectField name='addressProvince' label='Province' select required>
              {southKoreaProvinces.map(province => (
                <MenuItem key={province} value={province}>
                  {province}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        </Fragment>
      ) : values.addressCountry === 'Japan' ? (
        <Fragment>
          <Grid item xs={12} sm={6}>
            <TextField name='addressLine1' label='Address Line 1' required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name='addressLine2' label='Line 2' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoComplete {...core.autoJapan} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name='addressProvince' label='Province' required />
          </Grid>
        </Fragment>
      ) : values.addressCountry ? (
        <Fragment>
          <Grid item xs={12}>
            <TextField name='addressUnformatted' label='Address' required />
          </Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default AddressForm;
