import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
} from '@material-ui/core';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {Alert} from '@kbi/component-library';
import {connect} from 'react-redux';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';

const cleanState = {
  fileName: {
    display: 'Name',
    error: null,
    value: '',
  },
  fileOwner: '',
  fileDate: '',
  submitting: false,
  deleteConfirmation: false,
  deleting: false,
  formError: null,
  stage: 'basic',
};

class ViewFileDetailsModal extends Component {
  state = {
    ...cleanState,
  };
  componentDidMount() {
    const {fileName} = this.state;
    const {selectedFile} = this.props;
    this.setState({
      fileName: {...fileName, value: selectedFile.FileName},
      fileDate: selectedFile.Date,
      fileOwner: selectedFile.Owner.Name,
    });
  }
  userAuthorization() {
    if (
      this.props.currentUser.uid === this.props.selectedFile.Owner.Uid ||
      this.props.currentUser.roleCRM === 'Admin'
    ) {
      // if this conditional is passed, the user does have access to changing the note.
      return false;
    }
    else return true;
  }
  handleClose() {
    this.setState({...cleanState});
    this.props.close();
  };
  handleDeleteConfirm() {
    // there is no way here to delete the document in the files collection, as well as the file in the bucket at the same time.
    // due to this, I delete the reference to it on the account, so users dont have a link to a file that doesnt exist.
    // this leads to the possibility that during the delete that the file was left, and it has no reference in an account, and is an unused file in the bucket
    this.setState({deleting: true});
    Firestore.collection('CRM-Accounts')
      .doc(this.props.currentAccountId)
      .collection('Files')
      .doc(this.props.selectedFile.id)
      .delete()
      .then(() => {
        firebase
          .storage()
          .ref()
          .child(`Account_Files/${this.props.selectedFile.id}.${this.props.selectedFile.FileExt}`)
          .delete()
          .then(() => {
            this.setState({deleting: false, stage: 'success'});
          });
      })
      .catch(error => {
        this.setState({formError: 'There was an error deleting the note.'});
      });
  };
  handleSubmitEditClick() {
    const {fileName} = this.state;
    if (!fileName.value) {
      this.setState({fileName: {...fileName, error: 'File Name is a required field.'}});
      return;
    }
    this.setState({submitting: true});
    Firestore.collection('CRM-Accounts')
      .doc(this.props.currentAccountId)
      .collection('Files')
      .doc(this.props.selectedFile.id)
      .update({
        FileName: this.state.fileName.value.trim(),
        LastEdit: {
          LastEditDate: firebase.firestore.Timestamp.now(),
          LastEditUser: {
            Name: this.props.currentUser.displayName,
            Uid: this.props.currentUser.uid,
          },
        },
      })
      .then(() => {
        this.setState({submitting: false, stage: 'success'});
      })
      .catch(error => {
        this.setState({formError: 'There was an error updating the note.'});
      });
  };
  determineButtonsToShow() {
    const {stage, deleteConfirmation, submitting, deleting} = this.state;
    const core = {
      deleteButton: {
        color: 'secondary',
        onClick: () => {
          this.setState({deleteConfirmation: true});
        },
      },
      confirmButton: {
        color: 'secondary',
        onClick: () => this.handleDeleteConfirm(),
        text: 'Confirm?',
        loading: deleting,
        disabled: submitting,
      },
      cancelButton: {
        color: 'primary',
        onClick: () => this.handleClose(),
      },
      submitButton: {
        text: 'Submit',
        loading: submitting,
        color: 'primary',
        onClick: () => this.handleSubmitEditClick(),
        disabled: deleting,
      },
    };
    if (stage === 'success') {
      return (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <Button {...core.cancelButton}>Close</Button>
        </DialogActions>
      );
    }
    if (this.userAuthorization()) {
      return (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <Button {...core.cancelButton}>Cancel</Button>
        </DialogActions>
      );
    }
    else {
      if (deleteConfirmation) {
        return (
          <DialogActions style={{justifyContent: 'space-between'}}>
            <SubmitButton {...core.confirmButton} />
            <div>
              <Button {...core.cancelButton}>Cancel</Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </DialogActions>
        );
      }
      else {
        return (
          <DialogActions style={{justifyContent: 'space-between'}}>
            <Button {...core.deleteButton}>Delete</Button>
            <div>
              <Button {...core.cancelButton}>Cancel</Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </DialogActions>
        );
      }
    }
  }
  render() {
    const {stage, deleteConfirmation, fileName} = this.state;
    const {viewDetailsModalOpen, selectedFile} = this.props;
    const core = {
      dialog: {
        open: viewDetailsModalOpen,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      submitConfirmation: {
        text: deleteConfirmation ? 'File successfully deleted.' : 'File successfully updated.',
        stage,
        delete: deleteConfirmation,
      },
      fileOwner: {
        label: 'Owner',
        fullWidth: true,
        value: selectedFile.Owner.Name,
        disabled: true,
      },
      fileDate: {
        label: 'Date',
        disabled: true,
        fullWidth: true,
        value: moment(selectedFile.Date).format('MM/DD/YYYY'),
      },
      fileName: {
        label: fileName.display,
        error: fileName.error ? true : false,
        helperText: fileName.error,
        disabled: this.userAuthorization(),
        fullWidth: true,
        required: true,
        value: fileName.value,
        onChange: e => {
          this.setState({
            fileName: {...fileName, value: e.target.value, error: null},
          });
        },
      },
    };
    return (
      <Dialog {...core.dialog}>
        {stage !== 'success' && <DialogTitle>View File</DialogTitle>}
        <DialogContent>
          <Collapse in={stage === 'basic'}>
            <DialogContentText>Edit or delete the file</DialogContentText>
            <Fragment>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField {...core.fileOwner} />
                </Grid>
                <Grid item xs={4}>
                  <TextField {...core.fileDate} />
                </Grid>
                <Grid item xs={12}>
                  <TextField {...core.fileName} />
                </Grid>
              </Grid>
              <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
            </Fragment>
          </Collapse>
          <SubmitConfirmation {...core.submitConfirmation} />
        </DialogContent>
        {this.determineButtonsToShow()}
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const {currentUser} = state.auth;
  return {currentUser};
};

ViewFileDetailsModal.propTypes = {
  currentUser: PropTypes.object.isRequired,
  selectedFile: PropTypes.object.isRequired,
  viewDetailsModalOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  currentAccountId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(ViewFileDetailsModal);
