import React, {Fragment} from 'react';
import {Grid} from '@material-ui/core';
import KbiLogo from 'media/KBI-logo(small).png';
import moment from 'moment';
import PropTypes from 'prop-types';

const InformationSection = props => {
  const {primaryLocation, selectedPurchaseOrder, classes, StyledTypography} = props;
  return (
    <Fragment>
      <Grid container justify='space-between' className={`firstPage ${classes.newSection}`}>
        <Grid item xs={9}>
          <img alt='KBI logo' src={KbiLogo} style={{height: '80px'}} />
        </Grid>
        <Grid item xs={3} md={2}
          container justify='flex-start' direction='column'
          alignItems='flex-end'
        >
          <StyledTypography variant='body2' className={classes.bold}>
            Purchase Order
          </StyledTypography>
          <StyledTypography variant='body2'>125 E Commercial St. A</StyledTypography>
          <StyledTypography variant='body2'>Anaheim, CA 92801</StyledTypography>
          <StyledTypography variant='body2'>www.kbirecycling.com</StyledTypography>
        </Grid>
      </Grid>
      <Grid container className={`${classes.newSection} ${classes.paddingLeft}`}>
        <Grid item xs={4} className={`${classes.borderLeft} ${classes.borders}`}>
          <Grid item xs={12} className={`${classes.textChildrenPadding} ${classes.titleSection}`}>
            <StyledTypography variant='subtitle2' className={classes.bold}>
              Customer Information
            </StyledTypography>
          </Grid>
          <StyledTypography variant='body2'>{primaryLocation?.LocationName || 'There is no primary location on file.'}</StyledTypography>
          <StyledTypography variant='body2'>{primaryLocation?.Address.DoubleLine.Line1}</StyledTypography>
          <StyledTypography variant='body2'>{primaryLocation?.Address.DoubleLine.Line2}</StyledTypography>
          {primaryLocation?.Phone && primaryLocation.Phone.Primary && (
            <StyledTypography variant='body2'>Phone: {primaryLocation.Phone.Primary}</StyledTypography>
          )}
          {primaryLocation?.Phone && primaryLocation.Phone.Fax && (
            <StyledTypography variant='body2'>Fax: {primaryLocation.Phone.Fax}</StyledTypography>
          )}
        </Grid>
        <Grid item xs={4} className={classes.borders}>
          <Grid item xs={12} className={`${classes.textChildrenPadding} ${classes.titleSection}`}>
            <StyledTypography variant='subtitle2' className={classes.bold}>
              Billing Information
            </StyledTypography>
          </Grid>
          <StyledTypography variant='body2'>{selectedPurchaseOrder.BillingLocation.LocationName}</StyledTypography>
          <StyledTypography variant='body2'>Attn: {selectedPurchaseOrder.ContactName}</StyledTypography>
          <StyledTypography variant='body2'>
            {selectedPurchaseOrder.BillingLocation.Address.DoubleLine.Line1}
          </StyledTypography>
          <StyledTypography variant='body2'>
            {selectedPurchaseOrder.BillingLocation.Address.DoubleLine.Line2}
          </StyledTypography>
        </Grid>
        <Grid item xs={4} className={classes.borders}>
          <Grid item xs={12} className={`${classes.textChildrenPadding} ${classes.titleSection}`}>
            <StyledTypography variant='subtitle2' className={classes.bold}>
              Purchase Order
            </StyledTypography>
          </Grid>
          <StyledTypography variant='body2'>Order Number: {selectedPurchaseOrder.OrderNumber}</StyledTypography>
          <StyledTypography variant='body2'>
            Effective: {moment(selectedPurchaseOrder.EffectiveDate).format('MM/DD/YYYY')}
          </StyledTypography>
          <StyledTypography variant='body2'>
            Expiration: {moment(selectedPurchaseOrder.ExpirationDate).format('MM/DD/YYYY')}
          </StyledTypography>
          {selectedPurchaseOrder.LoadLimit && (
            <StyledTypography variant='body2'>Load Limit: {selectedPurchaseOrder.LoadLimit}</StyledTypography>
          )}
          <StyledTypography variant='body2'>Terms: {selectedPurchaseOrder.Terms}</StyledTypography>
          <StyledTypography variant='body2'>
            FOB: {selectedPurchaseOrder.FOB}
          </StyledTypography>
          <StyledTypography variant='body2'>
            Type: {selectedPurchaseOrder.POType === 'Sale' ? 'Sale' : 'Purchase'}
          </StyledTypography>
          <StyledTypography variant='body2' paragraph>
            Sales Contact: {selectedPurchaseOrder.System?.CreatedBy?.Name}
          </StyledTypography>
        </Grid>
      </Grid>
      <Grid container className={`${classes.textChildrenPadding} ${classes.newSection}`}>
        <Grid item xs={12} className={`${classes.borderLeft} ${classes.borders}`}>
          <Grid item xs={12} className={classes.titleSection}>
            <StyledTypography variant='subtitle2' className={classes.bold}>
              Comments
            </StyledTypography>
          </Grid>
          <StyledTypography variant='body2' paragraph>
            All shipping documents (i.e. bill of lading, manifest, etc.) must include the PO Number referenced above to
            ensure proper billing. Materials received with shipping documents lacking a reference PO Number may be
            rejected if proper billing information cannot be determined. KBI’s terms and conditions are attached hereto
            and included by reference.
          </StyledTypography>
          {selectedPurchaseOrder.AdditionalComments && (
            <Fragment>
              <StyledTypography variant='subtitle2' className={classes.bold}>
                Additional Comments:
              </StyledTypography>
              <StyledTypography paragraph variant='body2'>
                {selectedPurchaseOrder.AdditionalComments}
              </StyledTypography>
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

InformationSection.propTypes = {
  primaryLocation: PropTypes.object.isRequired,
  selectedPurchaseOrder: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  StyledTypography: PropTypes.object.isRequired,
};

export default InformationSection;
