import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Typography, withStyles} from '@material-ui/core';
import {BaseReportView} from './Reports/';
import flowRight from 'lodash.flowright';

class Reports extends Component {
  render() {
    const core = {
      baseReportProps: {
        classes: this.props.classes,
      },
    };
    return (
      <Fragment>
        <Typography variant='h5' gutterBottom>
          Reports
        </Typography>
        <BaseReportView {...core.baseReportProps} />
      </Fragment>
    );
  }
}

const styles = theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});

Reports.propTypes = {
  classes: PropTypes.object,
};

export default flowRight(withStyles(styles, {withTheme: true}), withRouter)(Reports);
