import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, MenuItem, MenuList, Button, Grid, Paper, Typography, LinearProgress} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {AccountBox, Domain, Edit, Home, LocalPrintshop} from '@material-ui/icons';
import {MailOutline, Smartphone, UnfoldLess, UnfoldMore} from '@material-ui/icons';
import {BackButton} from 'components/';
import {EventsTasksPanel, NotesPanel, EmailPanel} from '../panels';
import {connect} from 'react-redux';
import {ContactModal} from './Contacts/';
import {ContactToO365Button} from './ContactDetails/';
import flowRight from 'lodash.flowright';
import {Functions} from 'config.js';

class ContactDetails extends Component {
  state = {
    expand: {
      all: false,
      contacts: false,
      generators: false,
      notes: false,
      eventsTasks: false,
      email: false,
      editContactModal: false,
    },
    emailModalOpen: false,
    editContactModalOpen: false,
    threads: [],
    events: [],
    loadingEmails: false,
    loadingEvents: false,
    propsFetched: false,
  };
  componentDidMount() {
    const {contacts, match, users} = this.props;
    if (users && contacts && !this.state.propsFetched) {
      const contact = contacts.ref[match.params.ContactId];
      this.setState({propsFetched: true}, () => {
        if (contact.Email.Work) {
          this.handleGetEvents([contact.Email.Work]);
          this.handleGetEmailsFrom(contact.Email.Work, users);
        }
        if (!contact.Email.Work && contact.Email.Home) {
          this.handleGetEvents([contact.Email.Work]);
          this.handleGetEmailsFrom(contact.Email.Home, users);
        }
        if (!contact.Email.Home && !contact.Email.Work) {
          this.setState({threads: [], events: []});
        }
      });
    }
  }
  componentDidUpdate(prevProps) {
    const {contacts, match, users, history} = this.props;
    if (users && contacts && !this.state.propsFetched) {
      const contact = contacts.ref[match.params.ContactId];
      this.setState({propsFetched: true}, () => {
        this.handleGetEvents([contact.Email.Work]);
        if (contact.Email.Work) {
          this.handleGetEvents([contact.Email.Work]);
          this.handleGetEmailsFrom(contact.Email.Work, users);
        }
        if (!contact.Email.Work && contact.Email.Home) {
          this.handleGetEvents([contact.Email.Work]);
          this.handleGetEmailsFrom(contact.Email.Home, users);
        }
        if (!contact.Email.Home && !contact.Email.Work) {
          this.setState({threads: [], events: []});
        }
      });
    }
    if (contacts && !contacts.ref[match.params.ContactId]) {
      history.push('/contacts');
    }
  }
  handleGetEmailsFrom(contactEmail, users) {
    this.setState({loadingEmails: true});
    // console.log(users);
    users.forEach(user => {
      if (user.nylasAccessToken) {
        // console.log(user);
        Functions.httpsCallable('getEmailsFrom')({
          accessToken: user.nylasAccessToken,
          email: contactEmail,
        })
          .then(res => {
            const threadsWithUser = res.data.map(thread => {
              return {...thread, userToken: user.nylasAccessToken, from: user.email};
            });
            this.setState(prevState => ({
              threads: [...prevState.threads, ...threadsWithUser],
              loadingEmails: false,
            }));
          })
          .catch(err => {
            this.setState({loadingEmails: false});
            console.error('EMAIL ERROR: ', err);
          });
      }
    });
  };
  handleGetEvents(contactAddress) {
    this.setState({loadingEvents: true});
    Functions.httpsCallable('getEvents')({contactAddress})
      .then(res => {
        this.setState(prevState => ({events: [...prevState.events, ...res.data], loadingEvents: false}));
      })
      .catch(err => {
        this.setState({loadingEvents: false});
        console.error('Events err', err);
      });
  };
  handleExpand(key) {
    const {expand} = this.state;
    this.setState({expand: {...expand, [key]: !expand[key], all: false}});
  };
  handleEditContactModalOpen() {
    this.setState({editContactModalOpen: true});
  };
  handleEditContactModalClose() {
    this.setState({editContactModalOpen: false});
  };
  createExpandButton() {
    if (this.state.expand.all) {
      return (
        <Fragment>
          <UnfoldLess className={this.props.classes.extendedIcon} />
          Collapse All
        </Fragment>
      );
    }
    else {
      return (
        <Fragment>
          <UnfoldMore className={this.props.classes.extendedIcon} />
          Expand All
        </Fragment>
      );
    }
  };
  handleExpandButton() {
    const expand = {};
    if (!this.state.expand.all) {
      Object.keys(this.state.expand).forEach(panel => {
        expand[panel] = true;
      });
    }
    if (this.state.expand.all) {
      Object.keys(this.state.expand).forEach(panel => {
        expand[panel] = false;
      });
    }
    this.setState({expand});
  };
  expandNotePanel(panel) {
    this.setState(prevState => ({expand: {...prevState.expand, [panel]: !prevState.expand[panel], all: false}}));
  };
  render() {
    const {contacts, classes, currentUser, match, users, accounts} = this.props;
    const {expand, threads, editContactModalOpen, loadingEmails, loadingEvents, events} = this.state;
    if (!contacts || !currentUser || !users) return <LinearProgress />;
    const contact = contacts.ref[match.params.ContactId];
    const core = {
      buttonEdit: {
        size: 'small',
        variant: 'outlined',
        style: {marginLeft: '8px'},
        onClick: () => this.handleEditContactModalOpen(),
      },
      buttonExpand: {
        size: 'small',
        style: {marginLeft: '8px'},
        variant: 'outlined',
        onClick: () => this.handleExpandButton(),
      },
      contactToO365Button: {
        contact,
        currentUser,
      },
      panelNotes: {
        documentId: match.params.ContactId,
        firestoreCollection: 'CRM-Contacts',
        currentUser,
        expand,
        contacts: [contacts.ref[match.params.ContactId]],
        type: 'contact',
        onChange: () => this.expandNotePanel(),
        readOnly: true,
        contactSelect: {
          value: match.params.ContactId,
          name: contact.Name.Full,
          display: 'Contact',
          refAccount: contact.RefAccount.Id,
        },
        accountSelect: {
          id: contact.RefAccount.Id,
          name: contact.RefAccount.Name,
        },
      },
      paperHeader: {
        className: classes.paper,
        style: {display: 'flex', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center'},
      },
      typographyAccount: {
        style: {lineHeight: '1.2', textAlign: 'center', cursor: 'pointer', fontSize: '.9rem'},
        color: 'primary',
        variant: 'caption',
        onClick: () => this.props.history.push(`/accounts/${contact.RefAccount.Id}`),
      },
      editContactModal: {
        modalOpen: editContactModalOpen,
        selectedContact: contact,
        close: () => this.handleEditContactModalClose(),
        accounts: Object.values(accounts),
        currentUser,
      },
      emailPanel: {
        currentUser,
        loadingEmails,
        threads,
        classes,
        expand,
        onChange: panel => this.handleExpand(panel),
        contactAddress: {work: contact.Email.Work, home: contact.Email.Home},
        users,
      },
      eventsTasksPanel: {
        loadingEvents,
        events,
        contact,
        classes,
        expand,
        onChange: panel => this.handleExpand(panel),
      },
      menuIcon: {
        style: {paddingRight: '20px', fontSize: '3rem'},
      },
      menuPrimaryText: {
        style: {lineHeight: 1.5, fontSize: '1.0rem'},
        variant: 'caption',
      },
      menuSecondaryText: {
        noWrap: true,
        style: {lineHeight: 1.2, fontSize: '0.85rem'},
        variant: 'subtitle1',
      },
      menuDiv: {
        style: {display: 'flex', flexDirection: 'column'},
      },
      menuDividers: {
        variant: 'subtitle1',
        style: {width: '100%', textAlign: 'left', marginLeft: '8px', fontWeight: '600'},
      },
    };
    return (
      <Fragment>
        <div className='sideScrollingContainer'>
          {editContactModalOpen ? <ContactModal {...core.editContactModal} /> : null}
          <BackButton />
          <Button {...core.buttonEdit}>
            <Edit className={classes.extendedIcon} />
            Edit
          </Button>
          <Button {...core.buttonExpand}>{this.createExpandButton()}</Button>
          {currentUser.outlookUser && <ContactToO365Button {...core.contactToO365Button} />}
        </div>
        <Grid spacing={5} container style={{marginTop: '8px'}}>
          <Grid item xs={12} md={3}>
            <Paper {...core.paperHeader}>
              <AccountBox style={{fontSize: '10em', fill: 'slategray', width: '100%'}} />
              <List style={{minWidth: '100%', maxWidth: '100%'}}>
                <ListItem style={{flexDirection: 'column'}}>
                  <Typography variant='h6' style={{lineHeigt: '.9', textAlign: 'center'}}>
                    {contact.Name.Full}
                  </Typography>
                  {contact.Title && (
                    <Typography style={{lineHeigt: '.9', textAlign: 'center'}}>{contact.Title}</Typography>
                  )}
                  {contact.RefAccount.Name && (
                    <Typography {...core.typographyAccount}>{contact.RefAccount.Name}</Typography>
                  )}
                </ListItem>
                <ListItem style={{flexDirection: 'column'}}>
                  <MenuList className={classes.menuStyles}>
                    {(contact.Phone.Fax || contact.Phone.Home || contact.Phone.Work || contact.Phone.Mobile) && (
                      <StyledMenuItem disableGutters className={classes.disabled}>
                        <Typography {...core.menuDividers}>Phone Numbers</Typography>
                      </StyledMenuItem>
                    )}
                    {contact.Phone.Office && (
                      <StyledMenuItem className={classes.disabled}>
                        <Domain {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuPrimaryText}>Office</Typography>
                          <Typography {...core.menuSecondaryText}>{contact.Phone.Office}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                    {contact.Phone.Fax && (
                      <StyledMenuItem className={classes.disabled}>
                        <LocalPrintshop {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuPrimaryText}>Fax</Typography>
                          <Typography {...core.menuSecondaryText}>{contact.Phone.Fax}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                    {contact.Phone.Mobile && (
                      <StyledMenuItem className={classes.disabled}>
                        <Smartphone {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuPrimaryText}>Mobile</Typography>
                          <Typography {...core.menuSecondaryText}>{contact.Phone.Mobile}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                    {contact.Phone.Home && (
                      <StyledMenuItem className={classes.disabled}>
                        <Home {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuPrimaryText}>Home</Typography>
                          <Typography {...core.menuSecondaryText}>{contact.Phone.Home}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                    {(contact.Email.Home || contact.Email.Work) && (
                      <StyledMenuItem disableGutters className={classes.disabled}>
                        <Typography {...core.menuDividers}>Emails</Typography>
                      </StyledMenuItem>
                    )}
                    {contact.Email.Work && (
                      <StyledMenuItem className={classes.disabled}>
                        <MailOutline {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuPrimaryText}>Work</Typography>
                          <Typography {...core.menuSecondaryText}>{contact.Email.Work}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                    {contact.Email.Home && (
                      <StyledMenuItem className={classes.disabled}>
                        <Home {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuPrimaryText}>Home</Typography>
                          <Typography {...core.menuSecondaryText}>{contact.Email.Home}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                  </MenuList>
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <NotesPanel {...core.panelNotes} />
            <EventsTasksPanel {...core.eventsTasksPanel} />
            <EmailPanel {...core.emailPanel} />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  menuStyles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'ghostwhite',
    border: '1px solid darkgray',
    margin: '8px 0px',
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
});
const StyledMenuItem = withStyles({
  root: {
    minHeight: '0px',
    whiteSpace: 'normal',
  },
})(MenuItem);
const mapStateToProps = state => {
  const {accounts, users, contacts} = state.firestore;
  const {currentUser} = state.auth;
  return {contacts, users, accounts, currentUser};
};
ContactDetails.propTypes = {
  contacts: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    ref: PropTypes.objectOf(PropTypes.object),
  }),
  accounts: PropTypes.objectOf(PropTypes.object),
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object),
  outlookUsers: PropTypes.object,
};
export default flowRight(
  connect(mapStateToProps),
  withStyles(styles, {withTheme: true}),
  withRouter,
)(ContactDetails);
