import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionSummary, AccordionDetails, Grid, Button, Tooltip, Typography, LinearProgress} from '@material-ui/core';
import {Table} from '@kbi/component-library';
import {MaterialProfileModal} from './MaterialProfile/';
import {Search, ExpandMore, BookmarkBorder} from '@material-ui/icons';
import {connect} from 'react-redux';
import moment from 'moment';

class MaterialProfilePanel extends Component {
  state = {
    profileModalOpen: false,
    selectedProfile: null,
    profiles: null,
    nearlyExpiredProfile: false,
    expanded: false,
  };
  componentDidUpdate(prevProps) {
    if (this.props.profiles !== prevProps.profiles) {
      this.props.profiles.forEach(profile => {
        //
        if (profile.Active) {
          const expirationDate = moment(profile.Expiration.toISOString());
          const today = moment();
          const oneMonthAway = moment().add(1, 'M');
          if (expirationDate.isBetween(today, oneMonthAway)) {
            this.setState({nearlyExpiredProfile: true});
          }
        }
      });
    }
  }
  render() {
    const {classes, selectedAccount, accountContacts, locations, profiles} = this.props;
    const {profileModalOpen, selectedProfile, expanded} = this.state;

    const core = {
      profileTable: {
        columns: [
          {accessor: 'Active', Header: 'Active', type: 'boolean'},
          {accessor: 'System.CreatedBy.Name', Header: 'Created By'},
          {accessor: 'ApprovedBy', Header: 'Approved By'},
          {accessor: 'EffectiveDate', Header: 'Effective Date', type: 'datetime'},
          {accessor: 'Expiration', Header: 'Expiration Date', type: 'datetime'},
        ],
        sortInitial: [{id: 'Active', desc: false}],
        data: profiles,
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'View Profile',
            onClick: ({rowData}) =>
              this.props.history.push(`/accounts/${selectedAccount.AccountId}/material-profile/${rowData.id}`),
          },
        ],
      },
      profileModal: {
        modalOpen: this.state.profileModalOpen,
        contacts: accountContacts,
        close: () => this.setState({profileModalOpen: false, selectedProfile: null}),
        selectedAccount,
        locations,
        primaryLocation: locations && locations.find(location => location.PrimaryLocation),
        selectedProfile,
      },
      addProfileButton: {
        variant: 'outlined',
        onClick: e => {
          e.stopPropagation();
          this.setState({profileModalOpen: true});
        },
      },
    };

    const details = () => {
      if (!profiles) {
        return <LinearProgress />;
      }
      else if (profiles.length === 0) {
        return (
          <AccordionDetails>
            <Grid container justify='space-between'>
              <Typography variant='subtitle1'>No material profiles found for this account.</Typography>
            </Grid>
          </AccordionDetails>
        );
      }
      else {
        return (
          <AccordionDetails style={{padding: '0px'}}>
            <Grid container>
              <Grid item xs={12}>
                <Table {...core.profileTable} />
              </Grid>
            </Grid>
          </AccordionDetails>
        );
      }
    };

    return (
      <Accordion expanded={expanded} onChange={() => this.setState({expanded: !expanded})}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container justify='space-between'>
            <Grid item>
              <Typography className={classes.heading}>Material Profiles</Typography>
            </Grid><Grid item>
              {this.state.nearlyExpiredProfile && (
                <Tooltip title='Active Profile expires within the month.'>
                  <BookmarkBorder color='secondary' />
                </Tooltip>
              )}
              {expanded ? (
                <Button {...core.addProfileButton}>Add Profile</Button>
              ) : null}
            </Grid>
          </Grid>
        </AccordionSummary>
        {details()}
        {profileModalOpen && <MaterialProfileModal {...core.profileModal} />}
      </Accordion>
    );
  }
}

MaterialProfilePanel.propTypes = {
  selectedAccount: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  accountContacts: PropTypes.arrayOf(PropTypes.object),
  locations: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(null)(MaterialProfilePanel);
