import React, {useState, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid, MenuItem} from '@material-ui/core';
import {SubmitConfirmation, facilitiesConst} from 'components/';
import * as yup from 'yup';
import {Formik, Collapse, Alert} from '@kbi/component-library';
import {Firestore} from 'config.js';
import {useSelector} from 'react-redux';
import moment from 'moment';
const {
  FormikForm, TextField, SubmitButton, SelectField, FormButton, DateField, NumberField,
  AutoCompleteObject, validateAutoObject, validatePhoneField,
} = Formik;
validateAutoObject();
validatePhoneField();


const ShipmentModal = ({selectedShipment, close, selectedAccount, contacts}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const [activePurchaseOrder, setActivePurchaseOrder] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const {purchaseOrders} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);

  useEffect(() => {
    if (purchaseOrders) {
      purchaseOrders[selectedAccount.AccountId]?.find(po => po.Active) ? setActivePurchaseOrder(true) : setActivePurchaseOrder(false);
    }
  }, [purchaseOrders, selectedAccount.AccountId]);

  const formikProps = {
    initialValues: {
      pieces: selectedShipment?.Pieces ||'',
      facility: selectedShipment?.Facility || '',
      container: selectedShipment?.Container || '',
      startDateTime: selectedShipment ? moment(selectedShipment.StartDateTime).format('YYYY-MM-DDTHH:mm') : '',
      endDateTime: selectedShipment ? moment(selectedShipment.EndDateTime).format('YYYY-MM-DDTHH:mm') : '',
      loadType: selectedShipment?.LoadType || '',
      scheduleType: selectedShipment?.ScheduleType || '',
      notes: selectedShipment?.Notes || '',
      contact: selectedShipment ? contacts.find(contact => contact.ContactId === selectedShipment.Contact.RefId) : '',
      active: selectedShipment ? selectedShipment.Active : true,
    },
    validationSchema: yup.object().shape({
      pieces: yup.number().required('Pieces is a required field.').min(1, 'Pieces must be greater than 0.'),
      contact: yup.object().nullable().exists('Contact is a required field.'),
      scheduleType: yup.string().required('Schedule Type is a required field.'),
      loadType: yup.string().required('Load Type is a required field.'),
      container: yup.string().required('Container is a required field.'),
      facility: yup.string().required('Facility is a required field.'),
      startDateTime: yup.string().required('Start Time is a required field.').test({
        name: 'not in the past',
        test: function(value) {
          if (!value) return true;
          if (moment(value).isAfter(moment())) return true;
          else return false;
        },
        message: 'Start Time cannot be in the past.',
      }),
      endDateTime: yup.string().required('End Time is a required field.').test({
        name: 'after start',
        test: function(value) {
          if (!value) return true;
          if (value && moment(value).isAfter(moment(this.parent.startDateTime))) return true;
          else return false;
        },
        message: 'End Time must be after Start Time.',
      }),
    }),
    onSubmit: (values, actions) => {
      setDeleteConfirmation(false);
      const dataToSubmit = createDataForFirestore(values, selectedAccount, currentUser, selectedShipment);
      const firestoreRef = selectedShipment ? Firestore.collection('CRM-Accounts').doc(selectedAccount.AccountId).collection('Shipments').doc(selectedShipment.id) :
        Firestore.collection('CRM-Accounts').doc(selectedAccount.AccountId).collection('Shipments').doc();

      firestoreRef.set(dataToSubmit, {merge: true})
        .then(() => {
          actions.setSubmitting(false);
          setStage(stage + 1);
          setFormError('');
        }).catch(error => {
          actions.setSubmitting(false);
          setFormError('There was an error during submission. Please try again.');
        });
    },
  };


  const core = {
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'md',
      fullWidth: true,
    },
    submitConfirmation: {
      text: `Shipment successfully ${deleteConfirmation ? 'deleted' : selectedShipment ? 'updated' : 'created'}.`,
      stage: stage === 1 ? 'success' : 'not success',
      delete: deleteConfirmation,
    },
    contactField: {
      name: 'contact',
      label: 'Contact',
      required: true,
      options: contacts,
      optionKey: 'Name.Full',
    },
    deleteButton: {
      variant: 'text',
      color: 'secondary',
      disabled: deleting,
      onClick: () => {
        if (!deleteConfirmation) setDeleteConfirmation(true);
        else {
          setDeleting(true);
          Firestore.collection('CRM-Accounts')
            .doc(selectedAccount.AccountId)
            .collection('Shipments')
            .doc(selectedShipment.id)
            .delete()
            .then(() => {
              setDeleting(false);
              setFormError('');
              setStage(stage + 1);
            })
            .catch(error => {
              setDeleting(false);
              setFormError('There was an error during delete. Please try again.');
            });
        }
      },
    },
  };

  return <Dialog {...core.dialog}>
    <FormikForm {...formikProps}>
      {({values}) => (
        <Fragment>
          {stage !== 1 && <DialogTitle>{selectedShipment ? 'Edit' : 'Add New'} Shipment</DialogTitle>}
          <DialogContent>
            <Collapse in={stage === 0}>
              <DialogContentText>Enter shipment information:</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <DateField name='startDateTime' label='Start Time' type='datetime-local' required />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DateField name='endDateTime' label='End Time' type='datetime-local' required />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectField name='scheduleType' label='Schedule Type' required>
                    <MenuItem key='inbound' value='inbound' disabled={!activePurchaseOrder}>
                    Inbound
                    </MenuItem>
                    <MenuItem key='outbound' value='outbound' disabled={!selectedAccount.AccountType.Downstream}>
                    Outbound
                    </MenuItem>
                  </SelectField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectField name='loadType' label='Load Type' required>
                    <MenuItem key='lead acid' value='lead acid'>
                    Lead Acid
                    </MenuItem>
                    <MenuItem key='non-lead' value='non-lead'>
                    Non-Lead
                    </MenuItem>
                    <MenuItem key='mixed' value='mixed'>
                    Mixed
                    </MenuItem>
                    <MenuItem key='catalyst' value='catalyst'>
                    Catalyst
                    </MenuItem>
                  </SelectField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AutoCompleteObject {...core.contactField} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectField name='container' label='Container' required>
                    <MenuItem key='Drum' value='Drum'>
                    Drums
                    </MenuItem>
                    <MenuItem key='Super Sack' value='Super Sack'>
                    Super Sacks
                    </MenuItem>
                    <MenuItem key='Pallets' value='Pallets'>
                    Pallets
                    </MenuItem>
                    <MenuItem key='Mixed' value='Mixed'>
                    Mixed
                    </MenuItem>
                  </SelectField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumberField name='pieces' label='Pieces' required />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectField name='facility' label='Facility' required >
                    {facilitiesConst.map(facility => {
                      return (
                        <MenuItem key={facility} value={facility}>
                          {facility}
                        </MenuItem>
                      );
                    })}
                  </SelectField>
                </Grid>
                {selectedShipment && (
                  <Grid item xs={4}>
                    <SelectField name='active' label='Active' required>
                      <MenuItem key={true} value={true}>
                      True
                      </MenuItem>
                      <MenuItem key={false} value={false}>
                      False
                      </MenuItem>
                    </SelectField>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField name='notes' label='Notes' multiline />
                </Grid>
              </Grid>
            </Collapse>
            <SubmitConfirmation {...core.submitConfirmation} />
            <Alert in={Boolean(formError)} text={formError} />
          </DialogContent>
          {stage === 1 ? (
            <DialogActions>
              <Button variant='text' onClick={close} color='primary'>Close</Button>
            </DialogActions>
          ) : selectedShipment ? (
            <DialogActions style={{justifyContent: 'space-between'}}>
              <FormButton {...core.deleteButton}>{deleteConfirmation ? 'Confirm?' : 'Delete'}</FormButton>
              <div>
                <FormButton variant='text' color='secondary' onClick={close} disabled={deleting}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary' disabled={deleting}>Submit</SubmitButton>
              </div>
            </DialogActions>
          ) : (
            <DialogActions style={{justifyContent: 'space-between'}}>
              <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
              <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
            </DialogActions>
          )}
        </Fragment>
      )}
    </FormikForm>
  </Dialog>;
};

function createDataForFirestore(values, selectedAccount, currentUser, selectedShipment) {
  const firestoreObj = {
    AccountId: selectedAccount.AccountId,
    Active: values.active,
    Container: values.container,
    StartDateTime: new Date(values.startDateTime),
    EndDateTime: new Date(values.endDateTime),
    ScheduleType: values.scheduleType,
    LoadType: values.loadType,
    Notes: values.notes,
    Pieces: values.pieces,
    Facility: values.facility,
    ShipmentId: null,
    Contact: {
      RefId: values.contact.ContactId,
      Phone: values.contact.Phone,
      Name: values.contact.Name,
      Email: values.contact.Email,
    },
  };
  if (selectedShipment) {
    firestoreObj['System.UpdatedOn'] = new Date();
    firestoreObj['System.UpdatedBy'] = currentUser.displayName;
  }
  else {
    firestoreObj.System = {
      CreatedOn: new Date(),
      CreatedBy: currentUser.displayName,
    };
  }
  return firestoreObj;
}

ShipmentModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedShipment: PropTypes.object,
  selectedAccount: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ShipmentModal;
