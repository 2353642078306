import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid, MenuItem} from '@material-ui/core';
import {SubmitConfirmation} from 'components/';
import {formatDoubleLineAddress, formatSingleLineAddress, formatTripleLineAddress} from '@kbi/utility-library';
import * as yup from 'yup';
import {Formik, Collapse, Alert} from '@kbi/component-library';
import {AddressForm} from '../../components/';
import {Firestore} from 'config.js';
import {industries, countries} from './lists';
import {useSelector} from 'react-redux';
const {
  FormikForm, TextField, SubmitButton, SelectField, FormButton,
  AutoComplete, AutoCompleteObject, validateAutoObject, PhoneNumberField, validatePhoneField,
} = Formik;
validateAutoObject();
validatePhoneField();


const AccountInfoModal = props => {
  const [stage, setStage] = useState(0);
  const [stageArray] = useState(props.selectedAccount ? ['basic', 'manager', 'success'] : ['basic', 'address', 'contact', 'manager', 'success']);
  const [formError, setFormError] = useState('');
  const {currentUser} = useSelector(state => state.auth);
  const users = useSelector(state => state.firestore.users);

  const handleBack = () => {
    setStage(stage - 1);
  };

  const formikProps = {
    initialValues: {
      accountName: props.selectedAccount?.AccountName || '',
      addressLine1: '',
      addressLine2: '',
      addressCity: '',
      addressState: '',
      addressPostal: '',
      addressCountry: '',
      addressProvince: '',
      addressSubdivision: '',
      addressPrefecture: '',
      addressUnformatted: '',
      phonePrimary: '',
      phoneFax: '',
      phoneEmergency: '',
      active: props.selectedAccount ? props.selectedAccount.Active : true,
      typeDownstream: props.selectedAccount ? props.selectedAccount.AccountType.Downstream : '',
      typeCustomer: props.selectedAccount ? props.selectedAccount.AccountType.Customer : '',
      typeTransporter: props.selectedAccount ? props.selectedAccount.AccountType.Transporter : '',
      website: props.selectedAccount?.Website || '',
      accountManager: props.selectedAccount ? users.find( user => user.uid === props.selectedAccount.Manager.Uid) : '',
      industry: props.selectedAccount?.Industry || '',
      epaId: '',
      naicsId: '',
      sicId: '',
    },
    validationSchema: (() => {
      if (stageArray[stage] === 'basic') {
        return yup.object().shape({
          accountName: yup.string().required('Account Name is a required field.'),
          active: yup.boolean(),
          typeDownstream: yup.boolean().required('Downstream is a required field.'),
          typeCustomer: yup.boolean().required('Customer is a required field.'),
          typeTransporter: yup.boolean().required('Transporter is a required field.'),
          epaId: (() => (
            props.selectedAccount ? yup.string() : yup.string().required('EPA Id is a required for a new account (N/A is a valid entry).')
          ))(),
          naicsId: yup.string(),
          sicId: yup.string(),
        });
      }
      else if (stageArray[stage] === 'address') {
        return yup.object().shape({
          addressCountry: yup.string().required('Country is a required field.'),
          addressLine1: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico' || value === 'Japan' || value === 'South Korea' || value === 'Canada',
            then: yup.string().required('Address Line 1 is a required field.'),
            otherwise: yup.string(),
          }),
          addressLine2: yup.string(),
          addressCity: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico' || value === 'South Korea' || value === 'Canada',
            then: yup.string().required('City is a required field.'),
            otherwise: yup.string(),
          }),
          addressState: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico',
            then: yup.string().required('State is a required field.'),
            otherwise: yup.string(),
          }),
          addressPostal: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico' || value === 'Canada',
            then: yup.string().required('Postal is a required field.'),
            otherwise: yup.string(),
          }),
          addressProvince: yup.string().when('addressCountry', {
            is: value => value === 'Japan' || value === 'South Korea' || value === 'Canada',
            then: yup.string().required('Province is a required field.'),
            otherwise: yup.string(),
          }),
          addressSubdivision: yup.string().when('addressCountry', {
            is: value => value === 'South Korea',
            then: yup.string().required('Subdivision is a required field.'),
            otherwise: yup.string(),
          }),
          addressPrefecture: yup.string().when('addressCountry', {
            is: value => value === 'Japan',
            then: yup.string().required('Prefecture is a required field.'),
            otherwise: yup.string(),
          }),
          addressUnformatted: yup.string().when('addressCountry', {
            is: value => value !== 'United States' && value !== 'Mexico' && value !== 'Japan' && value !== 'South Korea' && value !== 'Canada',
            then: yup.string().required('Address is a required field.'),
            otherwise: yup.string(),
          }),
        });
      }
      else if (stageArray[stage] === 'contact') {
        return yup.object().shape({
          phonePrimary: yup.string().validPhoneNumber('Primary No. is an invalid number.'),
          phoneFax: yup.string().validPhoneNumber('Fax No. is an invalid number.'),
          phoneEmergency: yup.string().validPhoneNumber('Emergency No. is an invalid number.'),
        });
      }
      else {
        return yup.object().shape({
          website: yup.string().url('Must be a valid website.'),
          accountManager: yup.object().nullable().exists('Manager is a required field.'),
          industry: yup.string().required('Industry is a required field.'),
        });
      }
    })(),
    onSubmit: async (values, actions) => {
      if (stage !== stageArray.length - 2) {
        setStage(stage + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
        return;
      }
      const batch = Firestore.batch();
      const submitData = createAccountDataForFirestore(values);

      if (props.selectedAccount) {
        if (submitData.AccountName !== props.selectedAccount.AccountName) {
          const contactsQuery = Firestore.collection('CRM-Contacts').where('RefAccount.Id', '==', props.selectedAccount.AccountId);
          await contactsQuery.get().then(snap => {
            snap.forEach(doc => {
              batch.update(Firestore.collection('CRM-Contacts').doc(doc.id), {
                'RefAccount.Name': submitData.AccountName,
              });
            });
          });
        }
        batch.update(Firestore.collection('CRM-Accounts').doc(props.selectedAccount.AccountId), {
          ...submitData,
          'System.ModifiedOn': new Date(),
          'System.ModifiedBy': currentUser.displayName,
        });
      }
      else {
        const newAccountRef = Firestore.collection('CRM-Accounts').doc();
        const System = {
          // this is needed in order to create initial timestamp, and maintain it through updates
          CreatedOn: new Date(),
          CreatedBy: currentUser.displayName,
          ModifiedOn: new Date(),
          ModifiedBy: currentUser.displayName,
        };
        batch.set(newAccountRef, {
          ...submitData,
          System,
        });
        batch.set(newAccountRef.collection('Locations').doc(), {
          ...createLocationDataForFirestore(values),
          AccountRef: newAccountRef.id,
          System,
        });
      }
      return await batch.commit()
        .then(() => {
          setFormError('');
          setStage(stage + 1);
          actions.setSubmitting(false);
        }).catch(error => {
          actions.setSubmitting(false);
          setFormError('An error occured while trying to submit. Please try again.');
        });
    },
  };


  const core = {
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      fullWidth: true,
    },
    submitConfirmation: {
      text: `Account successfully ${props.selectedAccount ? 'updated' : 'created'}.`,
      stage: stageArray[stage],
    },
    managerField: {
      name: 'accountManager',
      label: 'Manager',
      required: true,
      options: users,
      optionKey: 'displayName',
      fast: true,
    },
    countryField: {
      name: 'addressCountry',
      label: 'Country',
      required: true,
      options: countries,
      optionKey: 'value',
      onChange: ({form}) => {
        form.setFieldValue('addressLine1', '');
        form.setFieldValue('addressLine2', '');
        form.setFieldValue('addressCity', '');
        form.setFieldValue('addressState', '');
        form.setFieldValue('addressPostal', '');
        form.setFieldValue('addressProvince', '');
        form.setFieldValue('addressSubdivision', '');
        form.setFieldValue('addressPrefecture', '');
        form.setFieldValue('addressUnformatted', '');
      },
    },
  };

  return <Dialog {...core.dialog}>
    <FormikForm {...formikProps}>
      {({values}) => (
        <Fragment>
          {stageArray[stage] !== 'success' && <DialogTitle>{props.selectedAccount ? 'Edit' : 'Add New'} Account</DialogTitle>}
          <DialogContent>
            <Collapse in={stageArray[stage] === 'basic'}>
              <DialogContentText>Enter account name and classification:</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField name='accountName' label='Account Name' required fast />
                </Grid>
                {props.selectedAccount ? (
                  <Grid item xs={3}>
                    <SelectField name='active' label='Active?' required fast >
                      <MenuItem key={true} value={true}>
                        True
                      </MenuItem>
                      <MenuItem key={false} value={false}>
                        False
                      </MenuItem>
                    </SelectField>
                  </Grid>
                ) : null}
                <Grid item xs={props.selectedAccount ? 3 : 4}>
                  <SelectField name='typeCustomer' label='Customer?' required fast >
                    <MenuItem key={true} value={true}>
                        True
                    </MenuItem>
                    <MenuItem key={false} value={false}>
                        False
                    </MenuItem>
                  </SelectField>
                </Grid>
                <Grid item xs={props.selectedAccount ? 3 : 4}>
                  <SelectField name='typeDownstream' label='Downstream?' required fast >
                    <MenuItem key={true} value={true}>
                        True
                    </MenuItem>
                    <MenuItem key={false} value={false}>
                        False
                    </MenuItem>
                  </SelectField>
                </Grid>
                <Grid item xs={props.selectedAccount ? 3 : 4}>
                  <SelectField name='typeTransporter' label='Transporter?' required fast >
                    <MenuItem key={true} value={true}>
                        True
                    </MenuItem>
                    <MenuItem key={false} value={false}>
                        False
                    </MenuItem>
                  </SelectField>
                </Grid>
                {// if creating a new account, have inputs for the ids, otherwise, don't show id fields, they will be in location modal
                  props.selectedAccount ? null : (
                    <Fragment>
                      <Grid item xs={6} sm={4}>
                        <TextField name='epaId' label='EPA ID' required fast />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField name='naicsId' label='NAICS ID' fast />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField name='sicId' label='SIC ID' fast />
                      </Grid>
                    </Fragment>
                  )}
              </Grid>
            </Collapse>
            <Collapse in={stageArray[stage] === 'address'}>
              <DialogContentText>Enter account&apos;s primary place of business:</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AutoComplete {...core.countryField} />
                </Grid>
                <AddressForm />
              </Grid>
            </Collapse>
            <Collapse in={stageArray[stage] === 'contact'}>
              <DialogContentText>Enter account&apos;s primary contact information:</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PhoneNumberField name='phonePrimary' label='Primary No.' />
                </Grid>
                <Grid item xs={6}>
                  <PhoneNumberField name='phoneFax' label='Fax No.' />
                </Grid>
                <Grid item xs={6}>
                  <PhoneNumberField name='phoneEmergency' label='Emergency No.' />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={stageArray[stage] === 'manager'}>
              <DialogContentText>Enter account manager information:</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <AutoCompleteObject {...core.managerField} />
                </Grid>
                <Grid item xs={6}>
                  <SelectField name='industry' label='Industry' required fast>
                    {industries.map(industry => (
                      <MenuItem key={industry} value={industry}>
                        {industry}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </Grid>
            </Collapse>
            <SubmitConfirmation {...core.submitConfirmation} />
            <Alert in={Boolean(formError)} text={formError} />
          </DialogContent>
          {stageArray[stage] === 'success' ? (
            <DialogActions>
              <Button variant='text' onClick={props.close} color='primary'>Close</Button>
            </DialogActions>
          ) : (
            <DialogActions style={{justifyContent: 'space-between'}}>
              <FormButton variant='text' color='secondary' onClick={props.close}>Cancel</FormButton>
              <div>
                <FormButton variant='text' color='primary' disabled={stage === 0} onClick={handleBack}>Back</FormButton>
                <SubmitButton variant='text' color='primary'>{stageArray[stage] === 'manager' ? 'Submit' : 'Next'}</SubmitButton>
              </div>
            </DialogActions>
          )}
        </Fragment>
      )}
    </FormikForm>
  </Dialog>;
};

function createAddressForFirestore(values) {
  let submitAddress = null;
  if (values.addressCountry === 'United States' || values.addressCountry === 'Mexico') {
    submitAddress = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      City: values.addressCity,
      State: values.addressState,
      PostalCode: values.addressPostal,
      Country: values.addressCountry,
    };
  }
  else if (values.addressCountry === 'Japan') {
    submitAddress = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      Prefecture: values.addressPrefecture,
      Province: values.addressProvince,
      Country: values.addressCountry,
    };
  }
  else if (values.addressCountry === 'South Korea') {
    submitAddress = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      City: values.addressCity,
      Subdivision: values.addressSubdivision,
      Province: values.addressProvince,
      Country: values.addressCountry,
    };
  }
  else if (values.addressCountry === 'Canada') {
    submitAddress = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      City: values.addressCity,
      Province: values.addressProvince,
      PostalCode: values.addressPostal,
      Country: values.addressCountry,
    };
  }
  else {
    submitAddress = {
      Unformatted: values.addressUnformatted,
      Country: values.addressCountry,
    };
  }
  submitAddress.SingleLine = formatSingleLineAddress(submitAddress);
  submitAddress.DoubleLine = formatDoubleLineAddress(submitAddress);
  submitAddress.TripleLine = formatTripleLineAddress(submitAddress);
  return submitAddress;
}
function createLocationDataForFirestore(values) {
  return {
    Address: createAddressForFirestore(values),
    Active: true,
    FirstOrThirdParty: 'First',
    LocationName: values.accountName,
    PrimaryLocation: true,
    Phone: {
      Primary: values.phonePrimary,
      Fax: values.phoneFax,
      Emergency: values.phoneEmergency,
    },
    EpaId: values.epaId,
    SicId: values.sicId,
    NaicsId: values.naicsId,
  };
}
function createAccountDataForFirestore(values) {
  const submitData = {
    AccountName: values.accountName,
    AccountType: {
      Customer: values.typeCustomer,
      Downstream: values.typeDownstream,
      Transporter: values.typeTransporter,
    },
    Active: values.active,
    Industry: values.industry,
    Manager: {
      Name: values.accountManager.displayName,
      Email: values.accountManager.email,
      Uid: values.accountManager.uid,
    },
    Website: values.website,
  };
  return submitData;
}

AccountInfoModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedAccount: PropTypes.object,
};

export default AccountInfoModal;
