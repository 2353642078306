/* eslint-disable guard-for-in */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Add, Search} from '@material-ui/icons';
import {LinearProgress} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import flowright from 'lodash.flowright';
import {TableWithPaper} from 'components/';
import {AccountInfoModal} from './Accounts/';
import {connect} from 'react-redux';

class Accounts extends Component {
  state = {
    addAccount: false,
  };

  createListOfAccountsForTable() {
    const {accounts, locations} = this.props;
    const arrayOfAccounts = [];
    if (accounts && locations) {
      for (const accountId in accounts) {
        arrayOfAccounts.push({
          ...accounts[accountId],
          primaryLocation: locations[accountId] ? locations[accountId].find(location => location.PrimaryLocation) : {},
        });
      }
    }
    return arrayOfAccounts;
  }
  render() {
    const {accounts, currentUser, users, history, locations} = this.props;
    if (!accounts || !currentUser || !users || !locations) return <LinearProgress />;
    const core = {
      tableAccounts: {
        columns: [
          {accessor: 'Active', Header: 'Active', type: 'boolean'},
          {accessor: 'AccountName', Header: 'Name'},
          {accessor: 'primaryLocation.Phone.Primary', Header: 'Primary Phone'},
          {accessor: 'primaryLocation.Phone.Fax', Header: 'Fax'},
          {accessor: 'Industry', Header: 'Industry'},
          {accessor: 'Manager.Name', Header: 'Manager'},
        ],
        sortInitial: [{id: 'AccountName', desc: false}],
        data: this.createListOfAccountsForTable(),
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'View Account',
            onClick: ({rowData}) => history.push(`/accounts/${rowData.AccountId}`),
          },
        ],
        actionsBar: [
          {
            text: 'Add Account',
            icon: Add,
            onClick: () => this.setState({addAccount: true}),
          },
        ],
        paginationInitialSize: 10,
        paginationSizes: [10, 25, 50, 100],
        title: {
          primary: 'Accounts',
        },
        paperProps: {
          style: {
            margin: '0px',
          },
        },
      },
      modalAccountNew: {
        currentUser,
        users,
        close: () => this.setState({addAccount: false}),
      },
    };
    return (
      <Fragment>
        <TableWithPaper {...core.tableAccounts} />
        {this.state.addAccount && <AccountInfoModal {...core.modalAccountNew} />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.firestore.users,
    accounts: state.firestore.accounts,
    currentUser: state.auth.currentUser,
    locations: state.firestore.locations,
  };
};
Accounts.propTypes = {
  accounts: PropTypes.objectOf(PropTypes.object),
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  locations: PropTypes.shape({
    listener: PropTypes.func.isRequired,
    // any number of keys that are account id's
  }),
};
export default flowright(connect(mapStateToProps), withRouter)(Accounts);
