import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {LinearProgress} from '@material-ui/core';
import {Add, Search} from '@material-ui/icons';
import {TableWithPaper} from 'components/';
import {ContactModal} from './Contacts/';
import flowRight from 'lodash.flowright';

class Contacts extends Component {
  state = {
    addContact: false,
    selectedContact: null,
    expand: {},
  };

  render() {
    const {contacts, currentUser, users, accounts, history} = this.props;
    if (!contacts || !currentUser || !users || !accounts) return <LinearProgress />;
    const core = {
      tableContacts: {
        columns: [
          {accessor: 'Active', Header: 'Active', type: 'boolean'},
          {accessor: 'Name.Full', Header: 'Name'},
          {accessor: 'RefAccount.Name', Header: 'Account'},
          {accessor: 'Phone.Office', Header: 'Office Phone'},
          {accessor: 'Email.Work', Header: 'Work Email'},
          {accessor: 'Title', Header: 'Title'},
        ],
        sortInitial: [{id: 'Name.Full', desc: false}],
        data: contacts?.list,
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'View Contact',
            onClick: ({rowData}) => history.push(`/contacts/${rowData.ContactId}`),
          },
        ],
        actionsBar: [
          {
            text: 'Add Contact',
            icon: Add,
            onClick: () => this.setState({addContact: true}),
          },
        ],
        paperProps: {
          style: {
            margin: '0px',
          },
        },
        paginationInitialSize: 10,
        paginationSizes: [10, 25, 50, 100],
        title: {
          primary: 'Contacts',
        },
      },
      modalContactNew: {
        currentUser,
        close: () => this.setState({addContact: false}),
      },
    };
    return (
      <Fragment>
        <TableWithPaper {...core.tableContacts} />
        {this.state.addContact && <ContactModal {...core.modalContactNew} />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {contacts} = state.firestore;
  return {contacts, users: state.firestore.users, accounts: state.firestore.accounts, currentUser: state.auth.currentUser};
};
Contacts.propTypes = {
  currentUser: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object),
  contacts: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    ref: PropTypes.objectOf(PropTypes.object),
  }),
  accounts: PropTypes.objectOf(PropTypes.object),
  history: PropTypes.object.isRequired,
};
export default flowRight(
  connect(mapStateToProps),
  withRouter,
)(Contacts);
