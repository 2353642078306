import React from 'react';
import {Grid} from '@material-ui/core';
import PropTypes from 'prop-types';

const TermsAndConditions = props => {
  const {classes, StyledTypography} = props;
  const core = {
    typographyProps: {
      paragraph: true,
      className: classes.avoidPageBreak,
      variant: 'caption',
    },
  };
  return (
    <div className='newPage'>
      <Grid item xs={12} className={classes.newSection}>
        <StyledTypography variant='h6' paragraph>
          TERMS AND CONDITIONS
        </StyledTypography>
        <StyledTypography {...core.typographyProps}>
          The following Terms and Conditions shall apply to any shipment or delivery to Kinsbursky Bros. Supply, Inc.
          d/b/a Kinsbursky Brothers Intl. (“KBI”) made by Customer pursuant to this Purchase Order. Customer’s
          commencement of any shipment or delivery of materials subject to the Purchase Order, shall be deemed an
          effective mode of acceptance of this Purchase Order. Any acceptance of this Purchase Order is limited to
          acceptance of the express terms contained herein. Any proposal for additional or different terms or any attempt
          by Customer to vary in any degree any of the terms of this Purchase Order are hereby rejected, unless agreed to
          in writing by an authorized representative of KBI.
        </StyledTypography>
        <StyledTypography {...core.typographyProps}>
          <span className={classes.bold}>1. Services. </span>KBI shall accept all materials received from the Customer
          that conform to the descriptions set forth in a KBI approved Material Profile or Material Profile Addendum.
          Before acceptance, KBI will visually inspect all materials received, and if any materials are found to be
          non-conforming to any approved Material Profile or Material Profile Addendum, then KBI reserves the right, at
          its sole discretion, to reject the materials back to the Customer. Customer shall be responsible for paying all
          costs associated with the rejection of materials. Upon acceptance, KBI shall recycle, reclaim, dispose of,
          reutilize and/or otherwise manage all materials in conformance with all federal, state and local laws. Title to
          materials shall transfer to KBI upon receipt and acceptance by KBI. In addition, upon Customer’s request, KBI
          may assist Customer with the scheduling of a third-party freight carrier to transport materials from Customer’s
          facility to a KBI facility. KBI’s assistance with transportation shall not change the duties and obligations of
          the parties, including but not limited to Customer’s responsibilities as the shipper of the materials.
        </StyledTypography>
        <StyledTypography {...core.typographyProps}>
          <span className={classes.bold}>2. Fees. </span>
          Customer will be paid/charged based on the rates stated in this Purchase Order. The rates stated in this
          Purchase Order are based on the weight of materials and/or the number of units comprising the materials, and for
          this purpose KBI’s weights and counts shall govern. If Customer delivers materials that are not listed in this
          Purchase Order, then the rates set forth in the Supplemental Recycling/Disposal Fees document shall govern;
          however Supplemental rates may be modified to be more beneficial to Customer, at any time, at KBI’s sole
          discretion. Payment terms shall be net thirty (30) days, whether payment is due to KBI or to the Customer.
        </StyledTypography>
        <StyledTypography {...core.typographyProps}>
          <span className={classes.bold}>3. KBI Warranties. </span>
          KBI warrants that: (i) it possesses the necessary permits, certifications, and approvals to manage the
          Customer’s materials in conformance with all federal, state and local law; (ii) it shall perform the Services in
          a manner consistent with the level of care and skill ordinarily exercised by other professional service
          providers under similar circumstances at the time the Services are performed; and (iii) it shall comply with all
          federal, state, provincial and local laws, ordinances and/or regulations applicable to the performance of its
          Services.
        </StyledTypography>
        <StyledTypography {...core.typographyProps}>
          <span className={classes.bold}>4. Customer Warranties. </span>
          Customer warrants that: (i) it shall package all materials in conformance with all federal, state, provincial
          and local laws, including, but not limited to, the Federal Hazmat Regulations set forth in 49 CFR parts 171‐180
          when shipping within the United States and the regulations made under the Transportation of Dangerous Goods Act,
          1992 when shipping within Canada; (ii) if it has submitted any samples, then that sample is representative as
          defined in 40 CFR 261 or by using an equivalent method; (iii) if applicable, it has captured all records and
          other information required by law for the acceptance or purchase of materials being transferred to KBI; and
          (iii) it shall comply with all other federal, state, and local laws, ordinances and/or regulations applicable to
          its performance under this Agreement.
        </StyledTypography>
        <StyledTypography {...core.typographyProps}>
          <span className={classes.bold}>5. Limit of Liability. </span>
          IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER, EITHER IN CONTRACT OR IN TORT, FOR ANY CONSEQUENTIAL,
          INCIDENTAL, INDIRECT, SPECIAL OR PUNITIVE DAMAGES.
        </StyledTypography>
        <StyledTypography {...core.typographyProps}>
          <span className={classes.bold}>6. ITAR Regulations. </span>
          In accordance with the International Traffic in Arms Regulations (ITAR), the Customer shall not deliver to KBI
          any articles listed in the U.S. Munitions List. Customer shall indemnify KBI against any and all actions,
          proceedings, losses, claims, costs, damages and expenses arising from Customer’s violation of this provision.
        </StyledTypography>
        <StyledTypography {...core.typographyProps}>
          <span className={classes.bold}>7. Governing Law & Jurisdiction. </span>
          These Terms and Conditions shall be governed, construed, and enforced in accordance with the laws of the State
          of California, without regard to its conflict of laws rules. The parties agree that any litigation in any way
          relating to this Purchase Order or the Terms and Conditions shall be brought and venued exclusively in
          California and both parties hereby consent to the personal jurisdiction of these courts and waives any objection
          that such venue is inconvenient or improper.
        </StyledTypography>
        <StyledTypography {...core.typographyProps}>
          <span className={classes.bold}>8. Additional Service Fees. </span>
          <span className={classes.bold}>(1) Sorting Fee: </span>Materials must be categorized and sorted by chemistry.
          Accordingly, a sorting fee of $0.15/lb. will be added to the gross weight of containers that are comprised of
        over 10% improperly sorted materials. <span className={classes.bold}>(2) Repackaging Fee: </span>A repackaging
            fee of $60.00 per man hour, plus the cost of materials, will be charged for repackaging containers and materials
        that are not in compliance with applicable shipping regulations or that otherwise represent a hazard.{' '}
          <span className={classes.bold}>(3) Disassembly Fee: </span>Reasonable disassembly and discharge fees may apply
          for large format battery packs, if in KBI’s sole discretion, pack disassembly and/or discharge is required to
        ensure safe storage/processing. <span className={classes.bold}>(4) Unscheduled Delivery Fee: </span>Customer
            must schedule all deliveries with KBI to ensure adequate labor and space is available to accept materials.
            Accordingly, if Customers fails to schedule a delivery, a $200.00 unscheduled delivery fee will be assessed.
            Further, KBI will assess a $60.00 per man hour fee to offset any overtime needed to accept Customer’s
        unscheduled delivery. <span className={classes.bold}>(5) Data Management Fee: </span>Customers that deliver
            materials on multiple shipping documents (bill of lading, manifest) will be assessed a $25.00 data management
        fee for each shipping document received after the first.{' '}
          <span className={classes.bold}>(6) Manifest Fee: </span>To offset costs associated with the EPA’s e-Manifest
        requirements, a Manifest Fee of $5.00 will be added for every hazardous waste manifest received.{' '}
          <span className={classes.bold}>(7) Minimum Fee: </span>Customers will be assessed a $100.00 minimum fee if KBI’s
        charges do not exceed $100.00. <span className={classes.bold}>(8) E-Waste Fee: </span>KBI does not process
            electronic waste and will charge a $0.75/lb. fee to ensure its proper management.
        </StyledTypography>
      </Grid>
    </div>
  );
};

TermsAndConditions.propTypes = {
  classes: PropTypes.object.isRequired,
  StyledTypography: PropTypes.object.isRequired,
};

export default TermsAndConditions;
