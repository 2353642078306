/* eslint-disable no-unused-expressions */
import React, {useState, useEffect} from 'react';
import {useModuleAccessCheck} from './hooks/';
import {Auth, Functions} from 'config.js';
import {useAuthCheck} from '@kbi/utility-library';
import {acGetCurrentUser} from 'state/auth/actions.js';
import {
  acGetAccounts,
  acGetAllMaterialTypes,
  acGetAllPurchaseOrders,
  acGetContacts,
  acGetLeads,
  acGetLocations,
  acGetMaterials,
  acGetOutlookUsers,
  acGetShipments,
  acGetUsers,
} from './state/firestore/actions.js';
import {withRouter} from 'react-router-dom';
import {LinearProgress} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {AppFrame, ErrorBoundary} from '@kbi/component-library';
import {menuItems, routes, moduleMenuOptions} from './appFrameProps.js';
import axios from 'axios';

export const ReportContext = React.createContext(null);

const Bowser = require('bowser/bundled');
const browser = Bowser.getParser(window.navigator.userAgent).getBrowserName();

if (browser === 'Internet Explorer') window.location.replace('http://kbi.works/PreventIE.html');

const handleLogout = async () => {
  document.cookie = `signOut=true.${Date.now()};domain=kbi.works;max-age=600`;
  try {
    await axios({
      method: 'post',
      url: `https://server.kbi.works/auth/signout`,
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
  catch (error) {
    console.log('logout error', error);
  }
};

function sendDataToEmailCloudFunction(error, info, currentUser) {
  if (process.env.NODE_ENV === 'production') {
    Functions.httpsCallable('emailCatchError')({
      errorMessage: error.message,
      errorName: error.name,
      errorLog: error.message.split(' at')[0],
      errorStack: info.componentStack,
      errorLocation: window.location.href,
      errorTime: new Date().toLocaleString(),
      user: currentUser?.email || 'No User Data found.',
    });
  }
}

const App = () => {
  const [view, setView] = useState(null);
  const [reportData, setReportData] = useState([]);
  const dispatch = useDispatch();
  const {currentUser} = useAuthCheck({acGetCurrentUser, Auth});
  useModuleAccessCheck();

  const {
    shipmentListener,
    accountListener,
    purchaseOrderListener,
    materials,
    types,
    locations,
  } = useSelector(state => state.firestore);

  useEffect(() => {
    if (currentUser) {
      // Use setTimeouts to randomize Firestore queries and prevent overloading database
      setTimeout(() => dispatch(acGetAccounts()), Math.random() * 5000);
      setTimeout(() => dispatch(acGetContacts()), Math.random() * 5000);
      setTimeout(() => dispatch(acGetUsers()), Math.random() * 5000);
      setTimeout(() => dispatch(acGetLeads()), Math.random() * 5000);
      setTimeout(() => dispatch(acGetOutlookUsers()), Math.random() * 5000);
      setTimeout(() => dispatch(acGetAllPurchaseOrders()), Math.random() * 5000);
      setTimeout(() => dispatch(acGetShipments()), Math.random() * 5000);
      setTimeout(() => dispatch(acGetLocations()), Math.random() * 5000);
      setTimeout(() => dispatch(acGetMaterials()), Math.random() * 5000);
      setTimeout(() => dispatch(acGetAllMaterialTypes()), Math.random() * 5000);
      return () => {
        purchaseOrderListener?.();
        accountListener?.();
        shipmentListener?.();
        materials?.listener();
        types?.listener();
        locations?.listener();
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);


  if (!currentUser) return <LinearProgress />;

  return (
    <ErrorBoundary errorHandlerFunction={(error, info) => sendDataToEmailCloudFunction(error, info, currentUser)}>
      <ReportContext.Provider
        value={{
          view: view,
          reportData: reportData,
          setReportData: reportData => setReportData(reportData),
          setView: view => setView(view),
        }}
      >
        <AppFrame routes={routes}
          menuItems={menuItems}
          moduleTitle='CRM'
          redirectTo='/accounts'
          currentUserEmail={currentUser.email}
          moduleMenuOptions={moduleMenuOptions}
          logoutFunction={() => handleLogout()}
        />
      </ReportContext.Provider>
    </ErrorBoundary>
  );
};

export default withRouter(App);
