import React, {useMemo} from 'react';
import {DialogActions, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import {SubmitButton} from 'components/';

const DefaultModalButtons = props => {
  const submitButtonProps = useMemo(() => {
    return {
      disabled: props.isSubmitting,
      onClick: () => {
        if (props.handleSubmit && typeof props.handleSubmit === 'function') return props.handleSubmit();
        else return;
      },
      text: 'Submit',
      loading: props.isSubmitting,
      color: 'primary',
      ...props.submitButtonProps,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.handleSubmit, props.isSubmitting, props.submitButtonProps]);
  const cancelButtonProps = useMemo(() => {
    return {
      color: 'secondary',
      onClick: props.close,
      disabled: props.isSubmitting,
      ...props.cancelButtonProps,
    };
  }, [props.cancelButtonProps, props.close, props.isSubmitting]);
  const backButtonProps = useMemo(() => {
    if (props.handleBack) {
      return {
        color: 'primary',
        onClick: props.handleBack,
        disabled: props.isSubmitting || props.stage === 'basic',
        ...props.backButtonProps,
      };
    }
    else return null;
  }, [props.backButtonProps, props.handleBack, props.isSubmitting, props.stage]);
  const closeButtonProps = useMemo(() => {
    return {
      color: 'primary',
      onClick: props.close,
      ...props.closeButtonProps,
    };
  }, [props.closeButtonProps, props.close]);
  if (props.stage === 'success') {
    return (
      <DialogActions style={{justifyContent: 'flex-end'}}>
        <Button {...closeButtonProps}>Close</Button>
      </DialogActions>
    );
  }
  else {
    return (
      <DialogActions style={{justifyContent: 'space-between'}}>
        <Button {...cancelButtonProps}>Cancel</Button>
        <div>
          {backButtonProps && <Button {...backButtonProps}>Back</Button>}
          <SubmitButton {...submitButtonProps} />
        </div>
      </DialogActions>
    );
  }
};

DefaultModalButtons.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  close: PropTypes.func.isRequired,
  submitButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
  backButtonProps: PropTypes.object,
  closeButtonProps: PropTypes.object,
  stage: PropTypes.string.isRequired,
};

export default DefaultModalButtons;
