import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Collapse} from '@material-ui/core';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {Alert} from '@kbi/component-library';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';

const cleanState = {
  note: {
    display: 'Note',
    error: null,
    value: '',
  },
  contactSelect: {
    display: 'Contact',
    error: null,
    value: '',
    name: '',
  },
  leadSelect: {
    display: 'Contact',
    error: null,
    value: '',
    name: '',
  },
  submitting: false,
  stage: 'basic',
  formError: null,
};

class NewNoteModal extends Component {
  state = {...cleanState};
  componentDidMount() {
    if (this.props.contactSelect) {
      this.setState({contactSelect: this.props.contactSelect});
    }
    if (this.props.accountSelect) {
      this.setState({accountSelect: this.props.accountSelect});
    }
    if (this.props.leadSelect) {
      this.setState({leadSelect: this.props.leadSelect});
    }
  }

  handleClose() {
    if (this.props.contactSelect) {
      this.setState({...this.state, contactSelect: this.props.contactSelect});
    }
    else {
      this.setState({...cleanState});
    }
    this.props.close();
  };
  handleSubmit() {
    const {note, contactSelect, accountSelect, leadSelect} = this.state;
    const {currentUser} = this.props;
    if (!note.value) {
      this.setState({note: {...note, error: 'Note is a required field.'}});
    }
    else {
      this.setState({submitting: true});
      const sumbitData = {
        Note: note.value,
        Owner: {
          Name: currentUser.displayName,
          Uid: currentUser.uid,
        },
        Date: firebase.firestore.Timestamp.now(),
        Contact: {
          Id: contactSelect.value || leadSelect.value || '',
          Name: contactSelect.name || leadSelect.name || '',
        },
      };
      const firestoreBatch = Firestore.batch();
      let newAccountNoteRef;
      if (accountSelect) {
        newAccountNoteRef = Firestore.collection('CRM-Accounts')
          .doc(accountSelect.id)
          .collection('Notes')
          .doc();
        firestoreBatch.set(newAccountNoteRef, sumbitData);
      }
      if (contactSelect.value) {
        const newContactNoteRef = Firestore.collection('CRM-Contacts')
          .doc(contactSelect.value)
          .collection('Notes')
          .doc(newAccountNoteRef.id);
        firestoreBatch.set(newContactNoteRef, sumbitData);
      }
      if (leadSelect.value) {
        const newLeadNoteRef = Firestore.collection('CRM-Leads')
          .doc(leadSelect.value)
          .collection('Notes')
          .doc();
        firestoreBatch.set(newLeadNoteRef, sumbitData);
      }
      firestoreBatch
        .commit()
        .then(() => {
          this.setState({submitting: false, stage: 'success'});
        })
        .catch(error => {
          this.setState({submitting: false, formError: 'Error submitting data. Try again.'});
        });
    }
  };
  render() {
    const {newNoteModalOpen, contacts, contactsRef, readOnly} = this.props;
    const {leadSelect} = this.state;
    const core = {
      dialog: {
        open: newNoteModalOpen,
        scroll: 'body',
        transitionDuration: {exit: 0},
        fullWidth: true,
      },
      noteField: {
        error: this.state.note.error ? true : false,
        fullWidth: true,
        helperText: this.state.note.error,
        id: 'note',
        label: this.state.note.display,
        margin: 'normal',
        multiline: true,
        required: true,
        value: this.state.note.value,
        onChange: e => this.setState({note: {...this.state.note, value: e.target.value}}),
      },
      textField: key => {
        return {
          error: this.state[key].error ? true : false,
          fullWidth: true,
          helperText: this.state[key].error,
          id: key,
          label: this.state[key].display,
          margin: 'normal',
          value: this.state[key].value,
          InputProps: {
            readOnly: readOnly,
          },
          onChange: e => {
            this.setState({
              [key]: {
                display: this.state[key].display,
                value: e.target.value,
                name: contactsRef[e.target.value].Name.Full,
                refAccount: contactsRef[e.target.value].RefAccount.Id,
              },
            });
          },
        };
      },
      leads: {
        fullWidth: true,
        label: 'Lead',
        margin: 'normal',
        value: leadSelect.value,
        InputProps: {
          readOnly: readOnly,
        },
        onChange: () => {},
      },
      submitButton: {
        color: 'primary',
        onClick: () => this.handleSubmit(),
        text: 'Submit',
        loading: this.state.submitting,
      },
      submitConfirmation: {
        text: 'New note successfully added.',
        stage: this.state.stage,
      },
    };

    return (
      <Dialog {...core.dialog}>
        <DialogTitle>New Note</DialogTitle>
        <DialogContent>
          <Fragment>
            <Collapse in={this.state.stage === 'basic'}>
              <DialogContentText>Enter note information.</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField {...core.noteField} />
                </Grid>
                <Grid item xs={12}>
                  {this.props.type === 'Lead' && (
                    <TextField {...core.leads} select>
                      <MenuItem key={leadSelect.value} value={leadSelect.value}>
                        {leadSelect.name}
                      </MenuItem>
                    </TextField>)}
                  {}
                  {this.props.type === 'account' && (
                    <TextField {...core.textField('contactSelect')} select disabled={contacts.length === 0} helperText={contacts.length === 0 ? 'No Contacts Available' : ''}>
                      {contacts.map(contact => (
                        <MenuItem key={contact.ContactId} value={contact.ContactId}>
                          {contact.Name.Full}
                        </MenuItem>
                      ))}
                    </TextField>)}
                </Grid>
              </Grid>
              <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
            </Collapse>
          </Fragment>
          <SubmitConfirmation {...core.submitConfirmation} />
        </DialogContent>
        {this.state.stage === 'success' ? (
          <DialogActions style={{justifyContent: 'flex-end'}}>
            <Button onClick={() => this.handleClose()} color='primary'>
              Close
            </Button>
          </DialogActions>
        ) : (
          <DialogActions style={{justifyContent: 'space-between'}}>
            <Button onClick={() => this.handleClose()} color='secondary' disabled={this.state.submitting}>
              Cancel
            </Button>
            <SubmitButton {...core.submitButton} />
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

NewNoteModal.propTypes = {
  close: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  newNoteModalOpen: PropTypes.bool.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object),
  contactsRef: PropTypes.objectOf(PropTypes.object),
  readOnly: PropTypes.bool.isRequired,
  contactSelect: PropTypes.object,
  accountSelect: PropTypes.object,
  leadSelect: PropTypes.object,
  type: PropTypes.string,
};
export default NewNoteModal;
