import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
  LinearProgress,
} from '@material-ui/core';
import {Table} from '@kbi/component-library';
import {ExpandMore, Search} from '@material-ui/icons';
import {NoteModal} from './Notes/';
import {Firestore} from 'config.js';

class NotesPanel extends Component {
  state = {
    noteModalOpen: false,
    notes: null,
    listener: null,
    selectedNote: null,
    /* eslint-disable no-invalid-this */
    firestoreCollection: this.props.firestoreCollection,
    documentId: this.props.documentId,
    /* eslint-enable no-invalid-this */
    expanded: false,
  };
  componentDidMount() {
    const listener = Firestore.collection(this.state.firestoreCollection)
      .doc(this.state.documentId)
      .collection('Notes')
      .onSnapshot(
        {includeMetadataChanges: true},
        snap => {
          const notes = [];
          if (!snap.empty) {
            snap.forEach(doc => {
              if (!doc.metadata.hasPendingWrites) {
                notes.push({...doc.data(), Date: doc.data().Date.toDate(), id: doc.id});
              }
            });
          }
          this.setState({notes});
        },
        error => { },
      );
    this.setState({listener});
  }
  componentWillUnmount() {
    this.state.listener();
  }

  render() {
    const {
      contacts,
      accountSelect,
      leadRef,
    } = this.props;

    const {noteModalOpen, notes, selectedNote, firestoreCollection, expanded} = this.state;
    const noteColumns =
      this.state.firestoreCollection === 'CRM-Accounts' ?
        [
          {accessor: 'Date', Header: 'Date', type: 'datetime'},
          {accessor: 'Owner.Name', Header: 'By'},
          {accessor: 'Contact.Name', Header: 'Contact'},
          {accessor: 'Note', Header: 'Note'},
        ] :
        [
          {accessor: 'Date', Header: 'Date', type: 'datetime'},
          {accessor: 'Owner.Name', Header: 'By'},
          {accessor: 'Note', Header: 'Note'},
        ];
    const core = {
      modalNote: {
        close: () => this.setState({noteModalOpen: false, selectedNote: null}),
        selectableContacts: contacts,
        accountRef: accountSelect?.id,
        leadRef: leadRef,
        selectedNote,
        firestoreCollection: firestoreCollection,
      },
      tableNotes: {
        columns: noteColumns,
        sortInitial: [{id: 'Date', desc: true}],
        data: notes,
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'View Note',
            onClick: ({rowData}) => this.setState({noteModalOpen: true, selectedNote: rowData}),
          },
        ],
      },
      emptyNotesButton: {
        onClick: e => {
          e.stopPropagation();
          this.setState({noteModalOpen: true});
        },
        variant: 'outlined',
      },
    };
    const details = () => {
      if (!notes) {
        return <LinearProgress />;
      }
      else if (notes.length === 0) {
        return (
          <AccordionDetails>
            <Grid container justify='space-between'>
              <Typography variant='subtitle1'>
                No notes found for this {accountSelect ? `account` : 'contact'}.
              </Typography>
            </Grid>
          </AccordionDetails>
        );
      }
      else {
        return (
          <AccordionDetails style={{padding: '0px'}}>
            <Grid container>
              <Grid item xs={12}>
                <Table {...core.tableNotes} />
              </Grid>
            </Grid>
          </AccordionDetails>
        );
      }
    };

    return (
      <Fragment>
        <Accordion expanded={expanded} onChange={() => this.setState({expanded: !expanded})}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Grid container justify='space-between'>
              <div>
                <Typography>Notes</Typography>
              </div>
              {expanded ? (
                <div>
                  <Button {...core.emptyNotesButton}>Add Note</Button>
                </div>
              ) : null}
            </Grid>
          </AccordionSummary>
          {details()}
        </Accordion>
        {noteModalOpen && <NoteModal {...core.modalNote} />}
      </Fragment>
    );
  }
}

NotesPanel.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object),
  contactSelect: PropTypes.object,
  accountSelect: PropTypes.object,
  leadRef: PropTypes.string,
  firestoreCollection: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
};

export default NotesPanel;
