import React, {Component, Fragment} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Button,
  Collapse,
  Grid,
  Checkbox,
  TextField,
  RadioGroup,
  Radio,
  LinearProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {KbiAutoText} from 'kbi-components';
import {Alert} from '@kbi/component-library';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';

const cleanState = {
  contactName: {
    value: '',
    error: null,
    display: 'Select Contact',
  },
  contactEmail: {
    value: '',
    error: null,
    display: 'Contact Email',
  },
  contactPhone: {
    value: '',
    error: null,
    display: 'Contact Phone',
  },
  checkboxObject: {}, // this object is filled after the user starts to check off items from the materials received from firestore
  selectedContact: null,
  stage: 'basic',
  submitting: false,
  formError: null,
};

class MaterialProfileModal extends Component {
  state = {
    ...cleanState,
    // eslint-disable-next-line no-invalid-this
    checkboxObject: this.createCleanStateCheckboxObj(),
  };
  createCleanStateCheckboxObj() {
    const objectForState = {};
    this.props.materials.inboundList.forEach(material => {
      objectForState[material.MaterialId] = {checked: false};
      if (material.FlagStatus === 'Required') {
        objectForState[material.MaterialId].flag = material.Flags[0].Name;
      }
      else {
        objectForState[material.MaterialId].flag = '';
      }
    });
    return objectForState;
  }
  componentDidMount() {
    const {selectedProfile, contacts, locations} = this.props;
    if (this.props.selectedProfile) {
      const selectedProfileMaterials = {...this.state.checkboxObject};
      this.props.selectedProfile.Materials.forEach(material => {
        selectedProfileMaterials[material.Ref].checked = true;
        selectedProfileMaterials[material.Ref].flag = material.PreferredFlag;
      });
      const objectToPassToState = {
        checkboxObject: selectedProfileMaterials,
        locations,
      };
      const matchedContact = contacts.find(contact => contact.Name.Full === selectedProfile.Contact.Name);
      if (matchedContact) {
        objectToPassToState.contactName = {...this.state.contactName, value: matchedContact.Name.Full};
        objectToPassToState.contactEmail = {
          ...this.state.contactEmail,
          error: null,
          value: matchedContact.Email.Work || matchedContact.Email.Home || 'N/A',
        };
        objectToPassToState.contactPhone = {
          ...this.state.contactPhone,
          error: null,
          value: matchedContact.Phone.Office || matchedContact.Phone.Mobile || matchedContact.Phone.Home || 'N/A',
        };
      }
      this.setState(objectToPassToState);
    }
    else {
      this.setState({locations});
    }
  }
  handleClose() {
    this.props.close();
  };
  matchSelectedMaterials() {
    const selectedMaterials = [];
    this.props.materials.inboundList.forEach(material => {
      if (this.state.checkboxObject[material.MaterialId].checked) {
        selectedMaterials.push({
          Name: material.UnitDetails.MaterialName,
          Ref: material.MaterialId,
          PreferredFlag: this.state.checkboxObject[material.MaterialId].flag,
        });
      }
    });
    return selectedMaterials;
  }
  createLocationDateForFirestore(location) {
    return {
      Address: location.Address,
      EpaId: location.EpaId,
      LocationName: location.LocationName || location.AccountName,
      NaicsId: location.NaicsId,
      SicId: location.SicId,
      IdRef: location.id || location.AccountId,
    };
  }
  handleSubmit() {
    const {selectedAccount, currentUser, selectedProfile, primaryLocation} = this.props;
    const {contactName, contactEmail, contactPhone, locations} = this.state;
    const firestoreBatch = Firestore.batch();
    let locationsSubcollectionRef = null;
    const dateInOneYear = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate() - 1),
    );
    const materialProfileSubcollectionRef = Firestore.collection('CRM-Accounts')
      .doc(selectedAccount.AccountId)
      .collection('Material-Profiles');
    const profileDataForFirestore = {
      Materials: this.matchSelectedMaterials(),
      Contact: {Name: contactName.value, Email: contactEmail.value, Phone: contactPhone.value},
      Account: {
        Name: selectedAccount.AccountName,
        Address: primaryLocation.Address,
        Fax: primaryLocation.Phone.Fax,
      },
      ApprovedBy: '',
      UploadedBy: '',
      Active: false, // new profiles arent set to active until they have been approved.
    };
    if (selectedProfile) {
      const updateProfileRef = materialProfileSubcollectionRef.doc(selectedProfile.id);
      locationsSubcollectionRef = updateProfileRef.collection('Profile-Locations');
      firestoreBatch.update(materialProfileSubcollectionRef.doc(selectedProfile.id), {
        ...profileDataForFirestore,
        'System.UpdatedBy': {Name: currentUser.displayName, Id: currentUser.uid, Email: currentUser.email},
      });
    }
    else {
      const newMaterialProfileRef = materialProfileSubcollectionRef.doc();
      locationsSubcollectionRef = newMaterialProfileRef.collection('Profile-Locations');
      firestoreBatch.set(newMaterialProfileRef, {
        ...profileDataForFirestore,
        Expiration: firebase.firestore.Timestamp.fromDate(dateInOneYear),
        EffectiveDate: firebase.firestore.Timestamp.now(),
        System: {
          CreatedBy: {Name: currentUser.displayName, Id: currentUser.uid, Email: currentUser.email},
          CreatedOn: firebase.firestore.Timestamp.now(),
          UpdatedBy: {Name: currentUser.displayName, Id: currentUser.uid, Email: currentUser.email},
          UpdatedOn: firebase.firestore.Timestamp.now(),
        },
      });
    }
    locations.forEach(location => {
      if (location.Active) {
        const dataToSendToFirestore = this.createLocationDateForFirestore(location);
        firestoreBatch.set(locationsSubcollectionRef.doc(dataToSendToFirestore.IdRef), dataToSendToFirestore);
      }
    });
    firestoreBatch.commit().then(() => {
      this.setState({stage: 'success', submitting: false});
    });
  }
  handleNext() {
    let dataError = false;
    const {stage, contactName, contactEmail, contactPhone, checkboxObject} = this.state;
    if (stage === 'basic') {
      this.setState({
        contactName: {...contactName, error: null},
        contactEmail: {...contactEmail, error: null},
        contactPhone: {...contactPhone, error: null},
      });
      if (!contactName.value) {
        dataError = true;
        this.setState({contactName: {...contactName, error: 'You must select a contact.'}});
      }
      if (contactEmail.value === 'N/A') {
        dataError = true;
        this.setState({contactEmail: {...contactEmail, error: 'Your contact must have an email.'}});
      }
      if (contactPhone.value === 'N/A') {
        dataError = true;
        this.setState({contactPhone: {...contactPhone, error: 'Your contact must have a phone number.'}});
      }
      if (!dataError) return this.setState({stage: 'materials'});
    }
    if (stage === 'materials') {
      let atLeastOneCheck = false;
      this.setState({formError: null});
      for (const materialId in checkboxObject) {
        // loops through the checkboxObject that the user has filled (or left empty) and makes sure there is at least one true value
        if (checkboxObject[materialId]) {
          atLeastOneCheck = true;
          break;
        }
      }
      // if there is a true value, the form is validated and they can continue
      if (!atLeastOneCheck) {
        // if there is not at least one selection, the form passes an error to the Alert component and stops progress
        dataError = true;
        this.setState({formError: 'At least one material must be selected'});
      }
      if (!dataError) {
        return this.setState({submitting: true}, () => {
          this.handleSubmit();
        });
      }
    }
  };
  handleBack() {
    const {stage} = this.state;
    if (stage === 'materials') {
      this.setState({stage: 'basic'});
    }
  };
  createRadioButtons(material) {
    const arrayToMap = [...material.Flags].sort((a, b) => a.Name > b.Name ? 1 : -1);
    if (material.FlagStatus === 'Optional') {
      arrayToMap.unshift({Name: '(none)'});
    }
    return arrayToMap.map((flag, index) => {
      return (
        <FormControlLabel key={index}
          value={flag.Name === '(none)' ? '' : flag.Name}
          control={<Radio color='primary' />}
          label={flag.Name}
        />);
    });
  }
  createCheckboxes() {
    const {checkboxObject} = this.state;
    if (this.props.materials.inboundList.length === 0) return <LinearProgress />;
    const arrayOfCheckboxes = Object.values(checkboxObject);
    const checkAllProps = {
      value: 'Check All',
      color: 'primary',
      checked:
        arrayOfCheckboxes.every(material => material.checked === true), // if all boxes checked, the check all is checked
      onChange: e => {
        const selectAllObject = {...this.state.checkboxObject};
        for (const materialId in selectAllObject) {
          if (Object.prototype.hasOwnProperty.call(selectAllObject, materialId)) {
            selectAllObject[materialId].checked = e.target.checked;
          }
        }
        this.setState({formError: null, checkboxObject: selectAllObject});
      },
    };
    return (
      <FormGroup>
        <FormControlLabel control={<Checkbox value='Check all' {...checkAllProps} />} label='Check All' />
        {this.props.materials.inboundList
          .sort((a, b) => {
            if (a.UnitDetails.MaterialName < b.UnitDetails.MaterialName) {
              return -1;
            }
            if (a.UnitDetails.MaterialName > b.UnitDetails.MaterialName) {
              return 1;
            }
            return 0;
          })
          .map((material, index) => {
            const materialCheckboxProps = {
              checked: checkboxObject[material.MaterialId].checked,
              color: 'primary',
              onChange: e => {
                const objectForState = {...checkboxObject};
                objectForState[material.MaterialId].checked = e.target.checked;
                this.setState({formError: null, checkboxObject: objectForState});
              },
            };
            const radioGroupProps = {
              value: this.state.checkboxObject[material.MaterialId].flag,
              row: true,
              style: {marginLeft: '15px'},
              onChange: e => {
                const objectForState = {...checkboxObject};
                objectForState[material.MaterialId].flag = e.target.value;
                this.setState({formError: null, checkboxObject: objectForState});
              },
            };
            return (
              <Fragment key={index}>
                <FormControlLabel
                  control={<Checkbox value={material.UnitDetails.MaterialName} {...materialCheckboxProps} />}
                  label={material.UnitDetails.MaterialName}
                />
                <Collapse in={checkboxObject[material.MaterialId].checked && this.props.materials.ref[material.MaterialId].Flags.length ? true : false}>
                  <RadioGroup {...radioGroupProps}>
                    {this.createRadioButtons(material)}
                  </RadioGroup>
                </Collapse>
              </Fragment>
            );
          })}
      </FormGroup>
    );
  }
  render() {
    const {stage, contactName, selectedContact, submitting} = this.state;
    const {modalOpen, contacts, selectedProfile} = this.props;
    const core = {
      dialog: {
        open: modalOpen,
        scroll: 'body',
        transitionDuration: {exit: 0},
        fullWidth: true,
      },
      contactAutoText: {
        itemKey: 'Name.Full',
        label: contactName.display,
        maxListItems: 5,
        minUserInput: 1,
        items: contacts,
        value: this.state.contactName.value,
        textFieldProps: {
          fullWidth: true,
          error: contactName.error ? true : false,
          helperText: contactName.error,
          margin: 'normal',
          required: true,
          autoComplete: 'none',
        },
        onItemChange: (value, item) => {
          if (item) {
            this.setState({
              selectedContact: item,
              contactName: {...this.state.contactName, value: value, error: null},
              contactEmail: {
                ...this.state.contactEmail,
                error: null,
                value: item.Email.Work || item.Email.Home || 'N/A',
              },
              contactPhone: {
                ...this.state.contactPhone,
                error: null,
                value: item.Phone.Office || item.Phone.Mobile || item.Phone.Home || 'N/A',
              },
            });
          }
          if (!item) {
            this.setState({
              selectedContact: null,
              contactName: {...this.state.contactName, value: ''},
              contactEmail: {...this.state.contactEmail, value: ''},
              contactPhone: {...this.state.contactPhone, value: ''},
            });
          }
        },
      },
      contactTextField: key => {
        return {
          error: this.state[key].error ? true : false,
          fullWidth: true,
          helperText: this.state[key].error,
          id: key,
          disabled: true,
          label: this.state[key].display,
          margin: 'normal',
          autoComplete: 'none',
          value: this.state[key].value,
          onChange: e =>
            this.setState({[key]: {error: null, display: this.state[key].display, value: e.target.value}}),
        };
      },
      cancelButton: {
        onClick: () => this.handleClose(),
        color: 'secondary',
        disabled: submitting,
      },
      submitButton: {
        color: 'primary',
        onClick: () => this.handleNext(),
        loading: submitting,
        text: stage === 'materials' ? 'Submit' : 'Next',
      },
      backButton: {
        onClick: () => this.handleBack(),
        color: 'primary',
        disabled: stage === 'basic' || submitting,
      },
      closeButton: {
        onClick: () => this.handleClose(),
        color: 'primary',
      },
      submitConfirmation: {
        text: 'Material profile successfully generated.',
        stage: stage,
      },
      firstStageTitle: selectedProfile ?
        'The contact associated with this profile.' :
        'Select the contact that you are dealing with.',
      secondStageTitle: selectedProfile ?
        'The selected materials for this profile.' :
        'Select the materials this profile will include.',
    };
    return (
      <Dialog {...core.dialog}>
        <DialogTitle>New Material Profile</DialogTitle>
        <DialogContent>
          <Collapse in={stage === 'basic'}>
            <DialogContentText>{core.firstStageTitle}</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <KbiAutoText {...core.contactAutoText} />
              </Grid>
              {selectedContact || selectedProfile ? (
                <Fragment>
                  <Grid item xs={6}>
                    <TextField {...core.contactTextField('contactPhone')} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField {...core.contactTextField('contactEmail')} />
                  </Grid>
                </Fragment>
              ) : null}
            </Grid>
          </Collapse>
          <Collapse in={stage === 'materials'}>
            <DialogContentText gutterBottom>{core.secondStageTitle}</DialogContentText>
            <Grid container spacing={2}>
              {this.createCheckboxes()}
            </Grid>
            <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
          </Collapse>
          <SubmitConfirmation {...core.submitConfirmation} />
        </DialogContent>
        {stage === 'success' ? (
          <DialogActions style={{justifyContent: 'flex-end'}}>
            <Button {...core.closeButton}>Close</Button>
          </DialogActions>
        ) : (
          <DialogActions style={{justifyContent: 'space-between'}}>
            <Button {...core.cancelButton}>Cancel</Button>
            <div>
              <Button {...core.backButton}>Back</Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {currentUser: state.auth.currentUser, materials: state.firestore.materials};
};

MaterialProfileModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object),
  close: PropTypes.func.isRequired,
  selectedAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectedProfile: PropTypes.object,
  materials: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
    materialList: PropTypes.arrayOf(PropTypes.object),
    yieldList: PropTypes.arrayOf(PropTypes.object),
    processList: PropTypes.arrayOf(PropTypes.object),
    inboundList: PropTypes.arrayOf(PropTypes.object),
    listener: PropTypes.func,
  }),
  locations: PropTypes.arrayOf(PropTypes.object),
  primaryLocation: PropTypes.object,
};

export default connect(mapStateToProps)(MaterialProfileModal);
