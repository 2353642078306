import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, List, ListItem, Paper, Typography, MenuList, MenuItem, LinearProgress} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {Domain, Edit, ImportExport, LocalPrintshop, LocationOn, MailOutline} from '@material-ui/icons';
import {Smartphone, Warning} from '@material-ui/icons';
import {BackButton} from 'components/';
import {EventsTasksPanel, EmailPanel, NotesPanel} from '../panels';
import {connect} from 'react-redux';
import flowRight from 'lodash.flowright';
import {Businessman} from '../components/';
import {LeadInfoModal} from './Leads/';
import {Functions} from 'config.js';
import {ConvertFromLeadModal} from './LeadDetails/';

class LeadDetails extends Component {
  state = {
    events: [],
    loadingEvents: false,
    threads: [],
    loadingEmails: false,
    editleadModalOpen: false,
    convertFromLeadModalOpen: false,
  };
  componentDidMount() {
    const {leads, match, users} = this.props;
    if (users && leads) {
      const lead = leads.ref[match.params.LeadId];
      if (lead.Contact.Email) {
        this.handleGetEmailsFrom(lead.Contact.Email, users);
        this.handleGetEvents([lead.Contact.Email]);
      }
      else this.setState({threads: [], events: []});
    }
  }
  componentDidUpdate(prevProps) {
    const {leads, match, users, history} = this.props;
    if (users && leads && prevProps.users !== users && prevProps.leads !== leads) {
      const lead = leads.ref[match.params.LeadId];
      if (lead.Contact.Email) {
        this.handleGetEmailsFrom(lead.Contact.Email, users);
        this.handleGetEvents([lead.Contact.Email]);
      }
      else this.setState({threads: [], events: []});
    }
    if (leads && !leads.ref[match.params.LeadId]) {
      history.push('/leads');
    }
  }

  handleGetEvents(contactAddress) {
    this.setState({loadingEvents: true});
    Functions.httpsCallable('getEvents')({contactAddress})
      .then(res => {
        this.setState(prevState => ({events: [...prevState.events, ...res.data], loadingEvents: false}));
      })
      .catch(err => {
        this.setState({loadingEvents: false});
        console.error('Events err', err);
      });
  };
  handleGetEmailsFrom(contactEmail, users) {
    this.setState({loadingEmails: true});
    users.forEach(user => {
      if (user.nylasAccessToken) {
        Functions.httpsCallable('getEmailsFrom')({
          accessToken: user.nylasAccessToken,
          email: contactEmail,
        })
          .then(res => {
            const threadsWithUser = res.data.map(thread => {
              return {...thread, userToken: user.nylasAccessToken, from: user.email};
            });
            this.setState(prevState => ({
              threads: [...prevState.threads, ...threadsWithUser],
              loadingEmails: false,
            }));
          })
          .catch(err => {
            this.setState({loadingEmails: false});
            console.error('EMAIL ERROR: ', err);
          });
      }
    });
  };
  displayAddress() {
    const {Address} = this.props.leads.ref[this.props.match.params.LeadId];
    if (Address.Line1) {
      if (Address.Country === 'United States') {
        return `${Address.City}, ${Address.State} ${Address.PostalCode}`;
      }
      else if (Address.Country === 'Mexico') {
        return `${Address.PostalCode} ${Address.City}, ${Address.State}`;
      }
      else if (Address.Country === 'Japan') {
        return `${Address.Prefecture}, ${Address.Province}`;
      }
      else if (Address.Country === 'South Korea') {
        return `${Address.Subdivision} ${Address.City}, ${Address.Province}`;
      }
      else if (Address.Country === 'Canada') {
        return `${Address.City}, ${Address.Province}, ${Address.PostalCode}`;
      }
      else return `${Address.City}`;
    }
  }
  render() {
    if (!this.props.leads || !this.props.currentUser || !this.props.match) return <LinearProgress />;
    if (!this.props.leads.ref[this.props.match.params.LeadId]) return <LinearProgress />;
    const {leads, classes, currentUser, match, users} = this.props;
    const {threads} = this.state;
    const {AccountName, Phone, Website, Contact, Address, LeadId} = leads.ref[match.params.LeadId];
    const core = {
      buttonEdit: {
        size: 'small',
        variant: 'outlined',
        style: {marginLeft: '8px'},
        onClick: () => this.setState({editleadModalOpen: true}),
      },
      editLeadModal: {
        modalOpen: this.state.editleadModalOpen,
        selectedLead: leads.ref[match.params.LeadId],
        close: () => this.setState({editleadModalOpen: false}),
      },
      buttonConvertFromLead: {
        size: 'small',
        style: {marginLeft: '8px'},
        variant: 'outlined',
        onClick: () => this.setState({convertFromLeadModalOpen: true}),
      },
      convertFromLeadModal: {
        modalOpen: this.state.convertFromLeadModalOpen,
        selectedLead: leads.ref[match.params.LeadId],
        documentId: LeadId,
        close: () => this.setState({convertFromLeadModalOpen: false}),
      },
      eventsTasksPanel: {
        contact: leads ?
          {
            Email: {
              work: leads.ref[match.params.LeadId].Contact.Email,
              home: '',
            },
          } :
          {},
        loadingEvents: this.state.loadingEvents,
        events: this.state.events,
        classes,
      },
      emailsPanel: {
        emails: null,
        classes,
        threads,
        users: users ? users : [],
        token: currentUser.token,
        handleNewEmail: this.handleEmailModalOpen,
        contactAddress: leads ?
          {
            work: leads.ref[match.params.LeadId].Contact.Email,
            home: '',
          } :
          {},
      },
      panelNotes: {
        documentId: LeadId,
        firestoreCollection: 'CRM-Leads',
        leadRef: LeadId,
        contacts: [leads.ref[match.params.LeadId]],
      },
      menuIcon: {
        style: {paddingRight: '20px', fontSize: '3rem'},
      },
      menuPrimaryText: {
        style: {lineHeight: 1.5, fontSize: '0.85rem'},
        variant: 'subtitle1',
      },
      menuSecondaryText: {
        noWrap: true,
        style: {lineHeight: 1.2, fontSize: '1em'},
        variant: 'caption',
      },
      menuDiv: {
        style: {display: 'flex', flexDirection: 'column'},
      },
      menuDividers: {
        variant: 'subtitle1',
        style: {width: '100%', textAlign: 'left', marginLeft: '8px', fontWeight: '600'},
      },
    };
    const openEditModalFunction = () => {
      if (this.state.editleadModalOpen) {
        return <LeadInfoModal {...core.editLeadModal} />;
      }
      else return null;
    };
    const openConvertModalFunction = () => {
      if (this.state.convertFromLeadModalOpen) {
        return <ConvertFromLeadModal {...core.convertFromLeadModal} />;
      }
      else return null;
    };
    return (
      <Fragment>
        <div className='sideScrollingContainer'>
          <BackButton />
          <Button {...core.buttonEdit}>
            <Edit className={classes.extendedIcon} />
            Edit
          </Button>
          <Button {...core.buttonConvertFromLead}>
            <ImportExport className={classes.extendedIcon} />
            Convert From Lead
          </Button>
        </div>
        <Grid spacing={5} container style={{marginTop: '8px'}}>
          <Grid item xs={12} md={3}>
            <Paper className={classes.paper}>
              <Businessman style={{fontSize: '10em', fill: 'slategray', width: '100%'}} />
              <List style={{minWidth: '100%', maxWidth: '100%'}}>
                <ListItem style={{flexDirection: 'column', justifyContent: 'space-around'}}>
                  <Typography variant='h6' style={{lineHeight: '1.3', textAlign: 'center'}}>
                    {Contact.Name}
                  </Typography>
                  <Typography variant='caption' style={{fontSize: '.9rem', lineHeight: '1.3', textAlign: 'center'}}>
                    {AccountName}
                  </Typography>
                  {Website && (
                    <Typography variant='caption'>
                      <a href={Website} target='_blank' rel='noopener noreferrer'>
                        {Website}
                      </a>
                    </Typography>
                  )}
                </ListItem>
                <ListItem style={{flexDirection: 'column'}}>
                  <MenuList className={classes.menuStyles}>
                    {Address.Line1 && (
                      <StyledMenuItem disableGutters className={classes.disabled}>
                        <Typography {...core.menuDividers}>Primary Address</Typography>
                      </StyledMenuItem>
                    )}
                    {Address.Line1 && (
                      <StyledMenuItem className={classes.disabled}>
                        <LocationOn {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuPrimaryText}>
                            {leads.ref[match.params.LeadId].Address.TripleLine.Line1}
                          </Typography>
                          {leads.ref[match.params.LeadId].Address.TripleLine.Line2 && (
                            <Typography {...core.menuSecondaryText} style={{fontSize: '0.85rem'}}>
                              {leads.ref[match.params.LeadId].Address.TripleLine.Line2}
                            </Typography>
                          )}
                          <Typography {...core.menuPrimaryText}>
                            {leads.ref[match.params.LeadId].Address.TripleLine.Line3 &&
                              leads.ref[match.params.LeadId].Address.TripleLine.Line3}
                          </Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                    {(Phone.Primary || Phone.Emergency || Phone.Fax || Phone.Mobile || Contact.Email) && (
                      <StyledMenuItem disableGutters className={classes.disabled}>
                        <Typography {...core.menuDividers}>Contact Information</Typography>
                      </StyledMenuItem>
                    )}
                    {Phone.Primary && (
                      <StyledMenuItem className={classes.disabled}>
                        <Domain {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuSecondaryText}>Primary</Typography>
                          <Typography {...core.menuPrimaryText}>{Phone.Primary}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                    {Phone.Mobile && (
                      <StyledMenuItem className={classes.disabled}>
                        <Smartphone {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuSecondaryText}>Mobile</Typography>
                          <Typography {...core.menuPrimaryText}>{Phone.Mobile}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                    {Phone.Fax && (
                      <StyledMenuItem className={classes.disabled}>
                        <LocalPrintshop {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuSecondaryText}>Fax</Typography>
                          <Typography {...core.menuPrimaryText}>{Phone.Fax}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                    {Phone.Emergency && (
                      <StyledMenuItem className={classes.disabled}>
                        <Warning {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuSecondaryText}>Emergency</Typography>
                          <Typography {...core.menuPrimaryText}>{Phone.Emergency}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                    {Contact.Email && (
                      <StyledMenuItem className={classes.disabled}>
                        <MailOutline {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuSecondaryText}>Email</Typography>
                          <Typography {...core.menuPrimaryText}>{Contact.Email}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                  </MenuList>
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <NotesPanel {...core.panelNotes} />
            <EventsTasksPanel {...core.eventsTasksPanel} />
            <EmailPanel {...core.emailsPanel} />
          </Grid>
        </Grid>
        {openEditModalFunction()}
        {openConvertModalFunction()}
      </Fragment>
    );
  }
}

const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
    justifyContent: 'center',
  },
  menuStyles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'ghostwhite',
    border: '1px solid darkgray',
    margin: '8px 0px',
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
});
const StyledMenuItem = withStyles({
  root: {
    minHeight: '0px',
    whiteSpace: 'normal',
  },
})(MenuItem);
const mapStateToProps = state => {
  return {leads: state.firestore.leads, currentUser: state.auth.currentUser, users: state.firestore.users};
};
LeadDetails.propTypes = {
  leads: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    ref: PropTypes.objectOf(PropTypes.object),
  }),
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object),
};
export default flowRight(
  connect(mapStateToProps),
  withStyles(styles, {withTheme: true}),
  withRouter,
)(LeadDetails);
