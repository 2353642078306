/* eslint-disable no-console */
import {Firestore, Functions} from 'config.js';
import {
  SET_LEADS,
  SET_CONTACTS,
  GET_OUTLOOK_USERS,
  SET_ACCOUNT_PROFILES,
  GET_SHIPMENTS,
  SET_SHIPMENT_LISTENER,
  SET_ACCOUNTS,
  SET_ACCOUNT_LISTENER,
  GET_LOCATIONS,
  SET_PURCHASE_ORDERS,
  SET_PURCHASE_ORDER_LISTENER,
  SET_MATERIALS,
  SET_ALL_MATERIAL_TYPES,
  SET_USERS,
} from './types';

export const acGetUsers = () => {
  return dispatch => {
    return Functions.httpsCallable('adminGetUsers')()
      .then(result => {
        let payload = result.data.filter(user => {
          const {active} = user;
          const {roleCRM} = user.customClaims;
          if (roleCRM === 'No Access' || roleCRM === undefined || roleCRM === null) {
            return false;
          }
          else if (!active) {
            return false;
          }
          return true;
        });
        payload = payload.map(user => {
          return {
            displayName: user.displayName,
            email: user.email,
            roleCatalyst: user.customClaims.roleCatalyst,
            roleEHS: user.customClaims.roleEHS,
            roleCRM: user.customClaims.roleCRM,
            rolePortalAdmin: user.customClaims.rolePortalAdmin,
            roleTracking: user.customClaims.roleTracking,
            outlookUser: user.customClaims.outlookUser,
            uid: user.uid,
            nylasAccessToken: user.customClaims.nylasAccessToken,
            nylasId: user.customClaims.nylasId,
            nylasFolderId: user.customClaims.nylasFolderId,
          };
        });
        dispatch({type: SET_USERS, payload});
      })
      .catch(error => {
        console.error('error', error);
      });
  };
};
export const acGetLeads = () => {
  return dispatch => {
    Firestore.collection('CRM-Leads')
      .onSnapshot(
        snap => {
          const leadsList = [];
          const active = [];
          let ref = {};
          snap.forEach(doc => {
            leadsList.push({...doc.data(), LeadId: doc.id});
            if (doc.data().Active) active.push({...doc.data(), LeadId: doc.id});
            ref = {...ref, [doc.id]: {...doc.data(), LeadId: doc.id}};
          });
          dispatch({type: SET_LEADS, payload: {leadsList, ref, active}});
        },
        error => { },
      );
  };
};
export const acGetContacts = () => {
  return dispatch => {
    Firestore.collection('CRM-Contacts').onSnapshot(
      snap => {
        const list = [];
        let ref = {};
        const activeList = [];
        snap.forEach(doc => {
          const docData = {...doc.data(), ContactId: doc.id};
          list.push(docData);
          activeList.push(docData);
          ref = {...ref, [docData.ContactId]: docData};
        });
        dispatch({type: SET_CONTACTS, payload: {list, ref, active: activeList}});
      },
      error => { },
    );
  };
};

export const acGetOutlookUsers = () => {
  return dispatch => {
    Functions.httpsCallable('outlookUsers')()
      .then(resp => {
        if (resp.data.success) {
          dispatch({type: GET_OUTLOOK_USERS, payload: resp.data.users.value});
        }
        else throw new Error();
      }).catch(error => console.error(error));
  };
};
export const acGetAccountProfiles = accountId => {
  return async dispatch => {
    const materialProfilesRef = Firestore.collection('CRM-Accounts')
      .doc(accountId)
      .collection('Material-Profiles');
    const listener = materialProfilesRef.onSnapshot(
      {includeMetadataChanges: true},
      snap => {
        const promiseArray = [];
        const profiles = [];

        snap.forEach(doc => {
          // this db call gets all locations from the current profiles subcollection and pushes them into an array within the profile
          promiseArray.push(
            materialProfilesRef
              .doc(doc.id)
              .collection('Profile-Locations')
              .get()
              .then(locationSnap => {
                const profileLocations = [];
                locationSnap.forEach(doc => {
                  profileLocations.push({...doc.data(), id: doc.id});
                });
                profiles.push({
                  ...doc.data(),
                  EffectiveDate: doc.data().EffectiveDate.toDate(),
                  Expiration: doc.data().Expiration.toDate(),
                  locations: profileLocations,
                  id: doc.id,
                });
              }),
          );
        });
        Promise.all(promiseArray).then(() => {
          dispatch({type: SET_ACCOUNT_PROFILES, payload: {profiles, listener}});
        });
      },
      error => { },
    );
  };
};
export const acClearAccountProfiles = profilesObjFromRedux => {
  return dispatch => {
    if (profilesObjFromRedux) {
      profilesObjFromRedux.listener();
    }
    dispatch({type: SET_ACCOUNT_PROFILES, payload: null});
  };
};
export const acGetShipments = () => {
  return dispatch => {
    const shipmentGroupRef = Firestore.collectionGroup('Shipments');
    const listener = shipmentGroupRef.onSnapshot(snapshot => {
      const allShipments = {};
      snapshot.forEach(doc => {
        const docData = doc.data();
        // the if/else statement loops over the shipment docs and created an object of all account id's as keys,
        // with their respective shipments in an array as its value
        if (allShipments[docData.AccountId]) {
          allShipments[docData.AccountId] = [
            ...allShipments[docData.AccountId],
            {
              ...docData,
              id: doc.id,
              StartDateTime: docData.StartDateTime.toDate(),
              EndDateTime: docData.EndDateTime.toDate(),
            },
          ];
        }
        else {
          allShipments[docData.AccountId] = [
            {
              ...docData,
              id: doc.id,
              StartDateTime: docData.StartDateTime.toDate(),
              EndDateTime: docData.EndDateTime.toDate(),
            },
          ];
        }
      });
      dispatch({type: GET_SHIPMENTS, payload: allShipments});
      dispatch({type: SET_SHIPMENT_LISTENER, payload: listener});
    });
    /** the layout of the object created and stored in redux
     * allShipments: {
     *  account1: [{...shipment1}, {...shipment2}],
     *  account2: [{...shipment1}, {...shipment2}]
     * }
     */
  };
};
export const acGetAccounts = () => {
  return dispatch => {
    const listener = Firestore.collection('CRM-Accounts').onSnapshot(
      snap => {
        let accounts = {};
        snap.forEach(doc => {
          accounts = {...accounts, [doc.id]: {...doc.data(), AccountId: doc.id}};
        });
        dispatch({type: SET_ACCOUNTS, payload: accounts});
        dispatch({type: SET_ACCOUNT_LISTENER, payload: listener});
      },
      error => { },
    );
  };
};
export const acGetLocations = () => dispatch => {
  const listener = Firestore.collectionGroup('Locations').onSnapshot(
    snap => {
      const locationsObj = {};
      snap.forEach(doc => {
        const location = doc.data();
        if (locationsObj[location.AccountRef]) {
          locationsObj[location.AccountRef].push({...location, id: doc.id});
        }
        else {
          locationsObj[location.AccountRef] = [{...location, id: doc.id}];
        }
      });
      dispatch({type: GET_LOCATIONS, payload: {listener, ...locationsObj}});
    },
    error => { },
  );
};
export const acGetAllPurchaseOrders = () => {
  return async dispatch => {
    const purchaseOrderRef = Firestore.collectionGroup('Purchase-Orders');
    const listener = purchaseOrderRef.onSnapshot(
      {includeMetadataChanges: true},
      snap => {
        const purchaseOrderObject = {};
        snap.forEach(purchaseOrderDoc => {
          const purchaseOrderData = purchaseOrderDoc.data();
          if (!purchaseOrderObject[purchaseOrderData.AccountId]) {
            purchaseOrderObject[purchaseOrderData.AccountId] = [
              {
                ...purchaseOrderData,
                EffectiveDate: purchaseOrderDoc
                  .data()
                  .EffectiveDate.toDate(),
                ExpirationDate: purchaseOrderDoc
                  .data()
                  .ExpirationDate.toDate(),
                id: purchaseOrderDoc.id,
              },
            ];
          }
          else {
            purchaseOrderObject[purchaseOrderData.AccountId] = [
              ...purchaseOrderObject[purchaseOrderData.AccountId],
              {
                ...purchaseOrderData,
                EffectiveDate: purchaseOrderDoc
                  .data()
                  .EffectiveDate.toDate(),
                ExpirationDate: purchaseOrderDoc
                  .data()
                  .ExpirationDate.toDate(),
                id: purchaseOrderDoc.id,
              },
            ];
          }
        });
        dispatch({type: SET_PURCHASE_ORDERS, payload: purchaseOrderObject});
        dispatch({type: SET_PURCHASE_ORDER_LISTENER, payload: listener});
      },
      error => { },
    );
  };
};
export const acGetMaterials = () => dispatch => {
  const materialListener = Firestore.collection('Tracking-Materials').onSnapshot(snapshot => {
    const materialList = [];
    const materialRef = {};
    const yieldList = [];
    const processList = [];
    const inboundList = [];
    const completeList = [];
    const kbiGeneratedList = [];
    snapshot.forEach(doc => {
      const docData = {MaterialId: doc.id, ...doc.data()};
      materialRef[doc.id] = docData;

      if (docData.Active) {
        completeList.push(docData);
        if (docData.UnitDetails.Yield) yieldList.push(docData);
        else materialList.push(docData);

        if (docData.UnitDetails.ProcessFormAllowed) processList.push(docData);
        if (docData.UnitDetails.InboundFormAllowed) inboundList.push(docData);
        if (docData.UnitDetails.KbiGenerated) kbiGeneratedList.push(docData);
      }
    });
    dispatch({
      type: SET_MATERIALS,
      payload: {ref: materialRef,
        listener: materialListener,
        materialList: materialList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1),
        yieldList: yieldList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1),
        processList: processList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1),
        inboundList: inboundList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1),
        completeList: completeList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1),
        kbiGeneratedList: kbiGeneratedList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1)},
    });
  });
};
export const acGetAllMaterialTypes = () => async dispatch => {
  const typesRef = Firestore.collectionGroup('Types');
  const typesListener = typesRef.onSnapshot({includeMetadataChanges: true}, snapshot => {
    const typesObj = {};
    snapshot.forEach(typedoc => {
      const typeData = typedoc.data();
      if (!typesObj[typeData.MaterialId]) {
        typesObj[typeData.MaterialId] = [{...typeData, id: typedoc.id}];
      }
      else {
        typesObj[typeData.MaterialId].push({...typeData, id: typedoc.id});
      }
    });
    dispatch({type: SET_ALL_MATERIAL_TYPES, payload: {...typesObj, listener: typesListener}});
  });
};
