export const industries = [
  'Automotive OEM',
  'Battery Management',
  'Battery Manufacturer',
  'Environmental Services',
  'E-waste Collector/Recycler',
  'Government/Military',
  'Oil, Gas & Mining',
  'Scrap Dealer/Broker',
  'Smelter/Refiner',
  'Transporter',
  'Utility',
  'Miscellaneous',
];
export const mexicoStates = [
  {value: 'Aguascalientes'},
  {value: 'Baja California'},
  {value: 'Baja California Sur'},
  {value: 'Chihuahua'},
  {value: 'Colima'},
  {value: 'Campeche'},
  {value: 'Coahuila'},
  {value: 'Chiapas'},
  {value: 'Federal District'},
  {value: 'Durango'},
  {value: 'Guerrero'},
  {value: 'Guanajuato'},
  {value: 'Hidalgo'},
  {value: 'Jalisco'},
  {value: 'México State'},
  {value: 'Michoacán'},
  {value: 'Morelos'},
  {value: 'Nayarit'},
  {value: 'Nuevo León'},
  {value: 'Oaxaca'},
  {value: 'Puebla'},
  {value: 'Querétaro'},
  {value: 'Quintana Roo'},
  {value: 'Sinaloa'},
  {value: 'San Luis Potosí'},
  {value: 'Sonora'},
  {value: 'Tabasco'},
  {value: 'Tlaxcala'},
  {value: 'Tamaulipas'},
  {value: 'Veracruz'},
  {value: 'Yucatán'},
  {value: 'Zacatecas'},
];
export const southKoreaProvinces = [
  'Chungcheong',
  'Gangwon',
  'Gyeonggi',
  'Gyeongsang',
  'Hamgyeong',
  'Hwanghae',
  'Jeolla',
  'Pyeongan',
];
export const canadaProvinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];
export const unitesStatesStates = [
  {value: 'AL'},
  {value: 'AK'},
  {value: 'AS'},
  {value: 'AZ'},
  {value: 'AR'},
  {value: 'CA'},
  {value: 'CO'},
  {value: 'CT'},
  {value: 'DE'},
  {value: 'DC'},
  {value: 'FM'},
  {value: 'FL'},
  {value: 'GA'},
  {value: 'GU'},
  {value: 'HI'},
  {value: 'ID'},
  {value: 'IL'},
  {value: 'IN'},
  {value: 'IA'},
  {value: 'KS'},
  {value: 'KY'},
  {value: 'LA'},
  {value: 'ME'},
  {value: 'MH'},
  {value: 'MD'},
  {value: 'MA'},
  {value: 'MI'},
  {value: 'MN'},
  {value: 'MS'},
  {value: 'MO'},
  {value: 'MT'},
  {value: 'NE'},
  {value: 'NV'},
  {value: 'NH'},
  {value: 'NJ'},
  {value: 'NM'},
  {value: 'NY'},
  {value: 'NC'},
  {value: 'ND'},
  {value: 'MP'},
  {value: 'OH'},
  {value: 'OK'},
  {value: 'OR'},
  {value: 'PW'},
  {value: 'PA'},
  {value: 'PR'},
  {value: 'RI'},
  {value: 'SC'},
  {value: 'SD'},
  {value: 'TN'},
  {value: 'TX'},
  {value: 'UT'},
  {value: 'VT'},
  {value: 'VI'},
  {value: 'VA'},
  {value: 'WA'},
  {value: 'WV'},
  {value: 'WI'},
  {value: 'WY'},
];
export const prefectures = [
  {value: 'Aichi'},
  {value: 'Akita'},
  {value: 'Aomori'},
  {value: 'Chiba'},
  {value: 'Ehime'},
  {value: 'Fukui'},
  {value: 'Fukuoka'},
  {value: 'Fukushima'},
  {value: 'Gifu'},
  {value: 'Gunma'},
  {value: 'Hiroshima'},
  {value: 'Hokkaidō'},
  {value: 'Hyōgo'},
  {value: 'Ibaraki'},
  {value: 'Ishikawa'},
  {value: 'Iwate'},
  {value: 'Kagawa'},
  {value: 'Kagoshima'},
  {value: 'Kanagawa'},
  {value: 'Kōchi'},
  {value: 'Kumamoto'},
  {value: 'Kyoto'},
  {value: 'Mie'},
  {value: 'Miyagi'},
  {value: 'Miyazaki'},
  {value: 'Nagano'},
  {value: 'Nagasaki'},
  {value: 'Nara'},
  {value: 'Niigata'},
  {value: 'Ōita'},
  {value: 'Okayama'},
  {value: 'Okinawa'},
  {value: 'Osaka'},
  {value: 'Saga'},
  {value: 'Saitama'},
  {value: 'Shiga'},
  {value: 'Shimane'},
  {value: 'Shizuoka'},
  {value: 'Tochigi'},
  {value: 'Tokushima'},
  {value: 'Tokyo'},
  {value: 'Tottori'},
  {value: 'Toyama'},
  {value: 'Wakayama'},
  {value: 'Yamagata'},
  {value: 'Yamaguchi'},
  {value: 'Yamanashi'},
];
export const countries = [
  {value: 'United States'},
  {value: 'Canada'},
  {value: 'Mexico'},
  {value: 'Japan'},
  {value: 'South Korea'},
  {value: 'Afghanistan'},
  {value: 'Albania'},
  {value: 'Algeria'},
  {value: 'Andorra'},
  {value: 'Angola'},
  {value: 'Anguilla'},
  {value: 'Antigua & Barbuda'},
  {value: 'Argentina'},
  {value: 'Armenia'},
  {value: 'Aruba'},
  {value: 'Australia'},
  {value: 'Austria'},
  {value: 'Azerbaijan'},
  {value: 'Bahamas'},
  {value: 'Bahrain'},
  {value: 'Bangladesh'},
  {value: 'Barbados'},
  {value: 'Belarus'},
  {value: 'Belgium'},
  {value: 'Belize'},
  {value: 'Benin'},
  {value: 'Bermuda'},
  {value: 'Bhutan'},
  {value: 'Bolivia'},
  {value: 'Bosnia & Herzegovina'},
  {value: 'Botswana'},
  {value: 'Brazil'},
  {value: 'British Virgin Islands'},
  {value: 'Brunei'},
  {value: 'Bulgaria'},
  {value: 'Burkina Faso'},
  {value: 'Burundi'},
  {value: 'Cambodia'},
  {value: 'Cameroon'},
  {value: 'Cape Verde'},
  {value: 'Cayman Islands'},
  {value: 'Chad'},
  {value: 'Chile'},
  {value: 'China'},
  {value: 'Colombia'},
  {value: 'Congo'},
  {value: 'Cook Islands'},
  {value: 'Costa Rica'},
  {value: 'Cote D Ivoire'},
  {value: 'Croatia'},
  {value: 'Cruise Ship'},
  {value: 'Cuba'},
  {value: 'Cyprus'},
  {value: 'Czech Republic'},
  {value: 'Denmark'},
  {value: 'Djibouti'},
  {value: 'Dominica'},
  {value: 'Dominican Republic'},
  {value: 'Ecuador'},
  {value: 'Egypt'},
  {value: 'El Salvador'},
  {value: 'Equatorial Guinea'},
  {value: 'Estonia'},
  {value: 'Ethiopia'},
  {value: 'Falkland Islands'},
  {value: 'Faroe Islands'},
  {value: 'Fiji'},
  {value: 'Finland'},
  {value: 'France'},
  {value: 'French Polynesia'},
  {value: 'French West Indies'},
  {value: 'Gabon'},
  {value: 'Gambia'},
  {value: 'Georgia'},
  {value: 'Germany'},
  {value: 'Ghana'},
  {value: 'Gibraltar'},
  {value: 'Greece'},
  {value: 'Greenland'},
  {value: 'Grenada'},
  {value: 'Guam'},
  {value: 'Guatemala'},
  {value: 'Guernsey'},
  {value: 'Guinea'},
  {value: 'Guinea Bissau'},
  {value: 'Guyana'},
  {value: 'Haiti'},
  {value: 'Honduras'},
  {value: 'Hong Kong'},
  {value: 'Hungary'},
  {value: 'Iceland'},
  {value: 'India'},
  {value: 'Indonesia'},
  {value: 'Iran'},
  {value: 'Iraq'},
  {value: 'Ireland'},
  {value: 'Isle of Man'},
  {value: 'Israel'},
  {value: 'Italy'},
  {value: 'Jamaica'},
  {value: 'Jersey'},
  {value: 'Jordan'},
  {value: 'Kazakhstan'},
  {value: 'Kenya'},
  {value: 'Kuwait'},
  {value: 'Kyrgyz Republic'},
  {value: 'Laos'},
  {value: 'Latvia'},
  {value: 'Lebanon'},
  {value: 'Lesotho'},
  {value: 'Liberia'},
  {value: 'Libya'},
  {value: 'Liechtenstein'},
  {value: 'Lithuania'},
  {value: 'Luxembourg'},
  {value: 'Macau'},
  {value: 'Macedonia'},
  {value: 'Madagascar'},
  {value: 'Malawi'},
  {value: 'Malaysia'},
  {value: 'Maldives'},
  {value: 'Mali'},
  {value: 'Malta'},
  {value: 'Mauritania'},
  {value: 'Mauritius'},
  {value: 'Moldova'},
  {value: 'Monaco'},
  {value: 'Mongolia'},
  {value: 'Montenegro'},
  {value: 'Montserrat'},
  {value: 'Morocco'},
  {value: 'Mozambique'},
  {value: 'Namibia'},
  {value: 'Nepal'},
  {value: 'Netherlands'},
  {value: 'Netherlands Antilles'},
  {value: 'New Caledonia'},
  {value: 'New Zealand'},
  {value: 'Nicaragua'},
  {value: 'Niger'},
  {value: 'Nigeria'},
  {value: 'Norway'},
  {value: 'Oman'},
  {value: 'Pakistan'},
  {value: 'Palestine'},
  {value: 'Panama'},
  {value: 'Papua New Guinea'},
  {value: 'Paraguay'},
  {value: 'Peru'},
  {value: 'Philippines'},
  {value: 'Poland'},
  {value: 'Portugal'},
  {value: 'Qatar'},
  {value: 'Reunion'},
  {value: 'Romania'},
  {value: 'Russia'},
  {value: 'Rwanda'},
  {value: 'Saint Pierre & Miquelon'},
  {value: 'San Marino'},
  {value: 'Saudi Arabia'},
  {value: 'Senegal'},
  {value: 'Serbia'},
  {value: 'Seychelles'},
  {value: 'Sierra Leone'},
  {value: 'Singapore'},
  {value: 'Slovakia'},
  {value: 'Slovenia'},
  {value: 'South Africa'},
  {value: 'Spain'},
  {value: 'Sri Lanka'},
  {value: 'St Kitts & Nevis'},
  {value: 'St Lucia'},
  {value: 'St Vincent'},
  {value: 'St. Lucia'},
  {value: 'Sudan'},
  {value: 'Surivalue'},
  {value: 'Swaziland'},
  {value: 'Sweden'},
  {value: 'Switzerland'},
  {value: 'Syria'},
  {value: 'Taiwan'},
  {value: 'Tajikistan'},
  {value: 'Tanzania'},
  {value: 'Thailand'},
  {value: 'Timor L\'Este'},
  {value: 'Togo'},
  {value: 'Tonga'},
  {value: 'Trinidad & Tobago'},
  {value: 'Tunisia'},
  {value: 'Turkey'},
  {value: 'Turkmenistan'},
  {value: 'Turks & Caicos'},
  {value: 'Uganda'},
  {value: 'Ukraine'},
  {value: 'United Arab Emirates'},
  {value: 'United Kingdom'},
  {value: 'Uruguay'},
  {value: 'Uzbekistan'},
  {value: 'Venezuela'},
  {value: 'Vietnam'},
  {value: 'Virgin Islands'},
  {value: 'Yemen'},
  {value: 'Zambia'},
  {value: 'Zimbabwe'},
];
