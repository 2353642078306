import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid, MenuItem} from '@material-ui/core';
import {SubmitConfirmation} from 'components/';
import * as yup from 'yup';
import {Formik, Collapse, Alert} from '@kbi/component-library';
import {Firestore, Functions} from 'config.js';
import {useSelector} from 'react-redux';
const {
  FormikForm, TextField, SubmitButton, SelectField, FormButton,
  AutoCompleteObject, validateAutoObject, PhoneNumberField, validatePhoneField,
} = Formik;
validateAutoObject();
validatePhoneField();


const ContactModal = ({selectedContact, close, selectedAccountId}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const {accounts} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);
  const handleBack = () => {
    setStage(stage - 1);
  };

  const formikProps = {
    initialValues: {
      firstName: selectedContact?.Name.First || '',
      middleInitial: selectedContact?.Name.Middle || '',
      lastName: selectedContact?.Name.Last || '',
      title: selectedContact?.Title || '',
      active: selectedContact ? selectedContact.Active : true,
      phoneOffice: selectedContact?.Phone.Office || '',
      phoneHome: selectedContact?.Phone.Home || '',
      phoneMobile: selectedContact?.Phone.Mobile || '',
      phoneFax: selectedContact?.Phone.Fax || '',
      emailWork: selectedContact?.Email.Work || '',
      emailHome: selectedContact?.Email.Home || '',
      account: selectedAccountId ? accounts[selectedAccountId] : selectedContact ? accounts[selectedContact.RefAccount.Id] : '',
    },
    validationSchema: (() => {
      if (stage === 0) {
        return yup.object().shape({
          firstName: yup.string().required('First Name is a required field.'),
          lastName: yup.string().required('Last Name is a required field.'),
          middleInitial: yup.string().max(1, 'MI can only be one character.'),
          account: yup.object().nullable().exists('Account is a required field.'),
        });
      }
      else if (stage === 1) {
        return yup.object().shape({
          phoneOffice: yup.string().validPhoneNumber('Office No. is an invalid number.'),
          phoneFax: yup.string().validPhoneNumber('Fax No. is an invalid number.'),
          phoneMobile: yup.string().validPhoneNumber('Mobile No. is an invalid number.'),
          phoneHome: yup.string().validPhoneNumber('Home No. is an invalid number.'),
        });
      }
      else if (stage === 2) {
        return yup.object().shape({
          emailWork: yup.string().email('Must be a valid email address.'),
          emailHome: yup.string().email('Must be a valid email address.'),
        });
      }
    })(),
    onSubmit: async (values, actions) => {
      if (stage !== 2) {
        setStage(stage + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
        return;
      }

      const contactData = createFirestoreData(values, currentUser, selectedContact);
      if (selectedContact) {
        const nylasData = createNylasData(values);

        try {
          await Firestore.collection('CRM-Contacts')
            .doc(selectedContact.ContactId)
            .update(contactData);
          await Functions.httpsCallable('nylasContactUpdate')({
            nylasLinks: selectedContact.NylasLinks,
            changesObject: nylasData,
          });
          actions.setSubmitting(false);
          setStage(stage + 1);
          setFormError('');
        }
        catch (error) {
          actions.setSubmitting(false);
          setFormError('An error occured while during submission. Please try again.');
        }
      }
      else {
        await Firestore.collection('CRM-Contacts')
          .add(contactData)
          .then(doc => {
            actions.setSubmitting(false);
            setStage(stage + 1);
            setFormError('');
          })
          .catch(error => {
            actions.setSubmitting(false);
            setFormError('An error occured while during submission. Please try again.');
          });
      }
    },
  };


  const core = {
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      fullWidth: true,
    },
    submitConfirmation: {
      text: `Contact successfully ${selectedContact ? 'updated' : 'created'}.`,
      stage: stage === 3 ? 'success' : 'not success',
    },
    accountField: {
      name: 'account',
      label: 'Account',
      required: true,
      disabled: Boolean(selectedAccountId),
      options: Object.values(accounts),
      optionKey: 'AccountName',
    },
  };

  return <Dialog {...core.dialog}>
    <FormikForm {...formikProps}>
      {({values}) => (
        <Fragment>
          {stage !== 3 && <DialogTitle>{selectedContact ? 'Edit' : 'Add New'} Contact</DialogTitle>}
          <DialogContent>
            <Collapse in={stage === 0}>
              <DialogContentText>Enter the contact&apos;s name and relevant account:</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField name='firstName' label='First Name' required />
                </Grid>
                <Grid item xs={2}>
                  <TextField name='middleInitial' label='MI' />
                </Grid>
                <Grid item xs={5}>
                  <TextField name='lastName' label='Last Name' required />
                </Grid>
                <Grid item xs={12}>
                  <AutoCompleteObject {...core.accountField} />
                </Grid>
                <Grid item xs={selectedContact ? 9 : 12}>
                  <TextField name='title' label='Title' />
                </Grid>
                {selectedContact && (
                  <Grid item xs={3}>
                    <SelectField name='active' label='Active' required>
                      <MenuItem key={true} value={true}>
                        True
                      </MenuItem>
                      <MenuItem key={false} value={false}>
                        False
                      </MenuItem>
                    </SelectField>
                  </Grid>)}
              </Grid>
            </Collapse>
            <Collapse in={stage === 1}>
              <DialogContentText>Enter contact&apos;s phone numbers:</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PhoneNumberField name='phoneOffice' label='Office No.' />
                </Grid>
                <Grid item xs={6}>
                  <PhoneNumberField name='phoneHome' label='Home No.' />
                </Grid>
                <Grid item xs={6}>
                  <PhoneNumberField name='phoneMoblile' label='Mobile No.' />
                </Grid>
                <Grid item xs={6}>
                  <PhoneNumberField name='phoneFax' label='Fax No.' />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={stage === 2}>
              <DialogContentText>Enter the contact&apos;s email address:</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField name='emailWork' label='Work Email' />
                </Grid>
                <Grid item xs={6}>
                  <TextField name='emailHome' label='Home Email' />
                </Grid>
              </Grid>
            </Collapse>
            <SubmitConfirmation {...core.submitConfirmation} />
            <Alert in={Boolean(formError)} text={formError} />
          </DialogContent>
          {stage === 3 ? (
            <DialogActions>
              <Button variant='text' onClick={close} color='primary'>Close</Button>
            </DialogActions>
          ) : (
            <DialogActions style={{justifyContent: 'space-between'}}>
              <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
              <div>
                <FormButton variant='text' color='primary' disabled={stage === 0} onClick={handleBack}>Back</FormButton>
                <SubmitButton variant='text' color='primary'>{stage === 2 ? 'Submit' : 'Next'}</SubmitButton>
              </div>
            </DialogActions>
          )}
        </Fragment>
      )}
    </FormikForm>
  </Dialog>;
};

function createNylasData(values) {
  const {
    firstName,
    middleInitial,
    lastName,
    account,
    phoneOffice,
    phoneHome,
    phoneMobile,
    phoneFax,
    emailWork,
    emailHome,
  } = values;

  const nylasContactData = {
    given_name: firstName,
    surname: lastName,
    middle_name: middleInitial,
    company_name: account.AccountName,
    phone_numbers: [],
    emails: [],
  };
  emailWork && nylasContactData.emails.push({type: 'work', email: emailWork});
  emailHome && nylasContactData.emails.push({type: 'personal', email: emailHome});
  phoneOffice && nylasContactData.phone_numbers.push({type: 'business', number: phoneOffice});
  phoneHome && nylasContactData.phone_numbers.push({type: 'home', number: phoneHome});
  phoneMobile && nylasContactData.phone_numbers.push({type: 'mobile', number: phoneMobile});
  phoneFax && nylasContactData.phone_numbers.push({type: 'business_fax', number: phoneFax});
  return nylasContactData;
}
function createFirestoreData(values, currentUser, selectedContact) {
  const {
    firstName,
    middleInitial,
    lastName,
    account,
    phoneOffice,
    phoneHome,
    phoneMobile,
    phoneFax,
    emailWork,
    emailHome,
    active,
    title,
  } = values;

  let submitData;
  if (selectedContact) {
    submitData = {
      'Active': active,
      'Title': title,
      'Name': {
        First: firstName,
        Middle: middleInitial,
        Last: lastName,
        Full: `${firstName}${middleInitial ? ` ${middleInitial}` : ''} ${lastName}`,
      },
      'RefAccount': {
        Id: account.AccountId,
        Name: account.AccountName,
      },
      'Phone': {
        Office: phoneOffice,
        Home: phoneHome,
        Mobile: phoneMobile,
        Fax: phoneFax,
      },
      'Email': {
        Home: emailHome,
        Work: emailWork,
      },
      'System.ModifiedBy': currentUser.displayName,
      'System.ModifiedOn': new Date(),
    };
  }
  else {
    submitData = {
      Active: active,
      Title: title,
      Name: {
        First: firstName,
        Middle: middleInitial,
        Last: lastName,
        Full: `${firstName}${middleInitial ? ` ${middleInitial}` : ''} ${lastName}`,
      },
      RefAccount: {
        Id: account.AccountId,
        Name: account.AccountName,
      },
      Phone: {
        Office: phoneOffice,
        Home: phoneHome,
        Mobile: phoneMobile,
        Fax: phoneFax,
      },
      Email: {
        Home: emailHome,
        Work: emailWork,
      },
      System: {
        CreatedBy: currentUser.displayName,
        CreatedOn: new Date(),
        ModifiedBy: currentUser.displayName,
        ModifiedOn: new Date(),
      },
      NylasLinks: {},
      NylasContactIds: [],
    };
  }
  return submitData;
}

ContactModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedContact: PropTypes.object,
  selectedAccountId: PropTypes.string,
};

export default ContactModal;
