import {
  SET_LEADS,
  SET_CONTACTS,
  GET_OUTLOOK_USERS,
  SET_ACCOUNT_PROFILES,
  GET_SHIPMENTS,
  SET_SHIPMENT_LISTENER,
  SET_ACCOUNTS,
  SET_ACCOUNT_LISTENER,
  GET_LOCATIONS,
  SET_PURCHASE_ORDERS,
  SET_PURCHASE_ORDER_LISTENER,
  SET_MATERIALS,
  SET_ALL_MATERIAL_TYPES,
  SET_USERS,
} from './types';

const INITIAL_STATE = {
  leads: null,
  accountProfiles: null,
  eventsAndTasks: null,
  shipments: null,
  outlookUsers: null,
  accounts: null,
  locations: null,
  online: true,
  purchaseOrders: null,
  accountListener: null,
  purchaseOrderListener: null,
  shipmentListener: null,
  types: null,
  materials: null,
  users: null,
  contacts: null,
};

const firestoreReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_CONTACTS:
    return {...state, contacts: payload};
  case SET_LEADS:
    return {...state, leads: payload};
  case GET_OUTLOOK_USERS:
    return {...state, outlookUsers: payload};
  case SET_ACCOUNT_PROFILES:
    return {...state, accountProfiles: payload};
  case SET_USERS:
    return {...state, users: payload};
  case SET_MATERIALS:
    return {...state, materials: {...payload}};
  case SET_ALL_MATERIAL_TYPES:
    return {...state, types: payload};
  case GET_SHIPMENTS:
    return {...state, shipments: payload};
  case SET_SHIPMENT_LISTENER:
    return {...state, shipmentListener: payload};
  case SET_ACCOUNTS:
    return {...state, accounts: payload};
  case SET_ACCOUNT_LISTENER:
    return {...state, accountListener: payload};
  case GET_LOCATIONS:
    return {...state, locations: payload};
  case SET_PURCHASE_ORDERS:
    return {...state, purchaseOrders: payload};
  case SET_PURCHASE_ORDER_LISTENER:
    return {...state, purchaseOrderListener: payload};
  default:
    return state;
  }
};

export default firestoreReducer;
