/* eslint-disable max-len */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import KbiLogo from 'media/KBI-logo(small).png';
import {TextField, Grid, InputAdornment} from '@material-ui/core';
import moment from 'moment';

const PdfFirstPage = props => {
  const {selectedProfile, classes, StyledTypography} = props;
  const companyCertificationText =
    'Kinsbursky Bros. Supply, Inc. d/b/a KBI certifies that: (i) it will accept all approved materials for recycling, reclamation, disposal, reutilization, and/or any other proper management technique; (ii) it possesses the necessary permits, certifications, and approvals to manage accepted materials in conformance with all federal, state and local law; and (iii) it shall comply with all federal, state, provincial and local laws, ordinances and/or regulations applicable to the management of accepted materials.';
  const customerGeneratorCertificationText =
    'Customer hereby certifies as the Generator of waste materials, or as the authorized representative of a Generator: (i) the information submitted in this Material Profile is true and accurate; (ii) only materials approved by KBI shall be delivered under this Material Profile; and (iii) any and all relevant information related to materials to be delivered under this Material Profile, either known or suspected, including any risks or potential hazards, has been disclosed within this Material Profile or to appropriate KBI personnel. In addition, upon Customer’s written or verbal consent, KBI is authorized to include additional Generators under this Material Profile.';

  const core = {
    textFields: (fieldValue, adornmentValue) => {
      return {
        value: fieldValue,
        autoComplete: 'off',
        fullWidth: true,
        InputProps: {
          readOnly: true,
          classes: {input: classes.smallInputFont},
          startAdornment: (
            <InputAdornment className={classes.smallInputFont} style={{whiteSpace: 'nowrap'}} position='start'>
              {adornmentValue}
            </InputAdornment>
          ),
        },
      };
    },
    newSection: {
      container: true,
      spacing: 1,
      className: classes.outlineForm,
    },
    sectionHeader: {
      container: true,
      item: true,
      xs: 12,
      className: classes.titleSection,
    },
  };
  return (
    <Fragment>
      <Grid container justify='space-between' className={`firstPage ${classes.newSection}`}>
        <Grid item xs={9}>
          <img alt='KBI logo' src={KbiLogo} style={{height: '80px'}} />
        </Grid>
        <Grid item xs={3} md={2}
          container justify='flex-start' direction='column'
          alignItems='flex-end'
        >
          <StyledTypography variant='body2' className={classes.bold}>
            Material Profile
          </StyledTypography>
          <StyledTypography variant='body2'>125 E Commercial St. A</StyledTypography>
          <StyledTypography variant='body2'>Anaheim, CA 92801</StyledTypography>
          <StyledTypography variant='body2'>www.kbirecycling.com</StyledTypography>
        </Grid>
      </Grid>
      <Grid {...core.newSection}>
        <Grid {...core.sectionHeader}>
          <StyledTypography variant='subtitle2' className={classes.titleText}>
            ACCOUNT INFORMATION
          </StyledTypography>
        </Grid>
        <Grid item xs={6}>
          <TextField {...core.textFields(selectedProfile.Account.Name, 'Account Name')} />
          <TextField {...core.textFields(selectedProfile.Account.Address.Line1, 'Address Line 1')} />
          {selectedProfile.Account.Address.Line2 && (
            <TextField {...core.textFields(selectedProfile.Account.Address.Line2, 'Address Line 2')} />
          )}
          {selectedProfile.Account.Address.City && (
            <TextField {...core.textFields(selectedProfile.Account.Address.City, 'City')} />
          )}
          {selectedProfile.Account.Address.State && (
            <TextField {...core.textFields(selectedProfile.Account.Address.State, 'State')} />
          )}
          {selectedProfile.Account.Address.PostalCode && (
            <TextField {...core.textFields(selectedProfile.Account.Address.PostalCode, 'Postal Code')} />
          )}
          {selectedProfile.Account.Address.Prefecture && (
            <TextField {...core.textFields(selectedProfile.Account.Address.Prefecture, 'Prefecture')} />
          )}
          {selectedProfile.Account.Address.Province && (
            <TextField {...core.textFields(selectedProfile.Account.Address.Province, 'Province')} />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField {...core.textFields(selectedProfile.Account.Address.Country, 'Country')} />
          <TextField {...core.textFields(selectedProfile.Contact.Name, 'POC Name')} />
          <TextField {...core.textFields(selectedProfile.Contact.Email, 'Email')} />
          <TextField {...core.textFields(selectedProfile.Contact.Phone, 'Phone')} />
          {selectedProfile.Account.Fax && <TextField {...core.textFields(selectedProfile.Account.Fax, 'Fax')} />}
        </Grid>
      </Grid>
      <Grid {...core.newSection}>
        <Grid {...core.sectionHeader}>
          <StyledTypography variant='subtitle2' className={classes.titleText}>
            COMPANY CERTIFICATION
          </StyledTypography>
        </Grid>
        <StyledTypography paragraph className={classes.textBox} variant='body2'>
          {companyCertificationText}
        </StyledTypography>
      </Grid>
      <Grid {...core.newSection}>
        <Grid {...core.sectionHeader}>
          <StyledTypography variant='subtitle2' className={classes.titleText}>
            CUSTOMER/GENERATOR CERTIFICATION
          </StyledTypography>
        </Grid>
        <StyledTypography className={classes.textBox} variant='body2'>
          {customerGeneratorCertificationText}
        </StyledTypography>
        <Grid item xs={6}>
          <TextField {...core.textFields(' ', 'Signature')} />
          <TextField {...core.textFields(moment().format('MM/DD/YYYY'), 'Date')} />
        </Grid>
        <Grid item xs={6}>
          <TextField {...core.textFields(' ', 'Print Name')} />
          <TextField {...core.textFields(' ', 'Title')} />
        </Grid>
      </Grid>
      <Grid {...core.newSection}>
        <Grid {...core.sectionHeader}>
          <StyledTypography variant='subtitle2' className={classes.titleText}>
            OTHER DOCUMENTATION
          </StyledTypography>
        </Grid>
        <ol className={classes.removeBottomMargin}>
          <li>
            <StyledTypography variant='body2'>
              <span className={classes.bold}>Exhibit A: Approved materials and Packaging Requirements </span>
              -Exhibit A describes all the batteries that are
              approved for delivery to KBI. In addition, it provides helpful references to applicable Department of
              Transportation shipping regulations.
            </StyledTypography>
          </li>
          <li>
            <StyledTypography paragraph variant='body2'>
              <span className={classes.bold}>Exhibit B: Generator List </span>
              -A detailed list of all physical locations that will be generating and sending batteries to KBI. This Exhibit B can be updated with additional locations at any time upon Customer&apos;s written consent.
            </StyledTypography>
          </li>
        </ol>
      </Grid>
      <Grid {...core.newSection}>
        <Grid {...core.sectionHeader}>
          <StyledTypography variant='subtitle2' className={classes.titleText}>
            METHODS OF RETURN
          </StyledTypography>
        </Grid>
        <Grid item xs={12} style={{padding: 0}}>
          <StyledTypography variant='body2' className={classes.textBox}>
            Completed material profiles can be returned to KBI as follows:
          </StyledTypography>
        </Grid>
        <Grid item xs={12} style={{padding: 0}}>
          <ul className={`${classes.removeBottomMargin} ${classes.removeTopMargin}`}>
            <li>
              <StyledTypography variant='body2'>
                <span className={classes.bold}>Fax: </span>714-773-4830; Attn: Client Support
              </StyledTypography>
            </li>
            <li>
              <StyledTypography paragraph variant='body2'>
                <span className={classes.bold}>Email: </span>clientsupport@kbirecycling.com
              </StyledTypography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Fragment>
  );
};

PdfFirstPage.propTypes = {
  selectedProfile: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  StyledTypography: PropTypes.object.isRequired,
};

export default PdfFirstPage;
