import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {Accordion, AccordionSummary, AccordionDetails, Grid, Button, LinearProgress} from '@material-ui/core';
import {PurchaseOrderModal} from './PurchaseOrders/';
import {Table} from '@kbi/component-library';
import {Search, ExpandMore, FileCopy} from '@material-ui/icons';

const PurchaseOrders = props => {
  const {classes, contacts, activeMaterialProfile, selectedAccount, purchaseOrders} = props;
  const [purchaseOrderModalOpen, setPurchaseOrderModalOpen] = useState(false);
  const [duplicatePO, setDuplicatePO] = useState(false);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const [activeMaterialProfileBool, setActiveMaterialProfileBool] = useState(false); // will be false if there is no active material profile
  useEffect(() => {
    if (activeMaterialProfile && Object.keys(activeMaterialProfile).length > 0) {
      setActiveMaterialProfileBool(true);
    }
  }, [activeMaterialProfile]);
  function details() {
    if (!purchaseOrders) {
      return <LinearProgress />;
    }
    else if (purchaseOrders.length === 0) {
      return (
        <AccordionDetails>
          <Grid container justify='space-between'>
            <Typography variant='subtitle1'>No purchase orders found for this account.</Typography>
          </Grid>
        </AccordionDetails>
      );
    }
    else {
      return (
        <AccordionDetails style={{padding: '0px'}}>
          <Grid container>
            <Grid item xs={12}>
              <Table {...core.purchaseOrderTable} />
            </Grid>
          </Grid>
        </AccordionDetails>
      );
    }
  }
  const core = {
    addPurchaseOrderButton: {
      variant: 'outlined',
      color: activeMaterialProfileBool ? 'default' : 'secondary',
      onClick: (e) => {
        e.stopPropagation();
        if (activeMaterialProfileBool) setPurchaseOrderModalOpen(true);
      },
    },
    purchaseOrderModal: {
      modalOpen: purchaseOrderModalOpen,
      activeMaterialProfile,
      contacts,
      selectedAccount,
      duplicatePO,
      selectedPurchaseOrder,
      close: () => {
        setPurchaseOrderModalOpen(false);
        setDuplicatePO(false);
        setSelectedPurchaseOrder(null);
      },
    },
    purchaseOrderTable: {
      columns: [
        {accessor: 'Active', Header: 'Active', type: 'boolean'},
        {accessor: 'OrderNumber', Header: 'PO Number'},
        {accessor: 'EffectiveDate', Header: 'Effective Date', type: 'datetime'},
        {accessor: 'ExpirationDate', Header: 'Expiration Date', type: 'datetime'},
        {accessor: 'FOB', Header: 'FOB'},
        {accessor: 'POType', Header: 'Type'},
        {accessor: 'BillingLocation.Address.SingleLine', Header: 'Billing Address'},
      ],
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Purchase Order',
          onClick: ({rowData}) => props.history.push(`/accounts/${selectedAccount.AccountId}/purchase-order/${rowData.id}`),
        },
        {
          icon: FileCopy,
          tooltip: 'Duplicate PO',
          onClick: ({rowData}) => {
            if (activeMaterialProfileBool) {
              setSelectedPurchaseOrder(rowData);
              setPurchaseOrderModalOpen(true);
              setDuplicatePO(true);
            }
          },
        },
      ],
      data: purchaseOrders,
      sortInitial: [{id: 'EffectiveDate', desc: true}],
    },
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container justify='space-between'>
          <div>
            <Typography className={classes.heading}>Purchase Orders</Typography>
          </div>
          {expanded ? (
            <div>
              <Button {...core.addPurchaseOrderButton}>
                {activeMaterialProfileBool ? 'Add Purchase Order' : 'No active material profile'}
              </Button>
            </div>
          ) : null}
        </Grid>
      </AccordionSummary>
      {details()}
      {purchaseOrderModalOpen && <PurchaseOrderModal {...core.purchaseOrderModal} />}
    </Accordion>
  );
};

PurchaseOrders.propTypes = {
  selectedAccount: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  activeMaterialProfile: PropTypes.object,
  purchaseOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object),
};

export default PurchaseOrders;
