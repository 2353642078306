import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import {connect} from 'react-redux';
import {Table} from '@kbi/component-library';
import {ExpandMore} from '@material-ui/icons';
import {EventDisplayModal, NewEventModal} from './EventsTasks/';
import {Search} from '@material-ui/icons';
import moment from 'moment';

class EventsTasksPanel extends Component {
  state = {
    viewEventModalOpen: false,
    newEventModalOpen: false,
    expanded: false,
    selectedEvent: null,
  };

  getColumnData(events) {
    const data = [];
    events.forEach(event =>
      data.push({
        subject: event.title,
        location: event.location,
        start: moment.unix(event.when.start_time).format('lll'),
        startDate: new Date(event.when.start_time),
        end: moment.unix(event.when.end_time).format('lll'),
        endDate: new Date(event.when.start_time),
        body: {
          content: event.description,
        },
        attendees: event.participants,
      }),
    );
    return data;
  }

  render() {
    const {classes, contact, listOfContacts, currentUser, loadingEvents, events} = this.props;
    const {viewEventModalOpen, newEventModalOpen, selectedEvent, expanded} = this.state;
    const core = {
      eventsTable: {
        columns: [
          {accessor: 'subject', Header: 'Subject'},
          {accessor: 'location', Header: 'Location'},
          {accessor: 'startDate', Header: 'Start Time', type: 'datetime'},
          {accessor: 'endDate', Header: 'End Time', type: 'datetime'},
        ],
        sortInitial: [{id: 'start', desc: true}],
        data: events ? this.getColumnData(events) : [],
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'See Details',
            onClick: ( {rowData}) => {
              this.setState({selectedEvent: rowData, viewEventModalOpen: true});
            },
          },
        ],
      },
      newEventModal: {
        modalOpen: newEventModalOpen,
        close: () => {
          this.setState({newEventModalOpen: false});
        },
        accountContacts: listOfContacts || [],
        contact,
        currentUser,
      },
      eventDisplay: {
        modalOpen: viewEventModalOpen,
        close: () => this.setState({viewEventModalOpen: false}),
        event: selectedEvent,
      },
      emptyEventsButton: {
        onClick: e => {
          e.stopPropagation();
          this.setState({newEventModalOpen: true});
        },
        variant: 'outlined',
      },
    };
    const details = () => {
      if (loadingEvents) {
        return <LinearProgress />;
      }
      else if (events.length === 0) {
        return (
          <AccordionDetails>
            <Grid container justify='space-between'>
              <Typography variant='subtitle1'>No events found for this {contact ? 'contact' : 'account'}.</Typography>
            </Grid>
          </AccordionDetails>
        );
      }
      else {
        return (
          <AccordionDetails style={{padding: '0px'}}>
            <Grid container>
              <Grid item xs={12}>
                <Table {...core.eventsTable} />
              </Grid>
            </Grid>
          </AccordionDetails>
        );
      }
    };
    return (
      <Accordion expanded={expanded} onChange={() => this.setState({expanded: !expanded})}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container justify='space-between'>
            <div>
              <Typography className={classes.heading}>Events & Tasks</Typography>
            </div>
            {expanded ? (
              <div>
                <Button {...core.emptyEventsButton}>Add Event</Button>
              </div>
            ) : null}
          </Grid>
        </AccordionSummary>
        {details()}
        {this.state.loadingEvents && <LinearProgress />}
        {viewEventModalOpen ? <EventDisplayModal {...core.eventDisplay} /> : null}
        {newEventModalOpen ? <NewEventModal {...core.newEventModal} /> : null}
      </Accordion>
    );
  }
}

EventsTasksPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  handleNewEvent: PropTypes.func,
  listOfContacts: PropTypes.arrayOf(PropTypes.object),
  contact: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  eventsAndTasks: PropTypes.object,
  loadingEvents: PropTypes.bool,
  events: PropTypes.array,
};

const mapStateToProps = state => {
  const {eventsAndTasks} = state.firestore;
  const {currentUser} = state.auth;
  return {eventsAndTasks, currentUser};
};

export default connect(mapStateToProps)(EventsTasksPanel);
