import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import {ViewFileDetailsModal} from './FilePanel/';
import {ExpandMore, CloudDownload, Search} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import {AddFileModal} from 'components/';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';

class FilePanel extends Component {
  state = {
    files: null,
    selectedFile: null,
    viewDetailsModalOpen: false,
    addFileModalOpen: false,
    expanded: false,
  };
  componentDidMount() {
    const listener = Firestore.collection('CRM-Accounts')
      .doc(this.props.currentAccount.AccountId)
      .collection('Files')
      .onSnapshot(
        {includeMetadataChanges: true},
        snap => {
          const files = [];
          if (!snap.empty) {
            snap.forEach(doc => {
              if (!doc.metadata.hasPendingWrites) {
                files.push({...doc.data(), Date: doc.data().Date.toDate(), id: doc.id});
              }
            });
          }
          this.setState({files});
        },
        error => { },
      );
    this.setState({listener});
  }
  componentWillUnmount() {
    this.state.listener();
  }
  openFile = row => {
    const fileRef = firebase.storage().ref(`Account_Files/${row.id}.${row.FileExt}`);
    fileRef.getDownloadURL().then(url => {
      window.open(url, '__blank');
    });
  };
  viewFileDetails(row) {
    this.setState({
      selectedFile: row,
      viewDetailsModalOpen: true,
    });
  };
  render() {
    const {classes, currentAccount} = this.props;
    const {files, addFileModalOpen, viewDetailsModalOpen, selectedFile, expanded} = this.state;
    const core = {
      fileTable: {
        trackTableSize: this.trackTableDataLength,
        columns: [
          {accessor: 'FileName', Header: 'File Name'},
          {accessor: 'Owner.Name', Header: 'By'},
          {accessor: 'Date', Header: 'Upload Date', type: 'datetime'},
        ],
        data: this.state.files,
        sortInitial: [{id: 'FileName', desc: false}],
        actionsPerRow: [
          {
            icon: CloudDownload,
            tooltip: 'Open file in a new tab',
            onClick: ({rowData}) => this.openFile(rowData),
          },
          {
            icon: Search,
            tooltip: 'View file details',
            onClick: ( {rowData}) => this.viewFileDetails(rowData),
          },
        ],
        options: {
          showTitle: false,
        },
      },
      addFileModal: {
        addFileModalOpen,
        currentAccount,
        close: () => this.setState({addFileModalOpen: false}),
      },
      viewDetailsModal: {
        viewDetailsModalOpen,
        selectedFile,
        currentAccountId: currentAccount.AccountId,
        close: () => this.setState({viewDetailsModalOpen: false}),
      },
      emptyFilesButton: {
        onClick: e => {
          e.stopPropagation();
          this.setState({addFileModalOpen: true});
        },
        variant: 'outlined',
      },
    };
    const details = () => {
      if (!files) {
        return <LinearProgress />;
      }
      else if (files.length === 0) {
        return (
          <AccordionDetails>
            <Grid container justify='space-between'>
              <Typography variant='subtitle1'>No documents found for this account.</Typography>
            </Grid>
          </AccordionDetails>
        );
      }
      else {
        return (
          <AccordionDetails style={{padding: '0px'}}>
            <Grid container>
              <Grid item xs={12}>
                <Table {...core.fileTable} />
              </Grid>
            </Grid>
          </AccordionDetails>
        );
      }
    };
    return (
      <Accordion expanded={expanded} onChange={() => this.setState({expanded: !expanded})}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container justify='space-between'>
            <div>
              <Typography className={classes.heading}>Documents</Typography>
            </div>
            {expanded ? (
              <div>
                <Button {...core.emptyFilesButton}>Add File</Button>
              </div>
            ) : null}
          </Grid>
        </AccordionSummary>
        {details()}
        {addFileModalOpen ? <AddFileModal {...core.addFileModal} /> : null}
        {viewDetailsModalOpen ? <ViewFileDetailsModal {...core.viewDetailsModal} /> : null}
      </Accordion>
    );
  }
}

FilePanel.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  classes: PropTypes.object,
};
export default FilePanel;
