import React from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionSummary, AccordionDetails, Typography} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';

const styles = theme => ({
  content: {
    width: '100%',
  },
});

function EmailCollapse(props) {
  const core = {
    iframe: {
      sandbox: '',
      style: {width: '100%', height: 500, borderWidth: 0},
      srcDoc: props.email.body,
    },
  };
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} classes={{content: props.classes.content}}>
        <Typography noWrap={true}>
          {'Received: '}
          {moment.unix(props.email.date).format('YYYY-MM-DD')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <iframe title='Email' {...core.iframe} />
      </AccordionDetails>
    </Accordion>
  );
}

EmailCollapse.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmailCollapse);
