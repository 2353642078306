/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {AddFileModal, FileViewModal, BackButton} from 'components/';
import {
  InformationSection,
  ApprovedTypesTable,
  SupplementalPricingTable,
  TermsAndConditions,
} from './PurchaseOrderToPdf/';
import {withRouter} from 'react-router-dom';
import {PurchaseOrderModal} from '../panels/PurchaseOrders/';
import {Button, withStyles, Typography, LinearProgress} from '@material-ui/core';
import flowright from 'lodash.flowright';
import {connect} from 'react-redux';
import {Edit, Print, SwapVerticalCircle} from '@material-ui/icons';
import {acClearAccountProfiles, acGetAccountProfiles} from '../state/firestore/actions.js';
import {Firestore} from 'config.js';

const PurchaseOrderToPdf = props => {
  const {classes, contacts, match, accountProfiles, purchaseOrders, accounts, materials, currentUser} = props;
  const {acGetAccountProfiles, acClearAccountProfiles} = props;
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountContacts, setAccountContacts] = useState(null);
  const [editPurchaseOrderModalOpen, setEditPurchaseOrderModalOpen] = useState(false);
  const [viewFileModalOpen, setViewFileModalOpen] = useState(false);
  const [addFileModalOpen, setAddFileModalOpen] = useState(false);
  const [currentActiveProfile, setCurrentActiveProfile] = useState(null);
  const [matchesMaterialProfileData, setMatchesMaterialProfileData] = useState(null);
  const [selectedPurchaseOrderFiles, setSelectedPurchaseOrderFiles] = useState([]);

  useEffect(() => {
    // this useEffect is both the componentDidMount and willUnmount for the redux actions
    acGetAccountProfiles(match.params.AccountId);
    return () => {
      acClearAccountProfiles(accountProfiles);
    };
  }, []);
  useEffect(() => {
    // this is the componentDidUpdate, handling any changes in the props, and adjusting the local state based on what account/purchase order the user is on
    if (accounts) {
      if (!accounts[match.params.AccountId]) {
        props.history.push('/accounts');
        return;
      }
      setSelectedAccount(accounts[match.params.AccountId]);
    }
    if (purchaseOrders) {
      const selectedPurchaseOrderRef = purchaseOrders[match.params.AccountId].find(
        PO => PO.id === match.params.PurchaseOrderId,
      );
      if (!selectedPurchaseOrderRef) {
        props.history.push(`/accounts/${match.params.AccountId}`);
        return;
      }
      setSelectedPurchaseOrder(selectedPurchaseOrderRef);
    }
    if (accountProfiles) {
      setCurrentActiveProfile(accountProfiles.profiles.find(profile => profile.Active));
    }
    if (contacts && selectedAccount) {
      setAccountContacts(contacts.active.filter(contact => contact.RefAccount.Id === selectedAccount.AccountId));
    }
    if (currentActiveProfile && selectedPurchaseOrder) {
      setMatchesMaterialProfileData(checkValidMaterials());
    }
  }, [accountProfiles, accounts, match.params, contacts, purchaseOrders]);
  useEffect(() => {
    if (selectedPurchaseOrder && selectedAccount) {
      getUploadedFiles();
    }
  }, [selectedPurchaseOrder, selectedAccount]);

  function checkValidMaterials() {
    // looks through all the approved types on the PO, and compares with the material profile confirming that the material catagory is on the approved profile
    let allMaterialsMatch = true;
    // eslint-disable-next-line guard-for-in
    for (const typeId in selectedPurchaseOrder.ApprovedTypes) {
      let currentMaterialMatch = false;
      currentActiveProfile.Materials.forEach(material => {
        if (material.Name === selectedPurchaseOrder.ApprovedTypes[typeId].Material) currentMaterialMatch = true;
      });
      if (!currentMaterialMatch) allMaterialsMatch = false;
    }
    return allMaterialsMatch;
  }
  function getUploadedFiles() {
    // gets all the files that have been uploaded to the current purchase order and puts them into an array to be passed into the table
    Firestore.collection('CRM-Accounts')
      .doc(selectedAccount.AccountId)
      .collection('Purchase-Orders')
      .doc(selectedPurchaseOrder.id)
      .collection('Files')
      .onSnapshot(fileQuery => {
        if (fileQuery.empty) {
          setSelectedPurchaseOrderFiles([]);
          return;
        }
        const arrayOfFiles = [];
        fileQuery.forEach(fileDoc => {
          arrayOfFiles.push({
            ...fileDoc.data(),
            id: fileDoc.id,
            Date: fileDoc.data().Date.toDate(),
          });
        });
        setSelectedPurchaseOrderFiles(arrayOfFiles);
      });
  }
  const core = {
    editButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      className: 'hidePrint',
      disabled: currentActiveProfile === undefined,
      onClick: () => {
        setEditPurchaseOrderModalOpen(true);
      },
    },
    viewButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      className: 'hidePrint',
      onClick: () => {
        setViewFileModalOpen(true);
      },
    },
    printButton: {
      variant: 'outlined',
      className: 'hidePrint',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: () => window.print(),
    },
    fileModalProps: {
      selectedPurchaseOrder,
      currentUser,
      currentAccount: selectedAccount,
      addFileModalOpen,
      close: () => {
        setAddFileModalOpen(false);
      },
    },
    editPurchaseOrderModal: {
      modalOpen: editPurchaseOrderModalOpen,
      selectedAccount,
      activeMaterialProfile: currentActiveProfile || {},
      contacts: accountContacts,
      selectedPurchaseOrder,
      close: () => setEditPurchaseOrderModalOpen(false),
    },
    informationSectionProps: {
      classes,
      selectedPurchaseOrder: selectedPurchaseOrder ? selectedPurchaseOrder : {},
      StyledTypography,
      primaryLocation:
        selectedAccount && props.locations ?
          props.locations[selectedAccount.AccountId].find(location => location.PrimaryLocation) :
          {},
    },
    approvedTypesTableProps: {
      classes,
      selectedPurchaseOrder: selectedPurchaseOrder ? selectedPurchaseOrder : {},
      StyledTypography,
    },
    SupplementalPricingTableProps: {
      classes,
      selectedSupplementalPricing: selectedPurchaseOrder && selectedPurchaseOrder.SupplementalPricing,
      StyledTypography,
    },
    termsAndConditionsProps: {
      classes,
      StyledTypography,
    },
    fileViewModalProps: {
      open: viewFileModalOpen,
      files: selectedPurchaseOrderFiles,
      selectedPurchaseOrder,
      classes,
      uploadButtonOnClick: () => {
        setAddFileModalOpen(true);
      },
      close: () => setViewFileModalOpen(false),
    },
  };
  if (!selectedPurchaseOrder || currentActiveProfile === null || !selectedAccount || !materials || !props.locations) {
    return <LinearProgress />;
  }
  if (matchesMaterialProfileData === false) {
  //   console.error('The current purchase order does not match the active material profile. do something to warn user');
  }
  return (
    <Fragment>
      <div className='sideScrollingContainer' style={{marginBottom: '15px'}}>
        <BackButton />
        <Button {...core.printButton}>
          <Print className={classes.extendedIcon} />
          Print
        </Button>
        <Button {...core.editButton}>
          <Edit className={classes.extendedIcon} />
          {currentActiveProfile === undefined ? 'No Active Profile' : 'Edit'}
        </Button>
        <Button {...core.viewButton}>
          <SwapVerticalCircle className={classes.extendedIcon} />
          Manage Uploaded Files
        </Button>
      </div>
      <InformationSection {...core.informationSectionProps} />
      <ApprovedTypesTable {...core.approvedTypesTableProps} />
      {selectedPurchaseOrder.TermsAndConditions && (
        <Fragment>
          <div className={classes.pageBreak} />
          <TermsAndConditions {...core.termsAndConditionsProps} />
        </Fragment>
      )}
      {selectedPurchaseOrder.SupplementalPricing &&
        Object.keys(selectedPurchaseOrder.SupplementalPricing).length > 0 ? (
          <Fragment>
            <div className={classes.pageBreak} />
            <SupplementalPricingTable {...core.SupplementalPricingTableProps} />
          </Fragment>
        ) : null}
      {editPurchaseOrderModalOpen && <PurchaseOrderModal {...core.editPurchaseOrderModal} />}
      {addFileModalOpen && <AddFileModal {...core.fileModalProps} />}
      {viewFileModalOpen && <FileViewModal {...core.fileViewModalProps} />}
    </Fragment>
  );
};

const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  titleSection: {
    backgroundColor: 'red',
    color: 'white',
    borderBottom: '1px solid black',
  },
  borders: {
    borderTop: '1px solid black',
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
  },
  borderTop: {
    borderTop: '1px solid black',
  },
  borderLeft: {
    borderLeft: '1px solid black',
  },
  borderBottom: {
    borderBottom: '1px solid black',
  },
  borderRight: {
    borderRight: '1px solid black',
  },
  newSection: {
    marginTop: '25px',
  },
  textChildrenPadding: {
    '& p': {
      padding: '3px 5px',
    },
  },
  tableHeader: {
    backgroundColor: 'rgba(255,0,0,0.2)',
  },
  bold: {
    fontWeight: '600',
  },
  paddingLeft: {
    '& p': {
      paddingLeft: '5px',
    },
  },
  avoidPageBreak: {
    pageBreakInside: 'avoid',
  },
  pageBreak: {
    pageBreakBefore: 'always',
    display: 'block',
  },
  childrenFontSize: {
    '& p': {
      fontSize: '0.875rem',
    },
  },
  smallInputFont: {
    'fontSize': '0.80rem',
    '& p': {
      fontSize: '0.80rem',
    },
  },
  signatureBox: {
    marginTop: '25px',
  },
});
const StyledTypography = withStyles({
  body2: {
    fontSize: '0.8rem',
    lineHeight: '1.20',
  },
  subtitle2: {
    fontSize: '0.8rem',
    paddingLeft: '5px',
  },
  caption: {
    fontSize: '0.7rem',
    lineHeight: '1.10',
  },
})(Typography);
const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    users: state.firestore.users,
    accountProfiles: state.firestore.accountProfiles,
    purchaseOrders: state.firestore.purchaseOrders,
    accounts: state.firestore.accounts,
    contacts: state.firestore.contacts,
    materials: state.firestore.materials,
    types: state.firestore.types,
    locations: state.firestore.locations,
  };
};
const mapActionsToProps = {
  acGetAccountProfiles,
  acClearAccountProfiles,
};

PurchaseOrderToPdf.propTypes = {
  acGetAccountProfiles: PropTypes.func.isRequired,
  acClearAccountProfiles: PropTypes.func.isRequired,
  accountProfiles: PropTypes.object,
  purchaseOrders: PropTypes.object,
  materials: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
    materialList: PropTypes.arrayOf(PropTypes.object),
    yieldList: PropTypes.arrayOf(PropTypes.object),
    processList: PropTypes.arrayOf(PropTypes.object),
    inboundList: PropTypes.arrayOf(PropTypes.object),
    listener: PropTypes.func,
  }),
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  accounts: PropTypes.object,
  types: PropTypes.shape({
    listener: PropTypes.func.isRequired,
    // any number of properties that are materialId's
  }),
  contacts: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    ref: PropTypes.objectOf(PropTypes.object),
    active: PropTypes.arrayOf(PropTypes.object),
  }),
  locations: PropTypes.shape({
    listener: PropTypes.func.isRequired,
    // any number of keys that are account id's
  }),
};

export default flowright(
  connect(
    mapStateToProps,
    mapActionsToProps,
  ),
  withStyles(styles, {withTheme: true}),
  withRouter,
)(PurchaseOrderToPdf);
