import React from 'react';
import flowRight from 'lodash.flowright';
import {Button} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

function BackButton(props) {
  const buttonBack = {
    size: 'small',
    variant: 'outlined',
    onClick: () => props.history.goBack(),
    className: 'hidePrint',
  };
  return (
    <Button {...buttonBack}>
      <ChevronLeft className={props.classes.extendedIcon} />
      Back
    </Button>
  );
}

BackButton.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});
export default flowRight(
  withRouter,
  withStyles(styles, {withTheme: true}),
)(BackButton);
