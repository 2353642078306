import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionSummary, AccordionDetails, Grid, Typography, Button, LinearProgress} from '@material-ui/core';
import {ExpandMore, Search} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import {LocationInfoModal} from './Locations/';


class LocationsPanel extends Component {
  state = {
    newLocationModalOpen: false,
    viewLocationModalOpen: false,
    selectedLocation: null,
    expanded: false,
  };
  render() {
    const {newLocationModalOpen, viewLocationModalOpen, selectedLocation, expanded} = this.state;
    const {locations, classes, currentAccount} = this.props;
    const core = {
      locationsTable: {
        columns: [
          {accessor: 'LocationName', Header: 'Name'},
          {accessor: 'Active', Header: 'Active?', type: 'boolean'},
          {accessor: 'FirstOrThirdParty', Header: 'First/Third Party?'},
          {accessor: 'PrimaryLocation', Header: 'Primary Location', type: 'boolean'},
          {accessor: 'Address.SingleLine', Header: 'Address'},
        ],
        sortInitial: [{id: 'LocationName', desc: false}],
        data: locations,
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'View Location',
            onClick: ({rowData}) => {
              this.setState({viewLocationModalOpen: true, selectedLocation: rowData});
            },
          },
        ],
      },
      newLocationModal: {
        close: () => this.setState({newLocationModalOpen: false}),
        open: newLocationModalOpen,
        currentAccount,
      },
      viewLocationModal: {
        close: () => this.setState({viewLocationModalOpen: false, selectedLocation: null}),
        open: viewLocationModalOpen,
        selectedLocation,
        currentAccount,
      },
      emptyLocationsButton: {
        onClick: e => {
          e.stopPropagation();
          this.setState({newLocationModalOpen: true});
        },
        variant: 'outlined',
      },
    };
    const details = () => {
      if (!locations) {
        return <LinearProgress />;
      }
      else if (locations.length === 0) {
        return (
          <AccordionDetails>
            <Grid container justify='space-between'>
              <Typography variant='subtitle1'>No locations found for this account.</Typography>
            </Grid>
          </AccordionDetails>
        );
      }
      else {
        return (
          <AccordionDetails style={{padding: '0px'}}>
            <Grid container>
              <Grid item xs={12}>
                <Table {...core.locationsTable} />
              </Grid>
            </Grid>
          </AccordionDetails>
        );
      }
    };
    const openNewLocationModal = () => {
      if (newLocationModalOpen) {
        return <LocationInfoModal {...core.newLocationModal} />;
      }
      else return null;
    };
    const openViewLocationModal = () => {
      if (viewLocationModalOpen) {
        return <LocationInfoModal {...core.viewLocationModal} />;
      }
      else return null;
    };
    return (
      <Fragment>
        <Accordion expanded={expanded} onChange={() => this.setState({expanded: !expanded})}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Grid container justify='space-between'>
              <div>
                <Typography className={classes.heading}>Locations</Typography>
              </div>
              {expanded ? (
                <div>
                  <Button {...core.emptyLocationsButton}>Add Location</Button>
                </div>
              ) : null}
            </Grid>
          </AccordionSummary>
          {details()}
        </Accordion>
        {openNewLocationModal()}
        {openViewLocationModal()}
      </Fragment>
    );
  }
}

LocationsPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
};
export default LocationsPanel;
