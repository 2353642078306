import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Typography, Accordion, AccordionSummary, AccordionDetails, Button, Grid, LinearProgress} from '@material-ui/core';
import {Table} from '@kbi/component-library';
import {ExpandMore, Search} from '@material-ui/icons';
import {ShipmentModal} from './ShipmentsPanel/';

const ShipmentsPanel = props => {
  const {classes, shipments, currentAccount, contacts} = props;
  const [shipmentModalOpen, setShipmentModalOpen] = useState(false);
  const [currentAccountShipments, setCurrentAccountShipments] = useState(null);
  const [selectedShipment, setSelectedShipment] = useState(null); // is used by the shipment modal in order to pass the shipment that has been selected to edit
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    // if redux has acquired the shipments, set the currentAccountShipments to match the shipments based on the account ID
    if (shipments) setCurrentAccountShipments(shipments[currentAccount.AccountId] || []);
  }, [shipments, currentAccount.AccountId]);

  const details = () => {
    if (!currentAccountShipments) {
      return <LinearProgress />;
    }
    else if (currentAccountShipments.length === 0) {
      return (
        <AccordionDetails>
          <Grid container justify='space-between'>
            <Typography variant='subtitle1'>No shipments found for this account.</Typography>
          </Grid>
        </AccordionDetails>
      );
    }
    else {
      return (
        <AccordionDetails style={{padding: '0px'}}>
          <Grid container>
            <Grid item xs={12}>
              <Table {...core.shipmentsTable} />
            </Grid>
          </Grid>
        </AccordionDetails>
      );
    }
  };
  const core = {
    shipmentsTable: {
      columns: [
        {accessor: 'Active', Header: 'Active', type: 'boolean'},
        {accessor: 'System.CreatedBy', Header: 'Created By'},
        {accessor: 'Facility', Header: 'Facility'},
        {accessor: 'StartDateTime', Header: 'Scheduled Time', type: 'datetime', datetimeFormat: 'MM/DD/YY h:mm a'},
      ],
      sortInitial: [{id: 'Active', desc: false}],
      data: currentAccountShipments,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Shipment',
          onClick: ({rowData}) => {
            setShipmentModalOpen(true);
            setSelectedShipment(rowData);
          },
        },
      ],
    },
    addShipment: {
      variant: 'outlined',
      color: 'default',
      onClick: e => {
        e.stopPropagation();
        setShipmentModalOpen(true);
      },
    },
    shipmentModal: {
      contacts,
      selectedAccount: currentAccount,
      selectedShipment,
      close: () => {
        setShipmentModalOpen(false);
        setSelectedShipment(null);
      },
    },
  };
  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container justify='space-between'>
          <div>
            <Typography className={classes.heading}>Shipments</Typography>
          </div>
          {expanded ? (
            <div>
              <Button {...core.addShipment}>
              Add Shipment
              </Button>
            </div>
          ) : null}
        </Grid>
      </AccordionSummary>
      {details()}
      {shipmentModalOpen && <ShipmentModal {...core.shipmentModal} />}
    </Accordion>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    shipments: state.firestore.shipments,
  };
};
ShipmentsPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  shipments: PropTypes.object,
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps)(ShipmentsPanel);
