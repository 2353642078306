import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, TextField, Grid, Collapse} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {Alert} from '@kbi/component-library';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {connect} from 'react-redux';
import flowRight from 'lodash.flowright';

class ViewNoteModal extends Component {
  state = {
    deleteConfirmation: false,
    // eslint-disable-next-line no-invalid-this
    noteMessage: {error: null, value: this.props.selectedNote.Note},
    stage: 'basic',
    submitting: false,
    deleting: false,
  };
  resetDeleteConformation() {
    this.setState({deleteConfirmation: false});
  };
  userAuthorization() {
    if (
      this.props.currentUser.uid === this.props.selectedNote.Owner.Uid ||
      this.props.currentUser.roleCRM === 'Admin'
    ) {
      // if this conditional is passed, the user does have access to changing the note.
      return false;
    }
    else return true;
  }
  handleClose() {
    this.resetDeleteConformation();
    this.props.close();
  };
  handleDeleteConfirm() {
    const {accountSelect, leadSelect, selectedNote} = this.props;
    this.setState({deleting: true});
    const firestoreBatch = Firestore.batch();
    if (accountSelect) {
      const accountNoteRef = Firestore.collection('CRM-Accounts')
        .doc(accountSelect.id)
        .collection('Notes')
        .doc(selectedNote.id);
      firestoreBatch.delete(accountNoteRef);
    }
    if (leadSelect) {
      const leadNoteRef = Firestore.collection('CRM-Leads')
        .doc(leadSelect.value)
        .collection('Notes')
        .doc(selectedNote.id);
      firestoreBatch.delete(leadNoteRef);
    }
    else if (selectedNote.Contact.Id) {
      const contactNoteRef = Firestore.collection('CRM-Contacts')
        .doc(selectedNote.Contact.Id)
        .collection('Notes')
        .doc(selectedNote.id);
      firestoreBatch.delete(contactNoteRef);
    }

    firestoreBatch
      .commit()
      .then(() => {
        this.setState({deleting: false, stage: 'success'});
      })
      .catch(error => {
        this.setState({formError: 'There was an error deleting the note.'});
      });
  };
  handleSubmitEditClick() {
    const {noteMessage} = this.state;
    if (!noteMessage.value) {
      this.setState({noteMessage: {...noteMessage, error: 'Note is a required field.'}});
      return;
    }
    this.setState({submitting: true});
    const {accountSelect, leadSelect, selectedNote} = this.props;
    const updateData = {
      Note: this.state.noteMessage.value,
      LastEdit: {
        LastEditDate: firebase.firestore.Timestamp.now(),
        LastEditUser: {
          Name: this.props.currentUser.displayName,
          Uid: this.props.currentUser.uid,
        },
      },
    };
    const firestoreBatch = Firestore.batch();
    if (accountSelect) {
      const accountNoteRef = Firestore.collection('CRM-Accounts')
        .doc(accountSelect.id)
        .collection('Notes')
        .doc(selectedNote.id);
      firestoreBatch.update(accountNoteRef, updateData);
    }
    if (leadSelect) {
      const leadNoteRef = Firestore.collection('CRM-Leads')
        .doc(leadSelect.value)
        .collection('Notes')
        .doc(selectedNote.id);
      firestoreBatch.update(leadNoteRef, updateData);
    }
    else if (selectedNote.Contact.Id) {
      const contactNoteRef = Firestore.collection('CRM-Contacts')
        .doc(selectedNote.Contact.Id)
        .collection('Notes')
        .doc(selectedNote.id);
      firestoreBatch.update(contactNoteRef, updateData);
    }

    firestoreBatch
      .commit()
      .then(() => {
        this.setState({submitting: false, stage: 'success'});
      })
      .catch(error => {
        this.setState({formError: 'There was an error updating the note.'});
      });
  };
  determineButtonsToShow() {
    const core = {
      deleteButton: {
        color: 'secondary',
        onClick: () => {
          this.setState({deleteConfirmation: true});
        },
      },
      confirmButton: {
        color: 'secondary',
        onClick: () => this.handleDeleteConfirm(),
        text: 'Confirm?',
        loading: this.state.deleting,
        disabled: this.state.submitting,
      },
      cancelButton: {
        color: 'primary',
        onClick: () => this.handleClose(),
      },
      submitButton: {
        text: 'Submit',
        color: 'primary',
        loading: this.state.submitting,
        onClick: () => this.handleSubmitEditClick(),
        disabled: this.state.deleting,
      },
    };
    if (this.state.stage === 'success') {
      return (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <Button {...core.cancelButton}>Close</Button>
        </DialogActions>
      );
    }
    if (this.userAuthorization()) {
      return (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <Button {...core.cancelButton}>Cancel</Button>
        </DialogActions>
      );
    }
    else {
      if (this.state.deleteConfirmation) {
        return (
          <DialogActions style={{justifyContent: 'space-between'}}>
            <SubmitButton {...core.confirmButton} />
            <div>
              <Button {...core.cancelButton}>Cancel</Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </DialogActions>
        );
      }
      else {
        return (
          <DialogActions style={{justifyContent: 'space-between'}}>
            <Button {...core.deleteButton}>Delete</Button>
            <div>
              <Button {...core.cancelButton}>Cancel</Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </DialogActions>
        );
      }
    }
  }
  render() {
    const {open, selectedNote} = this.props;
    const {noteMessage} = this.state;
    const core = {
      submitConfirmation: {
        text: this.state.deleteConfirmation ? 'Note successfully deleted.' : 'Note successfully updated.',
        stage: this.state.stage,
        delete: this.state.deleteConfirmation,
      },
      dialog: {
        open,
        scroll: 'body',
        transitionDuration: {exit: 0},
        fullWidth: true,
      },
      noteOwner: {
        label: 'Owner',
        fullWidth: true,
        value: selectedNote.Owner.Name,
        disabled: true,
      },
      noteDate: {
        label: 'Date',
        disabled: true,
        fullWidth: true,
        value: selectedNote.Date,
      },
      noteMessage: {
        disabled: this.userAuthorization(),
        error: noteMessage.error ? true : false,
        helperText: noteMessage.error,
        multiline: true,
        required: true,
        fullWidth: true,
        rowsMax: '5',
        label: 'Note',
        value: noteMessage.value,
        onChange: e => {
          this.setState({noteMessage: {...noteMessage, value: e.target.value, error: null}});
        },
      },
    };
    return (
      <Dialog {...core.dialog}>
        <DialogTitle>View Note</DialogTitle>
        <DialogContent>
          <DialogContentText>Edit or delete the note</DialogContentText>
          <Collapse in={this.state.stage === 'basic'}>
            <Fragment>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField {...core.noteOwner} />
                </Grid>
                <Grid item xs={4}>
                  <TextField {...core.noteDate} />
                </Grid>
                <Grid item xs={12}>
                  <TextField {...core.noteMessage} />
                </Grid>
              </Grid>
              <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
            </Fragment>
          </Collapse>
          <SubmitConfirmation {...core.submitConfirmation} />
        </DialogContent>
        {this.determineButtonsToShow()}
      </Dialog>
    );
  }
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
});
ViewNoteModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedNote: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  firestoreCollection: PropTypes.string.isRequired,
  accountSelect: PropTypes.object,
  leadSelect: PropTypes.object,
  contactSelect: PropTypes.object,
};
const mapStateToProps = state => {
  const {currentUser} = state.auth;
  return {currentUser};
};
export default flowRight(
  connect(mapStateToProps),
  withStyles(styles),
)(ViewNoteModal);
