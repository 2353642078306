export const GET_SHIPMENTS = 'module_crm/state/firestore/GET_SHIPMENTS';
export const SET_SHIPMENT_LISTENER = 'module_crm/state/firestore/SET_SHIPMENT_LISTENER';
export const SET_ACCOUNTS = 'module_crm/state/firestore/SET_ACCOUNTS';
export const SET_ACCOUNT_LISTENER = 'module_crm/state/firestore/SET_ACCOUNT_LISTENER';
export const GET_LOCATIONS = 'module_crm/state/firestore/GET_LOCATIONS';
export const SET_PURCHASE_ORDERS = 'module_crm/state/firestore/SET_PURCHASE_ORDERS';
export const SET_PURCHASE_ORDER_LISTENER = 'module_crm/state/firestore/SET_PURCHASE_ORDER_LISTENER';
export const SET_MATERIALS = 'module_crm/state/firestore/SET_MATERIALS';
export const SET_ALL_MATERIAL_TYPES = 'module_crm/state/firestore/SET_ALL_MATERIAL_TYPES';
export const SET_USERS = 'module_crm/state/firestore/SET_USERS';
export const SET_CONTACTS = 'module_crm/state/firestore/SET_CONTACTS';
export const SET_LEADS = 'module_crm/state/firestore/SET_LEADS';
export const GET_OUTLOOK_USERS = 'module_crm/state/firestore/GET_OUTLOOK_USERS';
export const SET_ACCOUNT_PROFILES = 'module_crm/state/firestore/SET_ACCOUNT_PROFILES';
