/* eslint-disable guard-for-in */
import React, {useState, Fragment, useContext, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button, Paper, Typography, Collapse} from '@material-ui/core';
import {EventNote, ChevronLeft, Search} from '@material-ui/icons';
import {NewReportModal} from './BaseReportView/';
import {ReportContext} from 'App.js';
import {TableWithPaper} from 'components/';
import {useHistory} from 'react-router-dom';

const BaseReportView = props => {
  const reportContext = useContext(ReportContext);
  const [newReportModalOpen, setNewReportModalOpen] = useState(false);
  const history = useHistory();

  const core = useMemo(() =>({
    paper: {
      className: props.classes.paper,
      style: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    backButton: {
      variant: 'outlined',
      onClick: () => {
        reportContext.setReportData([]);
        reportContext.setView(null);
      },
    },
    createReportButton: {
      color: 'primary',
      variant: 'outlined',
      onClick: () => setNewReportModalOpen(true),
    },
    newReportModal: {
      open: newReportModalOpen,
      close: () => setNewReportModalOpen(false),
      changeView: ({view, data}) => {
        reportContext.setReportData(data);
        reportContext.setView(view);
      },
    },
    materialProfileTable: {
      columns: [
        {accessor: 'id', Header: 'Id'},
        {Header: 'Account Name', accessor: 'Account.Name'},
        {Header: 'Uploaded By', accessor: 'UploadedBy.Name'},
        {Header: 'Effective Date', accessor: 'EffectiveDate', type: 'datetime'},
        {Header: 'Expiration Date', accessor: 'Expiration', type: 'datetime'},
      ],
      sortInitial: [{id: 'EffectiveDate', desc: false}],
      data: reportContext?.reportData,
      paginationSizes: [10, 25, 50],
      paginationInitialSize: 10,
      title: {primary: 'Profiles Pending Approval'},
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Profile',
          onClick: ({rowData}) => {
            history.push(`/accounts/${rowData.accountId}/material-profile/${rowData.id}`);
          },
        }],
    },
  }), [props.classes.paper, newReportModalOpen, reportContext, history]);

  return (
    <Fragment>
      {reportContext.view && (
        <Button {...core.backButton}>
          <ChevronLeft className={props.classes.extendedIcon} />
          New Report
        </Button>
      )}
      <Collapse in={!reportContext.view}>
        <Paper {...core.paper}>
          <Typography variant='h4' gutterBottom>
            CRM Reporting
          </Typography>
          <EventNote style={{fontSize: '10em'}} />
          <Button {...core.createReportButton}>Create Report</Button>
        </Paper>
      </Collapse>
      <Collapse in={reportContext.view === 'Profiles Pending Approval'}>
        {reportContext.view === 'Profiles Pending Approval' && (
          <TableWithPaper {...core.materialProfileTable} />
        )}
      </Collapse>
      {newReportModalOpen && <NewReportModal {...core.newReportModal} />}
    </Fragment>
  );
};

BaseReportView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default BaseReportView;
