/* eslint-disable no-var */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, List, ListItem, Paper, Tooltip, Typography, Fab, MenuItem, MenuList, LinearProgress} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {AttachMoney, Business, Cached, LocalShipping} from '@material-ui/icons';
import {Edit, LocalPrintshop, Domain, LocationOn, Warning} from '@material-ui/icons';
import {withRouter} from 'react-router-dom';
import {BackButton} from 'components/';
import {
  ContactsPanel,
  EventsTasksPanel,
  LocationsPanel,
  NotesPanel,
  FilePanel,
  MaterialProfilePanel,
  PurchaseOrderPanel,
  ShipmentsPanel,
} from '../panels';
import {acGetAccountProfiles, acClearAccountProfiles} from '../state/firestore/actions.js';
import {connect} from 'react-redux';
import {AccountInfoModal} from './Accounts/';
import flowRight from 'lodash.flowright';
// import {Functions} from 'config.js';

class AccountDetails extends Component {
  state = {
    editAccountModal: false,
    selectedProfile: null,
    // this will be the current account. it is assigned in componentDidMount and componentDidUpdate, depending on when the props are set.
    selectedAccount: undefined,
    accountContacts: undefined,
    events: [],
    loadingEvents: false,
  };

  componentDidMount() {
    const {accounts, match, contacts, acGetAccountProfiles} = this.props;
    acGetAccountProfiles(match.params.AccountId);
    if (accounts && contacts) {
      this.setState(
        {
          selectedAccount: accounts[match.params.AccountId],
          accountContacts: contacts.list.filter(
            contact => contact.RefAccount.Id === accounts[this.props.match.params.AccountId].AccountId,
          ),
        },
        /* DK 1/30/2023 This cloud function always throws an error; No longer seems to be used anyway.
        () =>
          this.handleGetEvents(
            this.state.accountContacts.filter(c => c.Email.Work || c.Email.Home).map(c => c.Email.Work || c.Email.Home),
          ),
        */
      );
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {accounts, history, match, contacts} = this.props;
    if (prevProps.accounts !== accounts) {
      this.setState({
        selectedAccount: accounts[match.params.AccountId],
      });
    }
    if (prevProps.contacts !== contacts || prevProps.accounts !== accounts) {
      if (contacts && accounts) {
        this.setState(
          {
            accountContacts: contacts.list.filter(
              contact => contact.RefAccount.Id === accounts[match.params.AccountId].AccountId,
            ),
          },
          /* DK 1/30/2023 This cloud function always throws an error; No longer seems to be used anyway.
          () =>
            this.handleGetEvents(
              this.state.accountContacts.filter(c => c.Email.Work || c.Email.Home).map(c => c.Email.Work || c.Email.Home),
            ),
          */
        );
      }
    }
    if (accounts && !accounts[match.params.AccountId]) {
      history.push('/accounts');
    }
  }
  componentWillUnmount() {
    this.props.acClearAccountProfiles(this.props.accountProfiles);
  }
  /* DK 1/30/2023 This cloud function always throws an error; No longer seems to be used anyway.
  handleGetEvents(contactAddress) {
    this.setState({loadingEvents: true});
    Functions.httpsCallable('getEvents')({contactAddress})
      .then(res => {
        this.setState(prevState => ({events: [...prevState.events, ...res.data], loadingEvents: false}));
      })
      .catch(err => {
        this.setState({loadingEvents: false});
        console.log('events err', err);
      });
  };
  */
  render() {
    const {accounts, classes, currentUser, match, contacts, history, accountProfiles, purchaseOrders} = this.props;
    const {selectedProfile, selectedAccount, accountContacts, events} = this.state;

    if (!accounts || !currentUser || !contacts) return <LinearProgress />;
    if (!selectedAccount) return <LinearProgress />;

    const {AccountId, AccountName, AccountType, Website} = selectedAccount;
    if (
      this.props.locations &&
      this.props.locations[selectedAccount.AccountId].find(location => location.PrimaryLocation)
    ) {
      var primaryLocation = this.props.locations[selectedAccount.AccountId].find(location => location.PrimaryLocation);
      var {Phone} = primaryLocation;
    }
    // const filteredContacts = contacts.list.filter(contact => contact.RefAccount.Id === AccountId);

    const core = {
      buttonEdit: {
        size: 'small',
        variant: 'outlined',
        style: {marginLeft: '8px'},
        onClick: () => this.setState({editAccountModal: true}),
      },
      editAccountModal: {
        selectedAccount: selectedAccount,
        close: () => this.setState({editAccountModal: false}),
      },
      notesPanel: {
        firestoreCollection: 'CRM-Accounts',
        documentId: AccountId,
        currentUser,
        contactsRef: contacts.ref,
        contacts: accountContacts,
        type: 'account',
        readOnly: false,
        accountSelect: {
          id: match.params.AccountId,
          name: AccountName,
        },
      },
      eventsAndTasksPanel: {
        classes: classes,
        events,
        listOfContacts: accountContacts,
        handleNewEvent: () => {
          this.setState({addEventModal: true});
        },
      },
      materialProfilePanel: {
        classes,
        history,
        selectedAccount: selectedAccount,
        accountContacts,
        profiles: accountProfiles && selectedAccount ? accountProfiles.profiles : [],
        locations: selectedAccount && this.props.locations ? this.props.locations[selectedAccount.AccountId] : [],
      },
      purchaseOrderPanel: {
        classes,
        history,
        purchaseOrders:
          // this conditional first confirms that the accounts and purchase orders have been defined, then that the account has purchase orders
          purchaseOrders && selectedAccount && purchaseOrders[selectedAccount.AccountId] ?
            purchaseOrders[selectedAccount.AccountId] :
            [],
        contacts: accountContacts,
        activeMaterialProfile: accountProfiles ? accountProfiles.profiles.find(profile => profile.Active) : {},
        selectedAccount: selectedAccount,
      },
      contactPanel: {
        classes,
        contacts: accountContacts || [],
        currentUser,
        AccountId,
        AccountName,
        history,
      },
      locationsPanel: {
        classes,
        locations:
          selectedAccount && this.props.locations && this.props.locations[selectedAccount.AccountId] ?
            this.props.locations[selectedAccount.AccountId] :
            [],
        currentAccount: selectedAccount,
      },
      shipmentsPanel: {
        classes,
        currentAccount: selectedAccount,
        contacts: accountContacts,
      },
      menuIcon: {
        style: {paddingRight: '20px', fontSize: '3rem'},
      },
      menuPrimaryText: {
        style: {lineHeight: 1.5, fontSize: '0.85rem'},
        variant: 'subtitle1',
      },
      menuSecondaryText: {
        noWrap: true,
        style: {lineHeight: 1.2, fontSize: '1em'},
        variant: 'caption',
      },
      menuDiv: {
        style: {display: 'flex', flexDirection: 'column'},
      },
      menuDividers: {
        variant: 'subtitle1',
        style: {width: '100%', textAlign: 'left', marginLeft: '8px', fontWeight: '600'},
      },
      fabStyle: type => {
        return {
          size: 'small',
          color: AccountType[type] ? 'primary' : null,
          disableRipple: true,
          style: {cursor: 'default'},
        };
      },
      profileToPdf: {
        selectedProfile,
        selectedAccount: selectedAccount,
        accountContacts: accountContacts,
      },
    };
    const openModalFunction = () => {
      if (this.state.editAccountModal) {
        return <AccountInfoModal {...core.editAccountModal} />;
      }
      else return null;
    };
    return (
      <Fragment>
        <BackButton />
        <Button {...core.buttonEdit}>
          <Edit className={classes.extendedIcon} />
          Edit
        </Button>
        <Grid spacing={5} container style={{marginTop: '8px'}}>
          <Grid item xs={12} md={3}>
            <Paper className={classes.paper}>
              <Business style={{fontSize: '10em', fill: 'slategray', width: '100%'}} />
              <List style={{minWidth: '100%', maxWidth: '100%'}}>
                <ListItem style={{flexDirection: 'column'}}>
                  <Typography variant='h6' style={{lineHeight: '1.3', textAlign: 'center'}}>
                    {AccountName}
                  </Typography>
                  {Website && (
                    <Typography variant='caption'>
                      <a href={Website} target='_blank' rel='noopener noreferrer'>
                        {Website}
                      </a>
                    </Typography>
                  )}
                </ListItem>
                <ListItem style={{justifyContent: 'space-around'}}>
                  <Tooltip title='Customer'>
                    <Fab {...core.fabStyle('Customer')}>
                      <AttachMoney />
                    </Fab>
                  </Tooltip>
                  <Tooltip title='Downstream'>
                    <Fab {...core.fabStyle('Downstream')}>
                      <Cached />
                    </Fab>
                  </Tooltip>
                  <Tooltip title='Transporter'>
                    <Fab {...core.fabStyle('Transporter')}>
                      <LocalShipping />
                    </Fab>
                  </Tooltip>
                </ListItem>
                <ListItem style={{flexDirection: 'column'}}>
                  {!primaryLocation ? (
                    // if there is no location set as primary, it will be undefined.
                    // the menu that normally displays the contact information will be replaced with a promt to select a primary location
                    <MenuList className={`${classes.disabled} ${classes.menuStyles}`}>
                      <Typography {...core.menuDividers}>
                        Set a headquarters to display contact information
                      </Typography>
                    </MenuList>
                  ) : (
                    <MenuList className={`${classes.disabled} ${classes.menuStyles}`}>
                      <StyledMenuItem disableGutters className={classes.disabled}>
                        <Typography {...core.menuDividers}>Primary Address</Typography>
                      </StyledMenuItem>
                      <StyledMenuItem className={classes.disabled}>
                        <LocationOn {...core.menuIcon} />
                        <div {...core.menuDiv}>
                          <Typography {...core.menuPrimaryText}>
                            {primaryLocation.Address.TripleLine.Line1}
                          </Typography>
                          {primaryLocation.Address.TripleLine.Line2 && (
                            <Typography {...core.menuSecondaryText} style={{fontSize: '0.85rem'}}>
                              {primaryLocation.Address.TripleLine.Line2}
                            </Typography>
                          )}
                          <Typography {...core.menuPrimaryText}>
                            {primaryLocation.Address.TripleLine.Line3 && primaryLocation.Address.TripleLine.Line3}
                          </Typography>
                        </div>
                      </StyledMenuItem>
                      {Phone && (Phone.Primary || Phone.Emergency || Phone.Fax) && (
                        <StyledMenuItem disableGutters className={classes.disabled}>
                          <Typography {...core.menuDividers}>Phone Numbers</Typography>
                        </StyledMenuItem>
                      )}
                      {Phone && Phone.Primary && (
                        <StyledMenuItem className={classes.disabled}>
                          <Domain {...core.menuIcon} />
                          <div {...core.menuDiv}>
                            <Typography {...core.menuSecondaryText}>Primary</Typography>
                            <Typography {...core.menuPrimaryText}>{Phone.Primary}</Typography>
                          </div>
                        </StyledMenuItem>
                      )}
                      {Phone && Phone.Fax && (
                        <StyledMenuItem className={classes.disabled}>
                          <LocalPrintshop {...core.menuIcon} />
                          <div {...core.menuDiv}>
                            <Typography {...core.menuSecondaryText}>Fax</Typography>
                            <Typography {...core.menuPrimaryText}>{Phone.Fax}</Typography>
                          </div>
                        </StyledMenuItem>
                      )}
                      {Phone && Phone.Emergency && (
                        <StyledMenuItem className={classes.disabled}>
                          <Warning {...core.menuIcon} />
                          <div {...core.menuDiv}>
                            <Typography {...core.menuSecondaryText}>Emergency</Typography>
                            <Typography {...core.menuPrimaryText}>{Phone.Emergency}</Typography>
                          </div>
                        </StyledMenuItem>
                      )}
                    </MenuList>
                  )}
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <ContactsPanel {...core.contactPanel} />
            <LocationsPanel {...core.locationsPanel} />
            <NotesPanel {...core.notesPanel} />
            <EventsTasksPanel {...core.eventsAndTasksPanel} />
            <FilePanel
              classes={classes}
              currentAccount={selectedAccount}
            />
            <MaterialProfilePanel {...core.materialProfilePanel} />
            <PurchaseOrderPanel {...core.purchaseOrderPanel} />
            <ShipmentsPanel {...core.shipmentsPanel} />
          </Grid>
        </Grid>
        {openModalFunction()}
      </Fragment>
    );
  }
}

const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    style: {display: 'flex', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center'},
  },
  menuStyles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'ghostwhite',
    border: '1px solid darkgray',
    margin: '8px 0px',
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  fabStyle: {
    '&:hover': {backgroundColor: 'transparent'},
  },
});
const StyledMenuItem = withStyles({
  root: {
    minHeight: '0px',
    whiteSpace: 'normal',
  },
})(MenuItem);

const mapStateToProps = state => {
  return {
    accounts: state.firestore.accounts,
    contacts: state.firestore.contacts,
    currentUser: state.auth.currentUser,
    purchaseOrders: state.firestore.purchaseOrders,
    locations: state.firestore.locations,
    accountProfiles: state.firestore.accountProfiles,
  };
};
const mapActionsToProps = {
  acGetAccountProfiles,
  acClearAccountProfiles,
};
AccountDetails.propTypes = {
  accounts: PropTypes.objectOf(PropTypes.object),
  contacts: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    ref: PropTypes.objectOf(PropTypes.object),
  }),
  acGetAccountProfiles: PropTypes.func.isRequired,
  acClearAccountProfiles: PropTypes.func.isRequired,
  accountProfiles: PropTypes.object,
  purchaseOrders: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  locations: PropTypes.shape({
    listener: PropTypes.func.isRequired,
    // any number of keys that are account ID's
  }),
};
export default flowRight(
  connect(
    mapStateToProps,
    mapActionsToProps,
  ),
  withStyles(styles, {withTheme: true}),
  withRouter,
)(AccountDetails);
