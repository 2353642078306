import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import PropTypes from 'prop-types';
import {Formik} from '@kbi/component-library';
const {CheckboxGroup, FormButton} = Formik;

const CheckboxForm = ({typesForBoxes, setFieldValue, currentCheckboxValue}) => {
  const checkAll = () => {
    const setOfBoxes = new Set([...currentCheckboxValue, ...typesForBoxes.map(type => type.value)]);
    setFieldValue('supplementalPricing', [...setOfBoxes], false);
  };
  const uncheckAll = () => {
    const filteredArray = currentCheckboxValue.filter(currentValue => {
      if (typesForBoxes.find(type => type.value === currentValue)) {
        return false;
      }
      else return true;
    });
    setFieldValue('supplementalPricing', filteredArray, false);
  };

  return (
    <Accordion style={{width: '100%'}} >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{typesForBoxes[0].materialName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CheckboxGroup name='supplementalPricing' id={`supplementalPricing${typesForBoxes[0].materialName}`} label=' ' boxes={typesForBoxes} row />
      </AccordionDetails>
      <AccordionActions>
        <FormButton color='secondary' variant='text' onClick={uncheckAll}>Uncheck All</FormButton>
        <FormButton color='primary' variant='text' onClick={checkAll}>Check All</FormButton>
      </AccordionActions>
    </Accordion>
  );
};

CheckboxForm.propTypes = {
  typesForBoxes: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  currentCheckboxValue: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CheckboxForm;
