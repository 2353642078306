import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {SubmitButton} from 'components';
import {Delete} from '@material-ui/icons';
import {Firestore, Functions} from 'config.js';
import {withStyles, Button} from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/firestore';

function createContactData(contact, folderId) {
  const o365Contact = {
    given_name: contact.Name.First,
    surname: contact.Name.Last,
    middle_name: contact.Name.Middle,
    company_name: contact.RefAccount.Name,
    emails: [],
    phone_numbers: [],
    group: folderId,
  };
  if (contact.Email.Work) o365Contact.emails.push({type: 'work', email: contact.Email.Work});
  if (contact.Email.Home) o365Contact.emails.push({type: 'personal', email: contact.Email.Home});
  if (contact.Phone.Office) o365Contact.phone_numbers.push({type: 'business', number: contact.Phone.Office});
  if (contact.Phone.Fax) o365Contact.phone_numbers.push({type: 'business_fax', number: contact.Phone.Fax});
  if (contact.Phone.Home) o365Contact.phone_numbers.push({type: 'home', number: contact.Phone.Home});
  if (contact.Phone.Mobile) o365Contact.phone_numbers.push({type: 'mobile', number: contact.Phone.Mobile});
  return o365Contact;
}

const ContactToO365Button = props => {
  const [confirmation, setConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {currentUser, contact} = props;
  const addToO365 = async () => {
    if (!currentUser.nylasAccessToken || !currentUser.nylasId) {
      window.alert('You are not connected to Nylas. Please speak to an admin.');
      setSubmitting(false);
      return;
    }
    try {
      const res = await Functions.httpsCallable('addNylasContact')({
        accessToken: currentUser.nylasAccessToken,
        body: createContactData(contact, currentUser.nylasFolderId),
      });
      const nylasLinkUpdate = {
        [`NylasLinks.${currentUser.uid}`]: {
          nylasContactId: res.data.id,
          nylasAccessToken: currentUser.nylasAccessToken,
          nylasId: currentUser.nylasId,
        },
        NylasContactIds: firebase.firestore.FieldValue.arrayUnion(res.data.id),
      };
      await Firestore.collection('CRM-Contacts')
        .doc(contact.ContactId)
        .update(nylasLinkUpdate);
      setSubmitting(false);
    }
    catch (error) {
      setSubmitting(false);
      alert(`There was an error adding the contact to your Outlook: ${error}`);
    }
  };

  const deleteFromO365 = async () => {
    try {
      await Functions.httpsCallable('removeNylasContact')({
        accessToken: currentUser.nylasAccessToken,
        nylasId: contact.NylasLinks[currentUser.uid].nylasContactId,
      });
      const nylasLinkUpdate = {
        [`NylasLinks.${currentUser.uid}`]: null,
        NylasContactIds: firebase.firestore.FieldValue.arrayRemove(contact.NylasLinks[currentUser.uid].nylasContactId),
      };
      await Firestore.collection('CRM-Contacts')
        .doc(contact.ContactId)
        .update(nylasLinkUpdate);
      setConfirmation(false);
      setSubmitting(false);
    }
    catch (error) {
      setSubmitting(false);
      alert(`There was an error removing the contact to your Outlook: ${error}`);
    }
  };
  const core = {
    deleteButton: {
      size: 'small',
      style: {marginLeft: '8px'},
      variant: 'outlined',
      disabled: submitting,
      onClick: () => setConfirmation(true),
    },
    addButton: {
      text: 'Add to O365',
      size: 'small',
      icon: 'AddCircle',
      loading: submitting,
      style: {marginLeft: '8px'},
      variant: 'outlined',
      onClick: () => {
        setSubmitting(true);
        addToO365();
      },
    },
    confirmButton: {
      loading: submitting,
      color: 'secondary',
      icon: 'Delete',
      size: 'small',
      text: 'confirm?',
      style: {marginLeft: '8px'},
      variant: 'outlined',
      onClick: () => {
        setSubmitting(true);
        deleteFromO365();
      },
    },
  };
  if (contact.NylasLinks[currentUser.uid]) {
    if (confirmation) {
      return <SubmitButton {...core.confirmButton} />;
    }
    else {
      return (
        <Button {...core.deleteButton}>
          <Delete className={props.classes.extendedIcon} />
          Delete From O365
        </Button>
      );
    }
  }
  else {
    return (
      <Fragment>
        <SubmitButton {...core.addButton} />
      </Fragment>
    );
  }
};
const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});
ContactToO365Button.propTypes = {
  currentUser: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles, {withTheme: true})(ContactToO365Button);
