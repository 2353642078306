import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, Typography, Chip} from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import moment from 'moment';

class EventDisplayModal extends Component {
  handleClose() {
    this.props.close();
  };
  createAttendees() {
    return this.props.event.attendees.map((attnd, i) => {
      return <Chip key={i} icon={<FaceIcon />} label={attnd.name}
        style={{margin: 5}}
      />;
    });
  };
  render() {
    const {modalOpen, event} = this.props;
    const core = {
      dialog: {
        open: modalOpen,
        scroll: 'body',
        transitionDuration: {exit: 0},
        // PaperProps: { style: { height: '70%' } }
      },
      iframe: {
        sandbox: '',
        style: {width: '100%', height: '100%', borderWidth: 0},
        srcDoc: event ? event.body.content : '',
      },
    };
    return (
      <Dialog {...core.dialog}>
        <DialogContent>
          <Typography variant='subtitle1'>Subject: {event.subject}</Typography>
          <Typography variant='subtitle1'>Location: {event.location}</Typography>
          <Typography variant='subtitle1'>Start: {moment(event.start, 'LLL').format('LLLL')}</Typography>
          <Typography variant='subtitle1'>End: {moment(event.end, 'LLL').format('LLLL')}</Typography>
          <Typography variant='subtitle1'>Attendees: </Typography>
          <span>{this.createAttendees()}</span>
          <Typography variant='subtitle1'>Message: </Typography>
          <iframe title={'Event'} {...core.iframe} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color='primary'>
            Back
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EventDisplayModal.propTypes = {
  close: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  event: PropTypes.object,
};
export default EventDisplayModal;
