/* eslint-disable no-console */
import React, {Component} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Chip,
  Collapse,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FaceIcon from '@material-ui/icons/Face';
import {KbiAutoText} from 'kbi-components';
import moment from 'moment';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {connect} from 'react-redux';
import {Functions} from 'config.js';

const cleanState = {
  attendee: {
    display: 'Attendee',
    value: '',
    error: '',
  },
  subject: {
    display: 'Subject',
    value: '',
    error: '',
  },
  body: {
    display: 'Description',
    contentType: 'HTML',
    value: '',
    error: '',
  },
  start: {
    display: 'Start Time',
    value: '',
  },
  end: {
    display: 'End Time',
    value: '',
    error: '',
  },
  location: {
    display: 'Location',
    value: '',
    error: '',
  },
  allAttendees: [],
  accountContacts: [],
  stage: 'basic',
  submitting: false,
};

class NewEventModal extends Component {
  state = {...cleanState,
    start: {...cleanState.start,
      value: moment()
        .format()
        .slice(0, 16),
    },
    end: {
      ...cleanState.end,
      value: moment()
        .format()
        .slice(0, 16),
    },
  };
  componentDidMount() {
    const {currentUser, contact, accountContacts} = this.props;
    const defaultAttendees = [{email: currentUser.email, name: currentUser.displayName}];
    if (contact) defaultAttendees.push({email: contact.Email.Work, name: contact.Name.Full});
    this.setState({
      allAttendees: defaultAttendees,
      accountContacts: accountContacts.map(contact => {
        return {name: contact.Name.Full, emailAddress: contact.Email.Work};
      }),
    });
  }

  handleDelete(name) {
    const indexToDelete = this.state.allAttendees.findIndex(i => i.name === name);
    const attendees = [...this.state.allAttendees];
    attendees.splice(indexToDelete, 1);
    this.setState({allAttendees: attendees});
  };
  handleSubmit() {
    const {subject, location, body, start, end, allAttendees} = this.state;
    if (!subject.value) {
      this.setState({subject: {...subject, error: 'Please include a subject.'}});
      return;
    }
    if (!location.value) {
      this.setState({location: {...location, error: 'Please include a location.'}});
      return;
    }
    if (!body.value) {
      this.setState({body: {...body, error: 'Please include a description.'}});
      return;
    }
    if (start.value === end.value) {
      this.setState({end: {...end, error: 'Start time and End time must be different'}});
      return;
    }
    if (!start.value) {
      this.setState({start: {...start, error: 'Please choose a start time.'}});
      return;
    }
    if (!end.value) {
      this.setState({end: {...end, error: 'Please choose an end time.'}});
      return;
    }
    this.setState({submitting: true}, () => {
      const event = {
        title: subject.value.trim(),
        when: {
          start_time: start.value,
          end_time: end.value,
        },
        description: body.value,
        location: location.value.trim(),
        participants: [...allAttendees],
      };
      Functions.httpsCallable('postEvent')({body: event})
        .then(res => this.setState({submitting: false, stage: 'success'}))
        .catch(err => {
          this.setState({submitting: false});
          alert('There was an error creating the event.');
          console.error(err);
        });
    });
  };
  createAttendeeChips() {
    return this.state.allAttendees.map((attnd, i) => {
      return (
        <Grid item key={i} xs={4}>
          <Chip icon={<FaceIcon />} label={attnd.name} onDelete={() => this.handleDelete(attnd.name)} />
        </Grid>
      );
    });
  };
  handleClose() {
    this.setState({...cleanState}, this.props.close);
  };
  render() {
    const {modalOpen, outlookUsers} = this.props;
    const state = this.state;
    const core = {
      dialog: {
        open: modalOpen,
        scroll: 'body',
        transitionDuration: {exit: 0},
        PaperProps: {style: {maxHeight: '80%', overflowY: 'scroll'}},
      },
      textField: key => {
        return {
          error: state[key].error ? true : false,
          fullWidth: true,
          helperText: state[key].error,
          id: key,
          label: state[key].display,
          margin: 'normal',
          value: state[key].value,
          onChange: e => this.setState({[key]: {display: state[key].display, value: e.target.value}}),
        };
      },
      timeField: key => {
        return {
          error: state[key].error ? true : false,
          fullWidth: true,
          helperText: state[key].error,
          type: 'datetime-local',
          id: key,
          label: state[key].display,
          margin: 'normal',
          value: state[key].value,
          InputLabelProps: {
            shrink: true,
          },
          onChange: e => this.setState({[key]: {display: state[key].display, value: e.target.value}}),
        };
      },
      autoOutlookAttendee: {
        itemKey: 'displayName',
        label: 'Add Outlook Attendee',
        maxListItems: 5,
        minUserInput: 1,
        items: outlookUsers ? outlookUsers : [],
        textFieldProps: {
          fullWidth: true,
          margin: 'normal',
        },
        onItemChange: (value, item) => {
          if (item) {
            this.setState({
              allAttendees: [...state.allAttendees, {email: item.mail, name: item.displayName}],
            });
          }
        },
      },
      autoAccountAttendee: {
        itemKey: 'name',
        label: 'Add Account Attendee',
        maxListItems: 5,
        minUserInput: 1,
        items: state.accountContacts,
        textFieldProps: {
          fullWidth: true,
          margin: 'normal',
        },
        onItemChange: (value, item) => {
          if (item) {
            this.setState({
              allAttendees: [...state.allAttendees, {email: item.emailAddress, name: item.name}],
            });
          }
        },
      },
      submitConformation: {
        stage: this.state.stage,
        text: ['New event successfully created.', 'Please refresh to populate the table.'],
      },
      submitButton: {
        color: 'primary',
        loading: this.state.submitting,
        onClick: () => this.handleSubmit(),
        text: 'Submit',
      },
    };
    return (
      <Dialog {...core.dialog}>
        <DialogTitle>Add New Event</DialogTitle>
        <DialogContent>
          <Collapse in={this.state.stage === 'basic'}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField {...core.textField('subject')} required />
              </Grid>
              <Grid item xs={6}>
                <TextField {...core.textField('location')} required />
              </Grid>
              <Grid item xs={12}>
                <TextField {...core.textField('body')} required />
              </Grid>
              <Grid item xs={6}>
                <TextField {...core.timeField('start')} required />
              </Grid>
              <Grid item xs={6}>
                <TextField {...core.timeField('end')} required />
              </Grid>
              <Grid item xs={6}>
                <KbiAutoText {...core.autoOutlookAttendee} />
              </Grid>
              <Grid item xs={6}>
                <KbiAutoText {...core.autoAccountAttendee} />
              </Grid>
              {this.createAttendeeChips()}
            </Grid>
          </Collapse>
          <SubmitConfirmation {...core.submitConformation} />
        </DialogContent>
        {this.state.stage === 'success' ? (
          <DialogActions style={{justifyContent: 'flex-end'}}>
            <Button onClick={() => this.handleClose()} color='primary'>
              Close
            </Button>
          </DialogActions>
        ) : (
          <DialogActions style={{justifyContent: 'space-between'}}>
            <Button onClick={() => this.handleClose()} color='secondary' disabled={this.state.submitting}>
              Cancel
            </Button>
            <div>
              <SubmitButton {...core.submitButton} />
            </div>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const {outlookUsers, users} = state.firestore;
  const {currentUser} = state.auth;
  return {currentUser, outlookUsers, users};
};

NewEventModal.propTypes = {
  outlookUsers: PropTypes.arrayOf(PropTypes.object),
  modalOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  accountContacts: PropTypes.array,
  contact: PropTypes.object,
  users: PropTypes.array,
};

export default connect(
  mapStateToProps,
)(NewEventModal);
