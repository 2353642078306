import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Collapse,
} from '@material-ui/core';
import {object, string} from 'yup';
import {Formik} from '@kbi/component-library';
import {SubmitConfirmation} from 'components/';
const {FormikForm, TextField, SubmitButton, FormButton} = Formik;

const stageArray = ['basic', 'success'];
const defaultFormik = {
  note: '',
};

const NewReportModal = props => {
  const [stage, setStage] = useState(0);

  const handleSubmit = (values, actions) => {
    props.rejectConfirmation(values.note, () => {
      actions.setSubmitting(false);
      setStage(stage + 1);
    });
  };
  const validationSchema = object().shape({
    note: string().min(10, 'Note must be at least 10 characters long.').required('This field is required for a rejection.'),
  });
  const core = {
    dialog: {
      open: props.open,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
    },
    cancelButton: {
      onClick: props.close,
      variant: 'text',
      color: 'secondary',
    },
    closeButton: {
      onClick: () => {
        props.close();
      },
      color: 'primary',
    },
    submitButton: {
      color: 'primary',
      variant: 'text',
    },
    submitConfirmation: {
      text: 'Profile successfully rejected.',
      stage: stageArray[stage],
    },
  };

  return (
    <Dialog {...core.dialog}>
      <FormikForm validationSchema={validationSchema} onSubmit={handleSubmit} initialValues={{...defaultFormik}}>
        {formikProps => (
          <Fragment>
            {stageArray[stage] !== 'success' && <DialogTitle>Add Reason For Rejection</DialogTitle>}
            <DialogContent >
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>This will get sent to the sales person in order to assist them in correcting the profile.</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField name='note' fast label='Note For Rejection' required />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
            </DialogContent>
            {stageArray[stage] !== 'success' ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton {...core.cancelButton}>Cancel</FormButton>
                <SubmitButton {...core.submitButton}>
                  Submit
                </SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions>
                <Button {...core.closeButton}>Close</Button>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

NewReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  rejectConfirmation: PropTypes.func.isRequired,
};

export default NewReportModal;
