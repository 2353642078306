/* eslint-disable max-len */
import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, LinearProgress} from '@material-ui/core';
import {connect} from 'react-redux';
import {Firestore} from 'config.js';

class ExhibitA extends Component {
  state = {
    filteredMaterials: null,
    unsubscribe: null,
  };
  hazardousWasteCodes = [
    ['Ignitablility', '40 CFR 261.21 (CCR 66261.21) D001'],
    ['Corrosivity', '40 CFR 261.22 (CCR 66261.22) D002'],
    ['Reactivity', ' 40 CFR 261.23 (CCR 66261.23) D003'],
    ['Arsenic', '40 CFR 261.24 (CCR 66261.24) D004'],
    ['Barium', '40 CFR 261.24 (CCR 66261.24) D005'],
    ['Cadmium', '40 CFR 261.24 (CCR 66261.24) D006'],
    ['Chromium', '40 CFR 261.24 (CCR 66261.24) D007'],
    ['Lead', '40 CFR 261.24 (CCR 66261.24) D008'],
    ['Murcury', '40 CFR 261.24 (CCR 66261.24) D009'],
    ['Selenium', '40 CFR 261.24 (CCR 66261.24) D010'],
    ['Silver', '40 CFR 261.24 (CCR 66261.24) D011'],
  ];
  componentDidMount() {
    if (this.props.materials) this.filterMaterials(this.props.materials.inboundList);
  }
  componentDidUpdate(prevProps) {
    if (
      (prevProps.materials !== this.props.materials || prevProps.selectedProfile !== this.props.selectedProfile) &&
      this.props.materials
    ) {
      this.filterMaterials(this.props.materials.inboundList);
    }
  }
  filterMaterials(materialArray) {
    const filteredMaterials = [];
    const promiseArray = [];
    this.props.selectedProfile.Materials.forEach(selectedMaterial => {
      materialArray.forEach(unmatchedMaterial => {
        if (unmatchedMaterial.MaterialId === selectedMaterial.Ref) {
          filteredMaterials.push(unmatchedMaterial);
          unmatchedMaterial.PreferredFlag = selectedMaterial.PreferredFlag;
          unmatchedMaterial.shippingNames = [];
          promiseArray.push(
            Firestore.collection('Tracking-Materials')
              .doc(unmatchedMaterial.MaterialId)
              .collection('ProperShippingNames')
              .get()
              .then(snap => {
                snap.forEach(doc => {
                  const docData = doc.data();
                  unmatchedMaterial.shippingNames.push(docData.ShippingName);
                });
              }),
          );
        }
      });
    });
    Promise.all(promiseArray).then(() => this.setState({filteredMaterials}));
  }
  displayMaterials() {
    const {filteredMaterials} = this.state;
    const {classes, StyledTypography} = this.props;
    const core = {
      sectionHeader: {
        container: true,
        item: true,
        xs: 12,
        className: `${classes.leftPadding} ${classes.titleSection} ${classes.bottomOpenOutline}`,
      },
      newSection: {
        item: true,
        container: true,
        spacing: 1,
        xs: 12,
        className: `newMaterial ${classes.removeHorizontalPadding}`,
      },
      centeredFullLengthGrid: {
        container: true,
        item: true,
        xs: 12,
        className: `${classes.topOpenOutline}`,
      },
      catagorySpacing: {
        style: {
          paddingRight: '5px',
        },
      },
    };
    return filteredMaterials.map((material, index, materialArray) => {
      const typeNameList = [];
      if (this.props.types) {
        this.props.types[material.MaterialId].forEach(type => {
          if (type.ShowOnProfile) typeNameList.push(type.TypeName);
        });
      }
      return (
        <Fragment key={index}>
          <Grid {...core.newSection}>
            <Grid {...core.sectionHeader}>
              <StyledTypography variant='subtitle2' className={classes.titleText}>
                {material.UnitDetails.MaterialName.toUpperCase()}
              </StyledTypography>
            </Grid>
            <Grid {...core.centeredFullLengthGrid}>
              <StyledTypography variant='body2'>
                <span className={classes.bold}>PSN: </span>
                {material.shippingNames.join('; ')}
              </StyledTypography>
            </Grid>
            <Grid {...core.centeredFullLengthGrid}>
              <StyledTypography {...core.catagorySpacing} className={classes.bold} variant='body2'>
                Shipping Regulations:
              </StyledTypography>
              <StyledTypography variant='body2' {...core.catagorySpacing}>
                {material.Regulations.Shipping.join(', ') + ';'}
              </StyledTypography>
              <StyledTypography variant='body2'>
                <span {...core.catagorySpacing} className={classes.bold}>
                  Other Regulations:
                </span>
                {material.Regulations.Other.join(', ')}
              </StyledTypography>
            </Grid>
            <Grid {...core.centeredFullLengthGrid}>
              <StyledTypography {...core.catagorySpacing} variant='body2'>
                <span className={classes.bold}>Approved Catagories: </span>
                {typeNameList.join(', ')}
              </StyledTypography>
            </Grid>
            <Grid {...core.centeredFullLengthGrid}>
              <StyledTypography variant='body2' {...core.catagorySpacing} className={classes.bold}>
                RCRA Waste Codes:
              </StyledTypography>
              <StyledTypography variant='body2' {...core.catagorySpacing}>
                {material.WasteCodes.RCRA.join(', ') + ';'}
              </StyledTypography>
              <StyledTypography variant='body2'>
                <span {...core.catagorySpacing} className={classes.bold}>
                  CA Waste Codes:
                </span>
                {material.WasteCodes.CA.join(', ')}
                <span style={{paddingLeft: '10px'}} className={classes.bold}>
                  Waste codes only apply when manifested
                </span>
              </StyledTypography>
            </Grid>
            {material.PreferredFlag &&
              <Grid {...core.centeredFullLengthGrid}>
                <StyledTypography {...core.catagorySpacing} variant='body2'>
                  <span className={classes.bold}>Preferred Flag: </span>{material.PreferredFlag}
                </StyledTypography>
              </Grid>}
          </Grid>
        </Fragment>
      );
    });
  }
  displayWasteCodes() {
    const {classes, StyledTypography} = this.props;
    const core = {
      newCodeGrid: {
        item: true,
        container: true,
        xs: 4,
        justify: 'space-between',
        className: classes.removeVerticalPadding,
      },
      hazardousName: {
        className: `${classes.bold} smallPrintFont`,
        variant: 'body2',
      },
      hazardousCode: {
        className: 'smallPrintFont',
        variant: 'body2',
      },
    };
    return this.hazardousWasteCodes.map((wasteCode, index) => {
      return (
        <Grid {...core.newCodeGrid} key={index}>
          <StyledTypography {...core.hazardousName}>{wasteCode[0]}</StyledTypography>
          <StyledTypography {...core.hazardousCode}>{wasteCode[1]}</StyledTypography>
        </Grid>
      );
    });
  }
  render() {
    const {filteredMaterials} = this.state;
    const {classes, StyledTypography} = this.props;
    const core = {
      sectionHeader: {
        container: true,
        item: true,
        xs: 12,
        className: classes.titleSection,
      },
      newSection: {
        container: true,
        spacing: 1,
        className: `${classes.avoidPageBreak} ${classes.outlineForm}`,
      },
    };
    if (!filteredMaterials) return <LinearProgress />;
    return (
      <Fragment>
        <StyledTypography variant='h6' className={`${classes.topPadding} ${classes.topMargin}`}>
          EXHIBIT A: APPROVED MATERIALS AND PACKAGING REQUIREMENTS
        </StyledTypography>
        <StyledTypography variant='body2' className={classes.textBox}>
          The tables shown below list batteries approved by KBI for delivery under this Material Profile. Further, the
          tables list current citations set forth in the Title 40 and Title 49 of the Code of Federal Regulations, which
          must be strictly adhered to when transporting batteries.
        </StyledTypography>
        {this.displayMaterials()}
        <Grid {...core.newSection}>
          <Grid {...core.sectionHeader}>
            <StyledTypography variant='subtitle2' className={classes.titleText}>
              BROKEN AND DAMAGED BATTERIES
            </StyledTypography>
          </Grid>
          <Grid className={classes.textBox} item xs={12}>
            <StyledTypography variant='body2'>
              40 CFR 273.9 defines Universal Waste Batteries as whole intact batteries, therefore broken, leaking or damaged batteries, that exhibit a waste characteristic, must be managed as a hazardous waste and shipped on a manifest identifying any and all applicable hazardous waste codes (as show below), unless excluded or excepted by regulation. 49 CFR 172.101(c)(9) (paraphrased) requires the word <span className={classes.underlined}>waste</span> to precede the shipping name when shipping a hazardous waste.
            </StyledTypography>
          </Grid>
          <Grid item xs={4}>
            <StyledTypography variant='body2' className='smallPrintFont'>
              <span className={classes.bold}>Form Code</span>: W309
            </StyledTypography>
          </Grid>
          <Grid item xs={8}>
            <StyledTypography variant='body2' className='smallPrintFont'>
              <span className={classes.bold}>Source Code</span>: G11, G15, G27
            </StyledTypography>
          </Grid>
          <Grid container spacing={2} className={classes.removeMargin}>
            {this.displayWasteCodes()}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {materials: state.firestore.materials, types: state.firestore.types};
};

ExhibitA.propTypes = {
  selectedProfile: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  materials: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
    materialList: PropTypes.arrayOf(PropTypes.object),
    yieldList: PropTypes.arrayOf(PropTypes.object),
    processList: PropTypes.arrayOf(PropTypes.object),
    inboundList: PropTypes.arrayOf(PropTypes.object),
    listener: PropTypes.func,
  }),
  types: PropTypes.shape({
    listener: PropTypes.func.isRequired,
    // any number of properties that are materialId's
  }),
  StyledTypography: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ExhibitA);
