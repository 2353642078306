import React from 'react';
import {SvgIcon} from '@material-ui/core';

const Businessman = props => {
  return (
    <SvgIcon viewBox='0 0 489.4 489.4' {...props}>
      <g>
        <g>
          <path d="M82.9,234.3c3.1,19.9,18.4,45.2,43.7,54.1c10.3,3.6,21.7,3.7,32-0.1c24.9-9,40.7-34.2,43.8-54.1c3.3-0.3,7.7-5,12.5-21.7
      c6.5-22.9-0.4-26.3-6.2-25.7c1.1-3.2,2-6.3,2.5-9.4c9.9-59.3-19.3-61.4-19.3-61.4s-4.8-9.3-17.6-16.4c-8.5-5.1-20.4-9-36.1-7.6
      c-5.1,0.2-9.9,1.2-14.4,2.7l0,0c-5.8,2-11.1,4.8-15.8,8.1c-5.9,3.7-11.4,8.3-16.3,13.5c-7.7,7.9-14.6,18.1-17.6,30.9
      c-2.5,9.5-1.9,19.5,0.1,30.2l0,0c0.6,3.1,1.4,6.3,2.5,9.4c-5.8-0.5-12.7,2.8-6.2,25.7C75.2,229.4,79.6,234.1,82.9,234.3z" />
          <path d="M248.9,315.3c-34.9-13.3-55.9-27-55.9-27l-26.9,85.2l-3.7,11.6l-12.1-34.2c27.7-38.7-2.1-40.5-7.3-40.5l0,0h-0.1h-0.1
      h-0.1h-0.1l0,0c-5.2,0-35,1.9-7.3,40.5l-12.1,34.2l-3.7-11.6l-26.7-85.2c0,0-20.9,13.7-55.9,27C-1.6,329.3,0.8,361,0,420.3h142.8
      h0.3h142.7C285,361,287.4,329.3,248.9,315.3z" />
          <path d="M246.5,287.6c-14-24.7-10.5-56.6,10.6-77.7c17-17,41-22.6,62.7-16.7l25.4-25.4c-33.9-16.3-75.3-11.8-105.2,13.4
      c1.2,2.7,2.2,5.7,2.7,8.8c1.5,8.3,0.5,17.9-3,30.2c-3.1,10.8-6.3,18.6-10.3,24.6c-2.6,3.9-5.4,6.9-8.2,9.1
      c-1.9,6.5-4.4,12.5-7.2,18.1C220.9,275.7,232,281.5,246.5,287.6z" />
          <path d="M391,213.5l-25.4,25.4c5.8,21.6,0.2,45.7-16.7,62.7c-14.7,14.7-34.7,20.8-53.8,18.5c4,6,7.1,12.7,9.4,20.3
      c1.2,4.1,2.2,8.4,3,12.9c23.5-1.1,46.7-10.5,64.6-28.5C402.1,294.7,408.4,249.8,391,213.5z" />
          <path d="M414,190.5c29.1,49.4,22.4,113.9-20,156.3c-23.3,23.3-53.4,35.8-83.9,37.5c0.2,7.6,0.3,15.8,0.4,24.5
      c0,3.6,0.1,7.4,0.1,11.3v0.1c39.5-1.8,78.5-17.8,108.7-48c56.4-56.4,63.3-143.7,20.6-207.6L414,190.5z" />
          <path d="M315.8,224.6c-12.2-5-26.8-2.6-36.7,7.3c-13.2,13.2-13.2,34.5,0,47.6c13.2,13.2,34.5,13.2,47.6,0
      c9.9-9.9,12.3-24.5,7.3-36.7l96.7-96.7l16.1,2.1l42.6-42.6l-32.2-4.3l-4.3-32.2l-42.6,42.7l2.1,16.1L315.8,224.6z" />
          <path d="M241.2,142.8c39.6-21.6,88.1-21,127.1,2l26-26c-49.8-33.2-113.6-36.4-166.1-9.6c0.2,0.3,0.5,0.6,0.7,0.9
      C235.4,118.7,239.5,129.7,241.2,142.8z" />
        </g>
      </g>
    </SvgIcon>);
};

export default Businessman;
