/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {AddFileModal, BackButton} from 'components/';
import {ExhibitA, ExhibitB, PdfFirstPage, RejectProfileModal} from './ProfileToPdf/';
import {MaterialProfileModal} from '../panels/MaterialProfile/';
import {Button, withStyles, Chip, Typography, LinearProgress} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import flowright from 'lodash.flowright';
import {connect} from 'react-redux';
import {Print, Check, CloudUpload, Search, Edit, Cancel} from '@material-ui/icons';
import {acClearAccountProfiles, acGetAccountProfiles} from '../state/firestore/actions.js';
import {Firestore, Storage, Functions} from 'config.js';

const ProfileToPdf = props => {
  const {classes, currentUser, contacts, users, match, accountProfiles, accounts} = props;
  const {acGetAccountProfiles, acClearAccountProfiles} = props;
  const [isApproved, setApproved] = useState('');
  const [hasBeenViewed, setHasBeenViewed] = useState(false);
  const [isUploaded, setUploaded] = useState(false);
  const [addFileModalOpen, setAddFileModalOpen] = useState(false);
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [rejectProfileModalOpen, setRejectProfileModalOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountContacts, setAccountContacts] = useState(null);
  const fileRef = Storage.ref(`Material_Profiles/${match.params.ProfileId}.pdf`);

  useEffect(() => {
    // this useEffect is both the componentDidMount and willUnmount for the redux actions
    acGetAccountProfiles(match.params.AccountId);
    return () => {
      acClearAccountProfiles(accountProfiles);
    };
  }, []);
  useEffect(() => {
    // this is the componentDidUpdate, handling any changes in the props, and adjusting the local state based on what account/profile the user is on
    if (accounts) {
      if (!accounts[match.params.AccountId]) {
        props.history.push('/accounts');
        return;
      }
      setSelectedAccount(accounts[match.params.AccountId]);
    }
    if (accountProfiles) {
      const selectedProfileRef = accountProfiles.profiles.find(profile => profile.id === match.params.ProfileId);
      if (!selectedProfileRef) {
        props.history.push(`/accounts/${match.params.AccountId}`);
        return;
      }
      setSelectedProfile(selectedProfileRef);
      setApproved(selectedProfileRef.ApprovedBy);
      setUploaded(selectedProfileRef.UploadDate);
    }

    if (contacts && selectedAccount) {
      setAccountContacts(contacts.active.filter(contact => contact.RefAccount.Id === selectedAccount.AccountId));
    }
  }, [accountProfiles, accounts, match.params, contacts]);

  if (!selectedProfile || !selectedAccount) {
    // if waiting for redux store to update async, return the loader
    return <LinearProgress />;
  }

  function sendStatusEmail(optionsObj) {
    Functions.httpsCallable('sendMaterialProfileStatusEmail')({
      ...optionsObj,
      accountName: selectedAccount.AccountName,
      accountId: selectedAccount.AccountId,
      profileId: selectedProfile.id,
    });
  }
  function updateProfileApproval() {
    Firestore.collection('CRM-Accounts')
      .doc(selectedAccount.AccountId)
      .collection('Material-Profiles')
      .doc(selectedProfile.id)
      .update({ApprovedBy: currentUser.displayName})
      .then(() => {
        setApproved(true);
        // send an email to the user that created the profile, and the last person who updated the profile
        // (if they are different that creator, and if they exist)
        const emailList = [selectedProfile.System.CreatedBy.Email];
        if (
          selectedProfile.System.UpdatedBy &&
          selectedProfile.System.UpdatedBy.Email !== selectedProfile.System.CreatedBy.Email
        ) {
          emailList.push(selectedProfile.System.UpdatedBy.Email);
        }
        sendStatusEmail({
          message: 'The profile has been approved.',
          listToSendTo: emailList,
        });
      });
  }
  function rejectProfileApproval(messageFromRejecter, callback) {
    // delete the file that had been uploaded so it doesn't fall into a memory leak.
    fileRef
      .delete()
      .then(() => {
        return Firestore.collection('CRM-Accounts')
          .doc(selectedAccount.AccountId)
          .collection('Material-Profiles')
          .doc(selectedProfile.id)
          .update({UploadedBy: '', UploadDate: ''});
      })
      .then(() => {
        callback();
        setHasBeenViewed(false);
        setUploaded(false);
        // send an email to the user that created the profile, and the last person who updated the profile
        // (if they are different that creator, and if they exist)
        const emailList = [selectedProfile.System.CreatedBy.Email];
        if (selectedProfile.UpdatedBy && selectedProfile.UpdatedBy.Email !== selectedProfile.CreatedBy.Email) {
          emailList.push(selectedProfile.UpdatedBy.Email);
        }
        sendStatusEmail({
          message: messageFromRejecter,
          listToSendTo: emailList,
        });
      })
      .catch(error => { });
  }
  function displayApprovalStatus() {
    if (isApproved) {
      // last step in process, if the profile is approved, display an approved chip
      return (
        <Chip className='hidePrint' style={{marginLeft: '8px'}} label='Approved'
          color='primary' variant='outlined'
        />
      );
    }
    else if (
      (props.currentUser.roleCRM === 'Admin' || props.currentUser.roleCRM === 'Manager') &&
      !isApproved &&
      hasBeenViewed
    ) {
      // if the profile is awaiting approval, admins and managers have the option after viewing the pdf to approve or deny
      return (
        <Fragment>
          <Button {...core.approveProfileButton}>
            <Check className={classes.extendedIcon} />
            Approve
          </Button>
          <Button {...core.rejectProfileButton}>
            <Cancel className={classes.extendedIcon} />
            Reject
          </Button>
        </Fragment>
      );
    }
    else if (!isApproved && isUploaded) {
      // after upload, display a waiting approval chip
      return (
        <Chip
          className='hidePrint'
          style={{marginLeft: '8px'}}
          label='Awaiting Approval'
          color='secondary'
          variant='outlined'
        />
      );
    }
    else {
      // first step in process, allow users to create the pdf, and upload signed pdfs to be approved.
      return (
        <Fragment>
          <Button {...core.printButton}>
            <Print className={classes.extendedIcon} />
            Print
          </Button>
          <Button {...core.uploadButton}>
            <CloudUpload className={classes.extendedIcon} />
            Upload
          </Button>
          <Button {...core.editButton}>
            <Edit className={classes.extendedIcon} />
            Edit
          </Button>
        </Fragment>
      );
    }
  }
  const core = {
    openPdfButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      className: 'hidePrint',
      onClick: () => {
        fileRef.getDownloadURL().then(url => {
          window.open(url, '__blank');
          setHasBeenViewed(true);
        });
      },
    },
    uploadButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      className: 'hidePrint',
      onClick: () => {
        setAddFileModalOpen(true);
      },
    },
    editButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      className: 'hidePrint',
      onClick: () => {
        setEditProfileModalOpen(true);
      },
    },
    printButton: {
      variant: 'outlined',
      className: 'hidePrint',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: () => window.print(),
    },
    exhibitAProps: {
      selectedProfile,
      classes,
      StyledTypography,
    },
    exhibitBProps: {
      classes,
      selectedProfile,
      StyledTypography,
    },
    approveProfileButton: {
      variant: 'outlined',
      className: 'hidePrint',
      size: 'small',
      color: 'primary',
      style: {marginLeft: '8px'},
      onClick: () => updateProfileApproval(),
    },
    rejectProfileButton: {
      variant: 'outlined',
      className: 'hidePrint',
      size: 'small',
      color: 'secondary',
      style: {marginLeft: '8px'},
      onClick: () => setRejectProfileModalOpen(true),
    },
    rejectProfileModal: {
      open: rejectProfileModalOpen,
      close: () => setRejectProfileModalOpen(false),
      rejectConfirmation: (values, callback) => rejectProfileApproval(values, callback),
    },
    fileModalProps: {
      selectedProfile,
      currentUser,
      currentAccount: selectedAccount,
      addFileModalOpen,
      successfulUpload: () => {
        setUploaded(true);
        // filters crm users for managers and send them an email about an uploaded profile
        const managerEmails = [];
        users.forEach(user => {
          if (user.roleCRM === 'Manager') managerEmails.push(user.email);
        });
        sendStatusEmail({
          listToSendTo: managerEmails,
          message: 'There has been a profile uploaded.',
        });
      },
      close: () => {
        setAddFileModalOpen(false);
      },
    },
    firstPage: {
      selectedProfile,
      classes,
      StyledTypography,
    },
    editProfileModal: {
      modalOpen: editProfileModalOpen,
      contacts: accountContacts,
      selectedAccount,
      primaryLocation: (() => {
        // DK 1/30/2023 System crashed when no props.locations found
        if (!props.locations) return {};
        return props.locations[selectedAccount.AccountId].find(location => location.PrimaryLocation);
      })(),
      selectedProfile,
      locations: (() => {
        // DK 1/30/2023 System crashed when no props.locations found
        if (!props.locations) return [];
        return props.locations[selectedAccount.AccountId];
      })(),
      close: () => setEditProfileModalOpen(false),
    },
  };

  return (
    <Fragment>
      <div className={`sideScrollingContainer ${classes.topButtonGutter}`}>
        <BackButton />
        {isUploaded && (
          <Button {...core.openPdfButton}>
            <Search className={classes.extendedIcon} />
            View Uploaded File
          </Button>
        )}
        {displayApprovalStatus()}
      </div>
      <PdfFirstPage {...core.firstPage} />
      <div className={classes.pageBreak} />
      <ExhibitA {...core.exhibitAProps} />
      <div className={classes.pageBreak} />
      <ExhibitB {...core.exhibitBProps} />
      {addFileModalOpen && <AddFileModal {...core.fileModalProps} />}
      {editProfileModalOpen && <MaterialProfileModal {...core.editProfileModal} />}
      {rejectProfileModalOpen && <RejectProfileModal {...core.rejectProfileModal} />}
    </Fragment>
  );
};

const styles = theme => ({
  titleSection: {
    backgroundColor: 'red',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  titleText: {
    color: 'white',
    fontWeight: 600,
  },
  outlineForm: {
    marginTop: '15px',
    border: '1px solid black',
  },
  topButtonGutter: {
    marginBottom: '15px',
  },
  textBox: {
    padding: '5px',
  },
  bold: {
    fontWeight: 600,
  },
  required: {
    backgroundColor: 'black',
    color: 'white',
  },
  underlined: {
    textDecoration: 'underline',
  },
  pageBreak: {
    pageBreakBefore: 'always',
    display: 'block',
  },
  avoidPageBreak: {
    pageBreakInside: 'avoid',
  },
  removeTopMargin: {
    marginTop: 0,
  },
  removeBottomMargin: {
    marginBottom: 0,
  },
  topMargin: {
    marginTop: '25px',
  },
  bottomMargin: {
    marginBottom: '25px',
  },
  outline: {
    border: '1px solid black',
  },
  topOutline: {
    borderTop: '1px solid black',
  },
  bottomOutline: {
    borderBottom: '1px solid black',
  },
  background: {
    backgroundColor: 'lightgrey',
  },
  removeVerticalPadding: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  addVerticalPadding: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  topBorder: {
    borderTop: '1px solid black',
  },
  tablePadding: {
    padding: '3px 5px',
  },
  topOpenOutline: {
    borderBottom: '1px solid black',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
  },
  bottomOpenOutline: {
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
  },
  leftPadding: {
    paddingLeft: '10px',
  },
  removeMargin: {
    margin: '0px',
  },
  smallInputFont: {
    'fontSize': '0.80rem',
    '& p': {
      fontSize: '0.80rem',
    },
  },
  removeHorizontalPadding: {
    paddingRight: '0px',
    paddingLeft: '0px',
  },
});
const StyledTypography = withStyles({
  body2: {
    fontSize: '0.8rem',
    lineHeight: '1.20',
  },
  subtitle2: {
    fontSize: '0.8rem',
  },
})(Typography);

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    users: state.firestore.users,
    accountProfiles: state.firestore.accountProfiles,
    accounts: state.firestore.accounts,
    contacts: state.firestore.contacts,
    locations: state.firestore.locations,
  };
};
const mapActionsToProps = {
  acGetAccountProfiles,
  acClearAccountProfiles,
};

ProfileToPdf.propTypes = {
  acGetAccountProfiles: PropTypes.func.isRequired,
  acClearAccountProfiles: PropTypes.func.isRequired,
  accountProfiles: PropTypes.object,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  accounts: PropTypes.object,
  contacts: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    ref: PropTypes.objectOf(PropTypes.object),
    active: PropTypes.arrayOf(PropTypes.object),
  }),
  locations: PropTypes.shape({
    listener: PropTypes.func.isRequired,
    // any number of object with key=accountId, containing an array of locations
  }),
};

export default flowright(
  connect(
    mapStateToProps,
    mapActionsToProps,
  ),
  withStyles(styles, {withTheme: true}),
  withRouter,
)(ProfileToPdf);
