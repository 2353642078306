import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {TableWithPaper} from 'components/';
import {LinearProgress} from '@material-ui/core';
import {Add, Search} from '@material-ui/icons';
import flowRight from 'lodash.flowright';
import {LeadInfoModal} from './Leads/';

class Leads extends Component {
  state = {
    addLeadModalOpen: false,
  };
  handleAddLeadModalClose() {
    this.setState({addLeadModalOpen: false});
  }
  render() {
    const {currentUser, leads, history} = this.props;
    if (!currentUser || !leads) return <LinearProgress />;

    const core = {
      addLead: {
        modalOpen: this.state.addLeadModalOpen,
        close: () => this.handleAddLeadModalClose(),
      },
      tableLeads: {
        columns: [
          {accessor: 'Active', Header: 'Active', type: 'boolean'},
          {accessor: 'Contact.Name', Header: 'Contact Name'},
          {accessor: 'AccountName', Header: 'Account'},
          {accessor: 'Industry', Header: 'Industry'},
          {accessor: 'Manager.Name', Header: 'Account Manager'},
        ],
        sortInitial: [{id: 'Contact.Name', desc: false}],
        data: leads?.active,
        title: {
          primary: 'Leads',
        },
        paperProps: {
          style: {
            margin: '0px',
          },
        },
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'View Lead',
            onClick: ({rowData}) => history.push(`/leads/${rowData.LeadId}`),
          },

        ],
        actionsBar: [
          {
            icon: Add,
            text: 'Add Lead',
            onClick: () => {
              this.setState({addLeadModalOpen: true});
            },
          },
        ],
        paginationSizes: [10, 25, 50, 100],
        paginationInitialSize: 10,
      },
    };

    const openAddLeadModal = () => {
      if (this.state.addLeadModalOpen) {
        return <LeadInfoModal {...core.addLead} />;
      }
      else return null;
    };

    return (
      <Fragment>
        <TableWithPaper {...core.tableLeads} />
        {openAddLeadModal()}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {leads, users} = state.firestore;
  return {leads, currentUser: state.auth.currentUser, users};
};
Leads.propTypes = {
  currentUser: PropTypes.object.isRequired,
  leads: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object.isRequired,
};
export default flowRight(
  connect(mapStateToProps),
  withRouter,
)(Leads);
