import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Grid, Box} from '@material-ui/core';

const ExhibitB = props => {
  const {classes, selectedProfile, StyledTypography} = props;
  const core = {
    column: {
      style: {
        display: 'inline-block',
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
      },
    },
    firstColumn: {
      style: {
        display: 'inline-block',
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
        borderLeft: '1px solid black',
      },
    },
  };
  function createGeneratorList() {
    return selectedProfile.locations.map((location, index) => {
      return (
        <Grid container key={index} className={classes.avoidPageBreak}>
          <Box {...core.firstColumn} width={1 / 20}>
            <StyledTypography variant='body2' className={classes.tablePadding}>
              {index + 1}
            </StyledTypography>
          </Box>
          <Box {...core.column} width={5 / 20}>
            <StyledTypography variant='body2' className={classes.tablePadding}>
              {location.LocationName || location.AccountName}
            </StyledTypography>
          </Box>
          <Box {...core.column} width={7 / 20}>
            <StyledTypography variant='body2' className={classes.tablePadding}>
              {location.Address.SingleLine}
            </StyledTypography>
          </Box>
          <Box {...core.column} width={3 / 20}>
            <StyledTypography variant='body2' className={classes.tablePadding}>
              {location.EpaId}
            </StyledTypography>
          </Box>
          <Box {...core.column} width={2 / 20}>
            <StyledTypography variant='body2' className={classes.tablePadding}>
              {location.NaicsId}
            </StyledTypography>
          </Box>
          <Box {...core.column} width={2 / 20}>
            <StyledTypography variant='body2' className={classes.tablePadding}>
              {location.SicId}
            </StyledTypography>
          </Box>
        </Grid>
      );
    });
  }
  return (
    <Fragment>
      <StyledTypography variant='h6' className={classes.topMargin}>
        EXHIBIT B: GENERATOR LIST
      </StyledTypography>
      <StyledTypography variant='body2' className={classes.textBox}>
        By shipping under this Material Profile, Customer certifies the below list contains all generators, currently
        known by the Customer, that may ship in materials to KBI under their account. In addition, in the future and upon Customer’s written
        consent, KBI is authorized to add additional generators to this Exhibit B.
      </StyledTypography>
      <Grid container className={`${classes.topBorder} ${classes.titleSection}`}>
        <Box {...core.firstColumn} width={1 / 20} />
        <Box {...core.column} width={5 / 20}>
          <StyledTypography variant='body2' className={`${classes.tablePadding} ${classes.titleText}`}>
            GENERATOR NAME
          </StyledTypography>
        </Box>
        <Box {...core.column} width={7 / 20}>
          <StyledTypography variant='body2' className={`${classes.tablePadding} ${classes.titleText}`}>
            ADDRESS
          </StyledTypography>
        </Box>
        <Box {...core.column} width={3 / 20}>
          <StyledTypography variant='body2' className={`${classes.tablePadding} ${classes.titleText}`}>
            EPA ID
          </StyledTypography>
        </Box>
        <Box {...core.column} width={2 / 20}>
          <StyledTypography variant='body2' className={`${classes.tablePadding} ${classes.titleText}`}>
            NAICS
          </StyledTypography>
        </Box>
        <Box {...core.column} width={2 / 20}>
          <StyledTypography variant='body2' className={`${classes.tablePadding} ${classes.titleText}`}>
            SIC
          </StyledTypography>
        </Box>
      </Grid>
      {createGeneratorList()}
    </Fragment>
  );
};

ExhibitB.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedProfile: PropTypes.object.isRequired,
  StyledTypography: PropTypes.object.isRequired,
};

export default ExhibitB;
