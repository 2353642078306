/* eslint-disable no-console */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionSummary, AccordionDetails, Grid, Button, Typography, LinearProgress} from '@material-ui/core';
import {Table} from '@kbi/component-library';
import {ExpandMore, Search} from '@material-ui/icons';
import {EmailDisplayModal} from './EmailPanel/';
import {connect} from 'react-redux';
import moment from 'moment';
import {Functions} from 'config.js';

class EmailPanel extends Component {
  state = {
    modalOpen: false,
    expanded: false,
    selectedThread: null,
    threadTitle: '',
    threads: null,
    emailSkipCount: null,
  };
  componentDidMount() {
    if (this.props.threads) this.setState({threads: this.props.threads});
  }

  getColumnData(threads) {
    const data = threads.map(thread => ({
      from: thread.from,
      subject: thread.subject,
      preview: thread.snippet,
      receivedOn: moment.unix(thread.first_message_timestamp).format('YYYY-MM-DD'),
      id: thread.id,
      messageIds: thread.messages.map(msg => msg.id),
      userToken: thread.userToken,
    }));
    return data;
  }

  render() {
    const {classes, threads, loadingEmails, users} = this.props;
    const {modalOpen, selectedThread, threadTitle, expanded} = this.state;
    const core = {
      emailTable: {
        columns: [
          {accessor: 'from', Header: 'From'},
          {accessor: 'subject', Header: 'Subject'},
          {accessor: 'preview', Header: 'Preview'},
          {accessor: 'receivedOn', Header: 'First Message', type: 'datetime'},
        ],
        sortinitial: [{id: 'receivedOn', desc: true}],
        data: threads ? this.getColumnData(threads) : [],
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'See Full Email',
            onClick: ( {rowData}) => {
              this.setState({modalOpen: true, selectedThread: undefined});
              Functions.httpsCallable('getSpecificEmailThread')({
                accessToken: rowData.userToken,
                threadId: rowData.id,
              })
                .then(res => {
                  this.setState({selectedThread: res.data, threadTitle: rowData.subject});
                })
                .catch(err => console.error('EMAIL Thread ERROR: ', err));
            },
          },
        ],
        options: {
          showTitle: false,
        },
      },
      emailDisplay: {
        modalOpen: modalOpen,
        close: () => this.setState({modalOpen: false}),
        emails: selectedThread,
        title: threadTitle,
        users,
      },
      emptyEmailsButton: {
        href: `mailto:${this.props.contactAddress}`,
        variant: 'outlined',
        onClick: (e) => e.stopPropagation(),
      },
    };
    const details = () => {
      if (loadingEmails) {
        return <LinearProgress />;
      }
      else if (threads.length === 0) {
        return (
          <AccordionDetails>
            <Grid container justify="space-between">
              <Typography variant="subtitle1">No emails found for this contact.</Typography>
            </Grid>
          </AccordionDetails>
        );
      }
      else {
        return (
          <AccordionDetails style={{padding: '0px'}}>
            <Grid container>
              <Grid item xs={12}>
                <Table {...core.emailTable} />
              </Grid>
            </Grid>
          </AccordionDetails>
        );
      }
    };
    return (
      <Accordion expanded={expanded} onChange={() => this.setState({expanded: !expanded})}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container justify='space-between'>
            <div>
              <Typography className={classes.heading}>Emails</Typography>
            </div>
            {expanded ? (
              <div>
                <Button {...core.emptyEmailsButton}>Send Email</Button>
              </div>) : null}
          </Grid>
        </AccordionSummary>
        {details()}
        {loadingEmails && <LinearProgress />}
        {modalOpen ? <EmailDisplayModal {...core.emailDisplay} /> : null}
      </Accordion>
    );
  }
}

const mapStateToProps = state => {
  return {currentUser: state.auth.currentUser};
};

EmailPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  threads: PropTypes.array,
  contactAddress: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  loadingEmails: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.object),
};
export default connect(mapStateToProps)(EmailPanel);
