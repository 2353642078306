import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Formik, Alert} from '@kbi/component-library';
import {SubmitConfirmation} from 'components/';
import {Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Grid, Button, LinearProgress} from '@material-ui/core';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {Firestore} from 'config.js';
import moment from 'moment';

const {FormikForm, SubmitButton, FormButton, TextField, AutoCompleteObject, DateField, validateAutoObject} = Formik;
validateAutoObject();

const NoteModal = props => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const {users} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);

  const dialogProps = {
    open: true,
    scroll: 'body',
    transitionDuration: {exit: 0},
    fullWidth: true,
    maxWidth: 'md',
  };
  const submitConfirmation = {
    text: props.selectedNote ? 'Note successfully updated.' : 'Note successfully added.',
    stage: stage === 1 ? 'success' : 'not success',
  };
  const formikProps = {
    initialValues: {
      Note: props.selectedNote?.Note || '',
      Owner: props.selectedNote ? users.find(user => user.uid === props.selectedNote.Owner.Uid) : users.find(user => user.uid === currentUser.uid),
      Contact: props.selectableContacts.length === 1 ? props.selectableContacts[0] : '',
      Date: props.selectedNote ? moment(props.selectedNote.Date).format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm'),
    },
    validationSchema: yup.object().shape({
      Note: yup.string().required(),
      Owner: yup.object(),
      Contact: yup.mixed().when('Note', {
        is: val => props.firestoreCollection === 'CRM-Accounts',
        then: yup.object(),
        otherwise: yup.object().nullable().exists('Contact is a required field.'),
      }),
      Date: yup.date(),
    }),
    onSubmit: (values, actions) => {
      const noteData = {
        Contact: {
          Id: props.firestoreCollection === 'CRM-Leads' ? values.Contact.LeadId : values.Contact?.ContactId || '',
          Name: props.firestoreCollection === 'CRM-Leads' ? values.Contact.Contact.Name : values.Contact?.Name?.Full || '',
        },
        Date: moment(values.Date).toDate(),
        Note: values.Note,
        Owner: {
          Name: values.Owner.displayName,
          Uid: values.Owner.uid,
        },
      };
      const batchWrite = Firestore.batch();
      if (props.leadRef) {
        let leadRef = Firestore.collection('CRM-Leads').doc(props.leadRef).collection('Notes');
        leadRef = props.selectedNote ? leadRef.doc(props.selectedNote.id) : leadRef.doc();
        batchWrite.set(leadRef, noteData, {merge: true});
      }
      else {
        let accountRef = Firestore.collection('CRM-Accounts').doc(props.accountRef).collection('Notes');
        accountRef = props.selectedNote ? accountRef.doc(props.selectedNote.id) : accountRef.doc();
        batchWrite.set(accountRef, noteData, {merge: true});
        if (noteData.Contact.Id) {
          batchWrite.set(Firestore.collection('CRM-Contacts').doc(noteData.Contact.Id).collection('Notes').doc(accountRef.id), noteData, {merge: true});
        }
      }

      batchWrite.commit().then(() => {
        actions.setSubmitting(false);
        setFormError('');
        setStage(stage + 1);
      }).catch(err => {
        actions.setSubmitting(false);
        setFormError('There was an error during submission. Please try again.');
      });
    },
  };
  const contactField = {
    name: 'Contact',
    required: props.firestoreCollection === 'CRM-Accounts' ? false : true,
    disabled: props.firestoreCollection !== 'CRM-Accounts',
    options: props.selectableContacts,
    optionKey: props.firestoreCollection === 'CRM-Leads' ? 'Contact.Name' : 'Name.Full',
  };
  const ownerField = {
    name: 'Owner',
    required: true,
    disabled: true,
    options: users,
    optionKey: 'displayName',
  };

  if (!users) return <LinearProgress />;

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formikProps}>
        {({values}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>{props.selectedNote ? 'Edit' : 'Add New'} Note</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>Enter note information:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <AutoCompleteObject {...ownerField} />
                  </Grid>
                  <Grid item xs={4}>
                    <DateField name='Date' type='datetime-local' disabled />
                  </Grid>
                  <Grid item xs={4}>
                    <AutoCompleteObject {...contactField} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name='Note' multiline required disabled={currentUser.uid !== values.Owner.uid} />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} />
            </DialogContent>
            {stage === 1 ? (
              <DialogActions>
                <Button variant='text' onClick={props.close} color='primary'>Close</Button>
              </DialogActions>
            ) : (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={props.close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

NoteModal.propTypes = {
  close: PropTypes.func.isRequired,
  leadRef: PropTypes.string,
  accountRef: PropTypes.string,
  selectedNote: PropTypes.object,
  selectableContacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  firestoreCollection: PropTypes.string.isRequired,
};

export default NoteModal;
