import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Collapse, Formik, Alert} from '@kbi/component-library';
import {formatSingleLineAddress, formatDoubleLineAddress, formatTripleLineAddress} from '@kbi/utility-library';
import {SubmitConfirmation} from 'components/';
import {Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Grid, Button, MenuItem} from '@material-ui/core';
import {AddressForm} from 'components/';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {countries, industries} from 'views/Accounts/lists.js';
import {Firestore} from 'config.js';

const {FormikForm, SubmitButton, FormButton, TextField, AutoComplete, AutoCompleteObject,
  PhoneNumberField, validatePhoneField, SelectField, validateAutoObject} = Formik;
validatePhoneField();
validateAutoObject();

const ConvetFromLeadModal = ({close, selectedLead}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const [newAccountId, setNewAccountId] = useState(null);
  const [notes, setNotes] = useState(null);
  const history = useHistory();

  const currentUser = useSelector(state => state.auth.currentUser);
  const users = useSelector(state => state.firestore.users);

  useEffect(() => {
    Firestore.collection('CRM-Leads')
      .doc(selectedLead.LeadId)
      .collection('Notes')
      .get().then(snap => {
        const notes = [];
        if (!snap.empty) {
          snap.forEach(doc => {
            notes.push({...doc.data(), Date: doc.data().Date.toDate(), id: doc.id});
          });
        }
        setNotes(notes);
      });
    // eslint-disable-next-line
  }, []);

  const handleBack = () => {
    return setStage(stage - 1);
  };
  const dialogProps = {
    open: true,
    scroll: 'body',
    transitionDuration: {exit: 0},
    fullWidth: true,
  };
  const submitConfirmation = {
    text: 'Lead successfully converted. You will be redirected to the new account when you close this modal.',
    stage: stage === 6 ? 'success' : 'not success',
  };
  const formikProps = {
    initialValues: {
      accountName: selectedLead.AccountName || '',
      typeTransport: '',
      typeDownstream: '',
      typeCustomer: '',
      epaId: '',
      sicId: '',
      naicsId: '',

      addressCountry: selectedLead.Address.Country || '',
      addressLine1: selectedLead.Address.Line1 || '',
      addressLine2: selectedLead.Address.Line2 || '',
      addressCity: selectedLead.Address.City || '',
      addressState: selectedLead.Address.State || '',
      addressPostal: selectedLead.Address.PostalCode || '',
      addressProvince: selectedLead.Address.Province || '',
      addressPrefecture: selectedLead.Address.Prefecture || '',
      addressUnformatted: selectedLead.Address.SingleLine || '',

      accountPhonePrimary: selectedLead.Phone.Primary,
      accountPhoneEmergency: selectedLead.Phone.Emergency,
      accountPhoneFax: selectedLead.Phone.Fax,
      website: selectedLead.Website || '',

      accountManager: users.find(user => user.uid === selectedLead.Manager.Uid),
      industry: selectedLead.Industry || '',

      firstName: selectedLead.Contact.Name.split(' ')[0],
      middleInitial: '',
      lastName: selectedLead.Contact.Name.split(' ')[1] || '',
      emailHome: '',
      emailWork: selectedLead.Contact.Email,

      contactPhoneHome: '',
      contactPhoneOffice: '',
      contactPhoneFax: selectedLead.Phone.Fax || '',
      contactPhoneMobile: selectedLead.Phone.Mobile || '',
    },
    validationSchema: (() => {
      if (stage === 0) {
        return yup.object().shape({
          accountName: yup.string().required('Account Name is a required field.'),
          typeTransport: yup.bool().required('Transporter is a required field.'),
          typeCustomer: yup.bool().required('Customer is a required field.'),
          typeDownstream: yup.bool().required('Downstream is a required field.'),
          epaId: yup.string().required('EPA Id is a required field. (N/A is accepted).').test({
            name: 'format epaid',
            test: value => {
              if (value) {
                if (value === 'N/A') return true;
                else if (epaIdRegex.test(value)) return true;
                else return false;
              }
              else return true;
            },
            message: 'EPA Id is not formatted correctly.',
          }),
        });
      }
      else if (stage === 1) {
        return yup.object().shape({
          addressCountry: yup.string().required('Country is a required field.'),
          addressLine1: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico' || value === 'Japan' || value === 'South Korea' || value === 'Canada',
            then: yup.string().required('Address Line 1 is a required field.'),
            otherwise: yup.string(),
          }),
          addressLine2: yup.string(),
          addressCity: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico' || value === 'South Korea' || value === 'Canada',
            then: yup.string().required('City is a required field.'),
            otherwise: yup.string(),
          }),
          addressState: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico' || value === 'Canada',
            then: yup.string().required('State is a required field.'),
            otherwise: yup.string(),
          }),
          addressPostal: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico' || value === 'Canada',
            then: yup.string().required('Postal is a required field.'),
            otherwise: yup.string(),
          }),
          addressProvince: yup.string().when('addressCountry', {
            is: value => value === 'Japan' || value === 'South Korea' || value === 'Canada',
            then: yup.string().required('Province is a required field.'),
            otherwise: yup.string(),
          }),
          addressSubdivision: yup.string().when('addressCountry', {
            is: value => value === 'South Korea',
            then: yup.string().required('Subdivision is a required field.'),
            otherwise: yup.string(),
          }),
          addressPrefecture: yup.string().when('addressCountry', {
            is: value => value === 'Japan',
            then: yup.string().required('Prefecture is a required field.'),
            otherwise: yup.string(),
          }),
          addressUnformatted: yup.string().when('addressCountry', {
            is: value => value !== 'United States' && value !== 'Mexico' && value !== 'Japan' && value !== 'South Korea' && value !== 'Canada',
            then: yup.string().required('Address is a required field.'),
            otherwise: yup.string(),
          }),
        });
      }
      else if (stage === 2) {
        return yup.object().shape({
          accountPhonePrimary: yup.string().validPhoneNumber('Must be a valid phone number.'),
          accountPhoneEmergency: yup.string().validPhoneNumber('Must be a valid phone number.'),
          accountPhoneFax: yup.string().validPhoneNumber('Must be a valid phone number.'),
          website: yup.string().matches(urlRegex, 'Must be a valid web address.'),

        });
      }
      else if (stage === 3) {
        return yup.object().shape({
          accountManager: yup.object().nullable().exists('Manager is a required field.'),
          industry: yup.string().required('Industry is a required field.'),
        });
      }
      else if (stage === 4) {
        return yup.object().shape({
          firstName: yup.string().required('First Name is a required field.'),
          lastName: yup.string().required('Last Name is a required field.'),
          middleInitial: yup.string().max(1, 'MI can only be one character.'),
          emailHome: yup.string().email('Must be a valid email address.'),
          emailWork: yup.string().email('Must be a valid email address.'),
        });
      }
      else if (stage === 5) {
        return yup.object().shape({
          contactPhoneHome: yup.string().validPhoneNumber('Must be a valid phone number.'),
          contactPhoneOffice: yup.string().validPhoneNumber('Must be a valid phone number.'),
          contactPhoneFax: yup.string().validPhoneNumber('Must be a valid phone number.'),
          contactPhoneMobile: yup.string().validPhoneNumber('Must be a valid phone number.'),
        });
      }
    })(),
    onSubmit: (values, actions) => {
      if (stage !== 5) {
        setStage(stage + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
        return;
      }
      const submitData = createDataForFirestore(values, currentUser);
      const newAccountRef = Firestore.collection('CRM-Accounts').doc();
      const newContactRef = Firestore.collection('CRM-Contacts').doc();
      const newLocationRef = newAccountRef.collection('Locations').doc();
      const selectedLeadRef = Firestore.collection('CRM-Leads').doc(selectedLead.LeadId);
      const firestoreBatch = Firestore.batch();

      firestoreBatch.set(newAccountRef, submitData.newAccountData);
      firestoreBatch.set(newLocationRef, {...submitData.newLocationData, AccountRef: newAccountRef.id});
      firestoreBatch.set(newContactRef, {
        ...submitData.newContactData,
        RefAccount: {
          Id: newAccountRef.id,
          Name: submitData.newAccountData.AccountName,
        },
      });
      firestoreBatch.update(selectedLeadRef, {Active: false, Converted: true});

      for (let notesIndex = 0; notesIndex < notes.length; notesIndex++) {
        const newContactNoteRef = Firestore.collection('CRM-Contacts')
          .doc(newContactRef.id)
          .collection('Notes')
          .doc();
        firestoreBatch.set(newContactNoteRef, {
          ...notes[notesIndex],
          Contact: {Id: newContactRef.id, Name: notes[notesIndex].Contact.Name},
        });
        const newAccountNoteRef = Firestore.collection('CRM-Accounts')
          .doc(newAccountRef.id)
          .collection('Notes')
          .doc(newContactNoteRef.id);
        firestoreBatch.set(newAccountNoteRef, {
          ...notes[notesIndex],
          Contact: {Id: newContactRef.id, Name: notes[notesIndex].Contact.Name},
        });
      }

      firestoreBatch
        .commit()
        .then(() => {
          actions.setSubmitting(false);
          setStage(stage + 1);
          setNewAccountId(newAccountRef.id);
          setFormError('');
        })
        .catch(error => {
          setFormError('An error occured during submission. Please try again.');
          actions.setSubmitting(false);
        });
    },
  };
  const countryField = {
    name: 'addressCountry',
    label: 'Country',
    options: countries,
    optionKey: 'value',
    required: true,
    onChange: ({form}) => {
      form.setFieldValue('addressLine1', '');
      form.setFieldValue('addressLine2', '');
      form.setFieldValue('addressCity', '');
      form.setFieldValue('addressState', '');
      form.setFieldValue('addressPostal', '');
      form.setFieldValue('addressProvince', '');
      form.setFieldValue('addressSubdivision', '');
      form.setFieldValue('addressPrefecture', '');
      form.setFieldValue('addressUnformatted', '');
    },
  };
  const accountManagerField = {
    name: 'accountManager',
    label: 'Account Manager',
    options: users,
    optionKey: 'displayName',
    required: true,
  };

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formikProps}>
        {() => (
          <Fragment>
            {stage !== 6 && <DialogTitle>Convert Lead</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>Enter account&#39;s name and classification:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField name='accountName' label='Account Name' required fast />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectField name='typeCustomer' label='Customer?' required fast>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={4}>
                    <SelectField name='typeDownstream' label='Downstream?' required fast>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={4}>
                    <SelectField name='typeTransport' label='Transport?' required fast>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField name='epaId' label='EPA Id' required fast />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField name='sicId' label='SIC Id' fast />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField name='naicsId' label='NAICS Id' fast />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 1}>
                <DialogContentText>Enter account&#39;s primary place of business:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AutoComplete {...countryField} />
                  </Grid>
                  <AddressForm />
                </Grid>
              </Collapse>
              <Collapse in={stage === 2}>
                <DialogContentText>Enter account&#39;s relevant contact information:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <PhoneNumberField name='accountPhonePrimary' label='Primary No.' />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneNumberField name='accountPhoneFax' label='Fax No.' />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneNumberField name='accountPhoneEmergency' label='Emergency No.' />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField name='website' label='Website' placeholder={'http://www.domain.com/'} />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 3}>
                <DialogContentText>Enter account management information:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <AutoCompleteObject {...accountManagerField} fast />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectField name='industry' label='Industry' required fast>
                      {industries.map(industry => (
                        <MenuItem key={industry} value={industry}>
                          {industry}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 4}>
                <DialogContentText>Enter contact&#39;s name and email:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <TextField name='firstName' label='First Name' required fast />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField name='middleInitial' label='MI' fast />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField name='lastName' label='Last Name' required fast />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField name='emailWork' label='Work Email' fast />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField name='emailHome' label='Home Email' fast />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 5}>
                <DialogContentText>Enter contact&#39;s phone numbers:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <PhoneNumberField name='contactPhoneOffice' label='Office No.' />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneNumberField name='contactPhoneHome' label='Home No.' />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneNumberField name='contactPhoneMobile' label='Mobile No.' />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneNumberField name='contactPhoneFax' label='Fax No.' />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} />
            </DialogContent>
            {stage === 6 ? (
              <DialogActions>
                <Button variant='text' onClick={() => history.push(`/accounts/${newAccountId}`)} color='primary'>Redirect</Button>
              </DialogActions>
            ) : (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <div>
                  <FormButton variant='text' color='primary' disabled={stage === 0} onClick={handleBack}>Back</FormButton>
                  <SubmitButton variant='text' color='primary'>{stage === 5 ? 'Submit' : 'Next'}</SubmitButton>
                </div>
              </DialogActions>
            )}

          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

function createDataForFirestore(values, currentUser) {
  const submitData = {};
  const {
    addressLine1,
    addressLine2,
    addressCity,
    addressCountry,
    addressPostal,
    addressState,
    website,
    addressSubdivision,
    addressPrefecture,
    addressProvince,
    addressUnformatted,
  } = values;
  const {
    typeCustomer,
    typeDownstream,
    typeTransport,
    accountName,
    accountPhoneEmergency,
    accountPhoneFax,
    accountPhonePrimary,
    sicId,
    epaId,
    naicsId,
    accountManager,
    industry,
  } = values;
  const {
    contactPhoneFax,
    contactPhoneHome,
    contactPhoneMobile,
    contactPhoneOffice,
    firstName,
    lastName,
    middleInitial,
    emailHome,
    emailWork,
  } = values;
  submitData.newAccountData = {
    AccountName: accountName,
    AccountType: {
      Customer: typeCustomer,
      Downstream: typeDownstream,
      Transporter: typeTransport,
    },
    Manager: {
      Name: accountManager.displayName,
      Email: accountManager.email,
      Uid: accountManager.uid,
    },
    System: {
      CreatedOn: new Date(),
      CreatedBy: currentUser.displayName,
      ModifiedOn: new Date(),
      ModifiedBy: currentUser.displayName,
    },
    Active: true,
    Industry: industry,
    Website: website,
  };
  submitData.newContactData = {
    Active: true,
    Name: {
      First: firstName,
      Middle: middleInitial,
      Last: lastName,
      Full: `${firstName} ${middleInitial} ${lastName}`,
    },
    Phone: {
      Office: contactPhoneOffice,
      Home: contactPhoneHome,
      Mobile: contactPhoneMobile,
      Fax: contactPhoneFax,
    },
    NylasLinks: {},
    Email: {
      Home: emailHome,
      Work: emailWork,
    },
    System: {
      CreatedBy: currentUser.displayName,
      CreatedOn: new Date(),
      ModifiedBy: currentUser.displayName,
      ModifiedOn: new Date(),
    },
  };
  submitData.newLocationData = {
    System: {
      CreatedBy: currentUser.displayName,
      CreatedOn: new Date(),
      ModifiedBy: currentUser.displayName,
      ModifiedOn: new Date(),
    },
    Phone: {
      Primary: accountPhonePrimary,
      Fax: accountPhoneFax,
      Emergency: accountPhoneEmergency,
    },
    LocationName: accountName,
    EpaId: epaId,
    SicId: sicId,
    NaicsId: naicsId,
    Active: true,
    FirstOrThirdParty: 'First',
    PrimaryLocation: true,
  };
  if (addressCountry === 'United States' || addressCountry === 'Mexico') {
    submitData.newLocationData.Address = {
      Line1: addressLine1,
      Line2: addressLine2,
      City: addressCity,
      State: addressState,
      PostalCode: addressPostal,
      Country: addressCountry,
    };
  }
  else if (addressCountry === 'Japan') {
    submitData.newLocationData.Address = {
      Line1: addressLine1,
      Line2: addressLine2,
      Prefecture: addressPrefecture,
      Province: addressProvince,
      Country: addressCountry,
    };
  }
  else if (addressCountry === 'South Korea') {
    submitData.newLocationData.Address = {
      Line1: addressLine1,
      Line2: addressLine2,
      City: addressCity,
      Subdivision: addressSubdivision,
      Province: addressProvince,
      Country: addressCountry,
    };
  }
  else if (addressCountry === 'Canada') {
    submitData.newLocationData.Address = {
      Line1: addressLine1,
      Line2: addressLine2,
      City: addressCity,
      Province: addressProvince,
      PostalCode: addressPostal,
      Country: addressCountry,
    };
  }
  else {
    submitData.newLocationData.Address = {
      Unformatted: addressUnformatted,
      Country: addressCountry,
    };
  }
  submitData.newLocationData.Address.SingleLine = formatSingleLineAddress(submitData.newLocationData.Address);
  submitData.newLocationData.Address.DoubleLine = formatDoubleLineAddress(submitData.newLocationData.Address);
  submitData.newLocationData.Address.TripleLine = formatTripleLineAddress(submitData.newLocationData.Address);
  return submitData;
}

// eslint-disable-next-line max-len
const urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const epaIdRegex = /^[a-zA-Z]{2}[a-zA-Z0-9]{1}\d{9}$/;

ConvetFromLeadModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedLead: PropTypes.object.isRequired,
};

export default ConvetFromLeadModal;
