import React, {Fragment, useMemo, useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {SubmitConfirmation, DefaultModalButtons} from 'components/';
import {Collapse, Alert, Formik, Table} from '@kbi/component-library';
import {CheckboxForm} from './PurchaseOrderModal/';
import * as yup from 'yup';
import {Add, DeleteForever, Edit} from '@material-ui/icons';
import {Firestore} from 'config.js';
const {FormikForm, DateField, TextField, SelectField, NumberField, FormButton, WeightField, AutoCompleteObject, validateAutoObject} = Formik;
validateAutoObject();

/* eslint-disable no-invalid-this */
yup.addMethod(yup.mixed, 'doesNotExist', function(message) {
  return this.test('empty item', message, function(value) {
    if (value) {
      return this.createError({
        path: `${this.path}`,
        message,
      });
    }
    else return true;
  });
});
yup.addMethod(yup.string, 'priceCheck', function() {
  return this.test('price check', null, function(value) {
    if (this.parent.billType === 'Charge' || this.parent.billType === 'Payment') {
      if (isNaN(parseFloat(value)) || parseFloat(value) <= 0) {
        return this.createError({
          path: `${this.path}`,
          message: 'Price must be greater than 0.',
        });
      }
    }
    return true;
  });
});
/* eslint-enable no-invalid-this */

const stageArray = ['basic', 'pricing details', 'supplemental materials', 'success'];

const PurchaseOrderModal = props => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const [arrayWarning, setArrayWarning] = useState('');
  const currentUser = useSelector(state => state.auth.currentUser);
  const {locations, materials, types} = useSelector(state => state.firestore);
  const [approvedTypes, setApprovedTypes] = useState(props.selectedPurchaseOrder ?
    formatApprovedTypesFromFirestoreToState(props.selectedPurchaseOrder.ApprovedTypes, {materials, types}) : [],
  );

  const approvedMaterials = useMemo(() => {
    const approvedArray = [];
    props.activeMaterialProfile.Materials.forEach(material => {
      if (materials.ref[material.Ref]) {
        approvedArray.push(materials.ref[material.Ref]);
      }
    });
    return approvedArray;
  }, [materials, props.activeMaterialProfile]);
  const typesForCheckboxes = useMemo(() => {
    return approvedMaterials.map(material => {
      return types[material.MaterialId].map(type => ({
        value: type.id,
        label: type.TypeName,
        materialName: material.UnitDetails.MaterialName,
      }));
    });
  }, [approvedMaterials, types]);
  const approvedContacts = [{Name: {Full: 'Accounts Payable'}}, ...props.contacts];

  const createFirestoreData = (values) => {
    const firestoreObj = {
      AccountId: props.selectedAccount.AccountId,
      Active: values.active,
      AdditionalComments: values.additionalComments,
      ApprovedTypes: formatApprovedTypesForFirestore(approvedTypes),
      BillingLocation: {
        Address: values.location.Address,
        IdRef: values.location.id,
        LocationName: values.location.LocationName,
      },
      ContactName: values.contact.Name.Full,
      EffectiveDate: moment(values.effectiveDate).toDate(),
      ExpirationDate: moment(values.expirationDate).toDate(),
      FOB: values.fob,
      LoadLimit: values.loadLimit,
      POType: values.poType,
      SupplementalPricing: createMapOfSupplementalPrices(values.supplementalPricing, types),
      Terms: values.terms,
      TermsAndConditions: values.termsAndConditions,
    };
    return firestoreObj;
  };

  const formik = {
    initialValues: {
      location: props.selectedPurchaseOrder ?
        locations[props.selectedAccount.AccountId].find(location => location.id === props.selectedPurchaseOrder.BillingLocation.IdRef) : '',
      contact: props.selectedPurchaseOrder ? approvedContacts.find(contact => contact.Name.Full === props.selectedPurchaseOrder.ContactName) : '',
      active: props.selectedPurchaseOrder && !props.duplicatePO ? props.selectedPurchaseOrder.Active : true,
      termsAndConditions: props.selectedPurchaseOrder ? props.selectedPurchaseOrder.TermsAndConditions : true,
      terms: props.selectedPurchaseOrder ? props.selectedPurchaseOrder.Terms : '',
      fob: props.selectedPurchaseOrder ? props.selectedPurchaseOrder.FOB : '',
      effectiveDate: props.selectedPurchaseOrder && !props.duplicatePO ?
        moment(props.selectedPurchaseOrder.EffectiveDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      expirationDate: props.selectedPurchaseOrder && !props.duplicatePO ?
        moment(props.selectedPurchaseOrder.ExpirationDate).format('YYYY-MM-DD') : '',
      loadLimit: props.selectedPurchaseOrder ? props.selectedPurchaseOrder.LoadLimit : '',
      additionalComments: props.selectedPurchaseOrder ? props.selectedPurchaseOrder.AdditionalComments : '',
      poType: props.selectedPurchaseOrder ? props.selectedPurchaseOrder.POType : 'Purchase',
      material: '',
      type: '',
      flag: '',
      price: '',
      estWeight: '',
      billType: '',
      numberOfLoads: '',
      addButtonPressed: false,
      supplementalPricing: props.selectedPurchaseOrder ? Object.values(props.selectedPurchaseOrder.SupplementalPricing).flat() : [],
    },
    validateOnBlur: stage === 1 ? false : true,
    validationSchema: (() => {
      if (stage === 0) {
        return yup.object().shape({
          location: yup.object().nullable().exists('Location is a required field.'),
          contact: yup.object().nullable().exists('Contact is a required field.'),
          active: yup.bool().required('Active is a required field.'),
          termsAndConditions: yup.bool().required('Terms and Conditions is a required field.'),
          terms: yup.string().required('Terms is a required field.'),
          fob: yup.string().required('FOB is a required field.'),
          poType: yup.string().required('Type is a required field.'),
          effectiveDate: yup.date().required('Effective Date is a required field.'),
          expirationDate: yup.date().required('Expiration Date is a required field.')
            .test({
              name: 'After Effective',
              test: function(value) {
                if (value && moment(value).diff(moment(this.parent.effectiveDate)) < 0) {
                  return false;
                }
                else return true;
              },
              message: 'Expiration Date must be after Effective Date.',
            })
            .test({
              name: 'one year',
              test: function(value) {
                if (value && moment(value).diff(moment(this.parent.effectiveDate)) > 31622400000) {
                  return false;
                }
                else return true;
              },
              message: 'Expiration Date must be within one year of Effective Date.',
            })
            .test({
              name: 'expires before material profile',
              test: function(value) {
                if (value && !moment(value).isBetween(moment(this.parent.effectiveDate), moment(props.activeMaterialProfile.Expiration))) {
                  return false;
                }
                else return true;
              },
              message: 'Expiration Date must be within the expiration date of the active material profile.',
            }),
          loadLimit: yup.number().min(0, 'Load Limit cannot be negative.'),
          additionalComments: yup.string(),
        });
      }
      else if (stage === 1) {
        return yup.object().shape({
          material: yup.mixed().when('addButtonPressed', {
            is: value => value,
            then: yup.object().nullable().exists('Material is a required field.'),
            otherwise: yup.mixed().doesNotExist('Please clear field before submission.'),
          }),
          type: yup.mixed().when('addButtonPressed', {
            is: value => value,
            then: yup.object().nullable().exists('Type is a required field.'),
            otherwise: yup.mixed().doesNotExist('Please clear field before submission.'),
          }),
          flag: yup.mixed().test({
            name: 'clear flag',
            test: function(value) {
              if (!this.parent.addButtonPressed && value) {
                return false;
              }
              else return true;
            },
            message: 'Please clear field before submission.',
          }).test({
            name: 'required Flag',
            test: function(value) {
              if (!value && this.parent.material?.FlagStatus === 'Required') {
                return false;
              }
              else return true;
            },
            message: 'Flag is a required field.',
          }),
          price: yup.mixed().when('addButtonPressed', {
            is: value => value,
            then: yup.string().required('Price is a required field.').priceCheck(),
            otherwise: yup.mixed().doesNotExist('Please clear field before submission.'),
          }),
          billType: yup.mixed().when('addButtonPressed', {
            is: value => value,
            then: yup.string().required('Bill Type is a required field.'),
            otherwise: yup.mixed().doesNotExist('Please clear field before submission.'),
          }),
          estWeight: yup.mixed().when('addButtonPressed', {
            is: value => value,
            then: yup.number().required('Est Weight is a required field.').min(1, 'Est. Weight must be greater than 0.'),
            otherwise: yup.mixed().doesNotExist('Please clear field before submission.'),
          }),
          numberOfLoads: yup.number().min(0, 'Number Of Loads cannot be negative.'),
        });
      }
    })(),
    onSubmit: (values, actions) => {
      if (stage === 0) {
        setStage(stage + 1);
        actions.setSubmitting(false);
        actions.setFieldTouched('material', false, false);
        actions.setFieldTouched('type', false, false);
        actions.setFieldTouched('flag', false, false);
        actions.setFieldTouched('price', false, false);
        actions.setFieldTouched('estWeight', false, false);
        actions.setFieldTouched('billType', false, false);
        actions.setFieldTouched('numberOfLoads', false, false);
        return;
      }
      else if (stage === 1) {
        if (values.addButtonPressed) {
          if (approvedTypes.find(approved => approved.material === values.material && approved.type === values.type && approved.flag === values.flag)) {
            // eslint-disable-next-line max-len
            setArrayWarning(`${values.material.UnitDetails.MaterialName} ${values.type.TypeName} ${values.flag ? `- ${values.flag.Name}` : ''} is already in the approved types.`);
            return actions.setSubmitting(false);
          }
          else setArrayWarning('');
          setApprovedTypes([...approvedTypes, {
            material: values.material,
            type: values.type,
            flag: values.flag,
            price: values.price,
            estWeight: values.estWeight,
            billType: values.billType,
            numberOfLoads: values.numberOfLoads,
          }]);
          actions.setSubmitting(false);
          actions.setFieldValue('material', '', false);
          actions.setFieldValue('type', '', false);
          actions.setFieldValue('flag', '', false);
          actions.setFieldValue('price', '', false);
          actions.setFieldValue('estWeight', '', false);
          actions.setFieldValue('billType', '', false);
          actions.setFieldValue('numberOfLoads', '', false);
          actions.setFieldValue('addButtonPressed', false, false);
          actions.setFieldTouched('material', false, false);
          actions.setFieldTouched('type', false, false);
          actions.setFieldTouched('flag', false, false);
          actions.setFieldTouched('price', false, false);
          actions.setFieldTouched('estWeight', false, false);
          actions.setFieldTouched('billType', false, false);
          actions.setFieldTouched('numberOfLoads', false, false);
          return;
        }
        else {
          if (!approvedTypes.length) {
            actions.setSubmitting(false);
            setArrayWarning('There must be at least one approved type.');
            return;
          }
          actions.setSubmitting(false);
          setStage(stage + 1);
          return;
        }
      }
      else {
        const dataForFirestore = createFirestoreData(values);
        if (props.selectedPurchaseOrder && !props.duplicatePO) {
          // if editing an existing PO
          Firestore.collection('CRM-Accounts')
            .doc(props.selectedAccount.AccountId)
            .collection('Purchase-Orders')
            .doc(props.selectedPurchaseOrder.id)
            .update({
              ...dataForFirestore,
              'System.ModifiedOn': new Date(),
              'System.ModifiedBy': {Name: currentUser.displayName, Id: currentUser.uid, Email: currentUser.email},
            })
            .then(() => {
              setStage(stage + 1);
              actions.setSubmitting(false);
            }).catch(error => {
              setFormError('There was an error during submission. Please try again.');
              actions.setSubmitting(false);
            });
        }
        else {
          // creating a new PO
          Firestore.runTransaction(transaction => {
            const counterRef = Firestore.collection('Counters').doc('CRM-Purchase-Orders');
            const purchaseOrderRef = Firestore.collection('CRM-Accounts')
              .doc(props.selectedAccount.AccountId)
              .collection('Purchase-Orders')
              .doc();
            return transaction.get(counterRef).then(counter => {
              const newCount = counter.data().Count + 1;
              let countWithZeros = newCount + '';
              while (countWithZeros.length < 4) countWithZeros = 0 + countWithZeros;
              transaction.set(counterRef, {Count: newCount});
              return transaction.set(purchaseOrderRef, {
                ...dataForFirestore,
                System: {
                  CreatedBy: {Name: currentUser.displayName, Id: currentUser.uid, Email: currentUser.email},
                  CreatedOn: new Date(),
                },
                OrderNumber: `${new Date().getFullYear()}-${countWithZeros}`,
              });
            });
          }).then(() => {
            setStage(stage + 1);
            actions.setSubmitting(false);
          }).catch(error => {
            setFormError('There was an error during submission. Please try again.');
            actions.setSubmitting(false);
          });
        }
      }
    },
  };

  const fieldProps = {
    contactField: {
      name: 'contact',
      label: 'Contact',
      fast: true,
      options: approvedContacts,
      optionKey: 'Name.Full',
      disableClearable: true,
      required: true,
    },
    locationField: {
      name: 'location',
      label: 'Location',
      fast: true,
      options: locations[props.selectedAccount.AccountId],
      optionKey: 'LocationName',
      disableClearable: true,
      required: true,
    },
    materialField: {
      name: `material`,
      label: 'Material',
      required: true,
      options: approvedMaterials,
      optionKey: 'UnitDetails.MaterialName',
      onChange: ({field, form}) => {
        form.setFieldValue(`type`, '');
        form.setFieldValue(`flag`, '');
      },
    },
    flagField: formikProps => ({
      name: `flag`,
      label: 'Flag',
      required: formikProps.values.material?.FlagStatus === 'Required' ? true : false,
      options: formikProps.values.material ? formikProps.values.material.Flags : [],
      optionKey: 'Name',
      loading: !formikProps.values.material,
      loadingText: 'Select a material to populate',
      noOptionsText: 'This material has no flags',
    }),
    typeField: formikProps => ({
      name: `type`,
      label: 'Type',
      required: true,
      options: formikProps.values.material ? types[formikProps.values.material.MaterialId] : [],
      optionKey: 'TypeName',
      loading: !formikProps.values.material,
      loadingText: 'Select a material to populate',
    }),
    priceField: formikProps => ({
      name: 'price',
      label: 'Price',
      required: true,
      disabled: formikProps.values.billType === 'Market' || formikProps.values.billType === 'No Charge',
      onBlur: ({field, form}) => {
        const value = parseFloat(field.value);
        if (value && !isNaN(value)) {
          if (value > 0) {
            form.setFieldValue('price', value.toFixed(4), false);
          }
        }
      },
    }),
    billTypeField: {
      name: 'billType',
      label: 'Bill Type',
      fast: true,
      required: true,
      onChange: ({event, form}) => {
        if (event.target.value === 'Market') {
          form.setFieldValue('price', 'N/A');
        }
        else if (event.target.value === 'No Charge') {
          form.setFieldValue('price', '0.00');
        }
        else if (event.target.value === '') {
          form.setFieldValue('price', '');
        }
      },
    },
  };

  const core = {
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'lg',
      fullWidth: true,
    },
    buttonProps: {
      handleBack: () => setStage(stage - 1),
      close: props.close,
      submitButtonProps: {
        text: stageArray[stage] !== 'supplemental materials' ? 'Next' : 'Submit',
      },
      cancelButtonProps: {},
      backButtonProps: {},
      closeButtonProps: {},
      stage: stageArray[stage],
    },
    submitConfirmation: {
      text: 'Purchase Order successfully created.',
      stage: stageArray[stage],
    },
    addButton: formikProps => ({
      color: 'primary',
      size: 'small',
      variant: 'text',
      onClick: () => {
        formikProps.setFieldValue('addButtonPressed', true, false);
        setTimeout(() => {
          formikProps.handleSubmit();
        }, 0);
      },
    }),
  };

  const tableProps = setFieldValue => ({
    data: approvedTypes,
    defaultSort: {key: 'material.UnitDetails.MaterialName', desc: false},
    columns: [
      {accessor: 'material.UnitDetails.MaterialName', Header: 'Material'},
      {accessor: 'type.TypeName', Header: 'Type'},
      {accessor: 'flag.Name', Header: 'Flag'},
      {accessor: 'price', Header: 'Price'},
      {accessor: 'billType', Header: 'Bill Type'},
      {accessor: 'estWeight', Header: 'Est Weight'},
      {accessor: 'numberOfLoads', Header: 'No. Of Loads'},
    ],
    actionsPerRow: [
      {
        icon: DeleteForever,
        tooltip: 'Remove Line from PO',
        onClick: ({rowIndex}) => {
          setApprovedTypes([
            ...approvedTypes.slice(0, rowIndex),
            ...approvedTypes.slice(rowIndex + 1),
          ]);
        },
      },
      {
        icon: Edit,
        tooltip: 'Edit Line',
        onClick: ({rowData, rowIndex}) => {
          setFieldValue('material', rowData.material);
          setFieldValue('type', rowData.type);
          setFieldValue('billType', rowData.billType);
          setFieldValue('estWeight', rowData.estWeight);
          setFieldValue('price', rowData.price);
          setFieldValue('numberOfLoads', rowData.numberOfLoads);
          setFieldValue('flag', rowData.flag);
          setApprovedTypes([
            ...approvedTypes.slice(0, rowIndex),
            ...approvedTypes.slice(rowIndex + 1),
          ]);
        },
      },
    ],
    paginationActive: false,
  });

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {formikProps => (
          <Fragment>
            {stageArray[stage] !== 'success' && <DialogTitle>Purchase Order</DialogTitle>}
            <DialogContent>
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>Enter general purchase order information.</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <AutoCompleteObject {...fieldProps.locationField} />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <AutoCompleteObject {...fieldProps.contactField} />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <SelectField name='poType' label='Type' required>
                      <MenuItem value='Purchase'>Purchase</MenuItem>
                      <MenuItem value='Sale'>Sale</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DateField name='effectiveDate' label='Effective Date' required fast />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DateField name='expirationDate' label='Expiration Date' required fast />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <NumberField name='loadLimit' label='Load Limit' fast />
                  </Grid>
                  <Grid item xs={6} md={props.selectedPurchaseOrder ? 3 : 4}>
                    <SelectField name='termsAndConditions' label='Include Terms And Conditions?' required fast>
                      <MenuItem key='true' value={true}>Yes</MenuItem>
                      <MenuItem key='false' value={false}>No</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={6} md={props.selectedPurchaseOrder ? 3 : 4}>
                    <SelectField name='fob' label='FOB' required fast>
                      <MenuItem key='Destination' value='Destination'>Destination</MenuItem>
                      <MenuItem key='Origin' value='Origin'>Origin</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={6} md={props.selectedPurchaseOrder ? 3 : 4}>
                    <SelectField name='terms' label='Terms' required fast>
                      <MenuItem key='Due on receipt' value='Due on receipt'>Due on receipt</MenuItem>
                      <MenuItem key='Net 10' value='Net 10'>Net 10</MenuItem>
                      <MenuItem key='Net 15' value='Net 15'>Net 15</MenuItem>
                      <MenuItem key='Net 30' value='Net 30'>Net 30</MenuItem>
                      <MenuItem key='Net 45' value='Net 45'>Net 45</MenuItem>
                      <MenuItem key='Net 60' value='Net 60'>Net 60</MenuItem>
                    </SelectField>
                  </Grid>
                  {props.selectedPurchaseOrder && <Grid item xs={6} md={3}>
                    <SelectField name='active' label='Active?' required fast>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </SelectField>
                  </Grid>}
                  <Grid item xs={12}>
                    <TextField name='additionalComments' label='Comments' fast multiline />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stageArray[stage] === 'pricing details'}>
                <DialogContentText>Select desired types and their corresponding information.</DialogContentText>
                <Grid container spacing={2}>
                  <Grid container alignItems='center' justify='center' spacing={2} item xs={12}>
                    <Grid item xs={10} md={11} container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <AutoCompleteObject {...fieldProps.materialField} />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <AutoCompleteObject {...fieldProps.typeField(formikProps)} />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <AutoCompleteObject {...fieldProps.flagField(formikProps)} />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField {...fieldProps.priceField(formikProps)} />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <SelectField {...fieldProps.billTypeField} >
                          <MenuItem value=''>
                            (Clear)
                          </MenuItem>
                          <MenuItem value='Charge'>
                            Charge
                          </MenuItem>
                          <MenuItem value='Payment'>
                            Payment
                          </MenuItem>
                          <MenuItem value='No Charge'>
                            No Charge
                          </MenuItem>
                          <MenuItem value='Market'>
                            Market
                          </MenuItem>
                        </SelectField>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <WeightField name={`estWeight`} label='Est. Weight' fast required />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <NumberField name={`numberOfLoads`} label='Number of Loads' fast />
                      </Grid>
                    </Grid>
                    <Grid item xs={2} md={1}>
                      <FormButton {...core.addButton(formikProps)}><Add /></FormButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {approvedTypes.length ? (
                      <Fragment>
                        <Typography variant='h6'>Approved Types</Typography>
                        <Table {...tableProps(formikProps.setFieldValue)} />
                      </Fragment>
                    ) : null}
                  </Grid>
                </Grid>
                <Alert text={arrayWarning} in={Boolean(arrayWarning)} severity='error' />
              </Collapse>
              <Collapse in={stageArray[stage] === 'supplemental materials'}>
                <DialogContentText>Select material types to include gate pricing.</DialogContentText>
                <Grid container spacing={2}>
                  {typesForCheckboxes.map(types => (
                    <CheckboxForm typesForBoxes={types} key={types[0].materialName} setFieldValue={formikProps.setFieldValue}
                      currentCheckboxValue={formikProps.values.supplementalPricing}
                    />
                  ))}
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert text={formError} in={Boolean(formError)} severity='error' />
            </DialogContent>
            <DefaultModalButtons handleSubmit={() => formikProps.setFieldValue('addButtonPressed', false, false)}
              isSubmitting={formikProps.isSubmitting} {...core.buttonProps}
            />
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

function createMapOfSupplementalPrices(listOfTypeIds, reduxTypes) {
  const mapOfSupplementalPrices = {};
  listOfTypeIds.forEach(typeId => {
    for (const materialId in reduxTypes) {
      if (materialId === 'listener') continue;
      if (reduxTypes[materialId].find(type => type.id === typeId)) {
        if (mapOfSupplementalPrices[materialId]) {
          mapOfSupplementalPrices[materialId].push(typeId);
        }
        else mapOfSupplementalPrices[materialId] = [typeId];
      }
    }
  });
  return mapOfSupplementalPrices;
}
function formatApprovedTypesForFirestore(approvedTypes) {
  const typeAndFlagMap = {};
  approvedTypes.forEach(type => {
    if (typeAndFlagMap[type.type.id]) {
      typeAndFlagMap[type.type.id][type.flag?.Name || 'none'] = {
        BillType: type.billType,
        EstWeight: type.estWeight,
        Flag: type.flag?.Name || '',
        LoadNumber: type.numberOfLoads,
        Material: type.material.UnitDetails.MaterialName,
        MaterialId: type.material.MaterialId,
        Price: type.price,
        TypeId: type.type.id,
        TypeName: type.type.TypeName,
      };
    }
    else {
      typeAndFlagMap[type.type.id] = {[type.flag?.Name || 'none']: {
        BillType: type.billType,
        EstWeight: type.estWeight,
        Flag: type.flag?.Name || '',
        LoadNumber: type.numberOfLoads,
        Material: type.material.UnitDetails.MaterialName,
        MaterialId: type.material.MaterialId,
        Price: type.price,
        TypeId: type.type.id,
        TypeName: type.type.TypeName,
      }};
    }
  });
  return typeAndFlagMap;
}
function formatApprovedTypesFromFirestoreToState(approvedTypes, {materials, types}) {
  return Object.values(approvedTypes).map(flagObj => Object.values(flagObj).map(firestoreItem => ({
    billType: firestoreItem.BillType,
    estWeight: firestoreItem.EstWeight,
    flag: materials.ref[firestoreItem.MaterialId].Flags?.find(flag => flag.Name === firestoreItem.Flag) || '',
    numberOfLoads: firestoreItem.LoadNumber,
    material: materials.ref[firestoreItem.MaterialId],
    type: types[firestoreItem.MaterialId].find(type => type.id === firestoreItem.TypeId),
    price: firestoreItem.Price,
  }))).flat(1);
}

PurchaseOrderModal.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeMaterialProfile: PropTypes.object,
  modalOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selectedAccount: PropTypes.object.isRequired,
  duplicatePO: PropTypes.bool,
  selectedPurchaseOrder: PropTypes.object,
};

export default PurchaseOrderModal;
