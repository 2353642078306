import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Formik, Alert} from '@kbi/component-library';
import {formatSingleLineAddress, formatDoubleLineAddress, formatTripleLineAddress} from '@kbi/utility-library';
import {SubmitConfirmation} from 'components/';
import {Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Grid, Button, MenuItem} from '@material-ui/core';
import {AddressForm} from '../../components/';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {countries, industries} from '../../views/Accounts/lists.js';
import {Firestore} from 'config.js';

const {FormikForm, SubmitButton, FormButton, TextField, AutoComplete, AutoCompleteObject,
  PhoneNumberField, validatePhoneField, SelectField, validateAutoObject} = Formik;
validatePhoneField();
validateAutoObject();

const LeadInfoModal = ({close, selectedLead}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const currentUser = useSelector(state => state.auth.currentUser);
  const users = useSelector(state => state.firestore.users);

  const handleBack = () => {
    return setStage(stage - 1);
  };
  const dialogProps = {
    open: true,
    scroll: 'body',
    transitionDuration: {exit: 0},
    fullWidth: true,
  };
  const submitConfirmation = {
    text: selectedLead ? 'Lead successfully updated.' : 'Lead successfully added.',
    stage: stage === 4 ? 'success' : 'not success',
  };
  const formikProps = {
    initialValues: {
      accountName: selectedLead?.AccountName || '',
      phonePrimary: selectedLead?.Phone.Primary || '',
      phoneEmergency: selectedLead?.Phone.Emergency ||'',
      phoneFax: selectedLead?.Phone.Fax || '',
      phoneMobile: selectedLead?.Phone.Mobile || '',
      addressCountry: selectedLead?.Address.Country || '',
      addressLine1: selectedLead?.Address.Line1 || '',
      addressLine2: selectedLead?.Address.Line2 || '',
      addressCity: selectedLead?.Address.City || '',
      addressState: selectedLead?.Address.State || '',
      addressPostal: selectedLead?.Address.PostalCode || '',
      addressProvince: selectedLead?.Address.Province || '',
      addressPrefecture: selectedLead?.Address.Prefecture || '',
      addressUnformatted: selectedLead?.Address.SingleLine || '',
      active: selectedLead?.Active !== undefined ? selectedLead.Active : true,
      contactName: selectedLead?.Contact.Name || '',
      contactEmail: selectedLead?.Contact.Email || '',
      accountManager: selectedLead ? users.find(user => user.uid === selectedLead.Manager.Uid) : '',
      website: selectedLead?.Website || '',
      industry: selectedLead?.Industry || '',
    },
    validationSchema: (() => {
      if (stage === 0) {
        return yup.object().shape({
          contactName: yup.string().required('Contact Name is a required field.'),
          contactEmail: yup.string().email('Must be a correctly formatted email.'),
          accountName: yup.string().required('Account Name is a required field.'),
          website: yup.string().matches(urlRegex, 'Must be a valid url.'),
        });
      }
      else if (stage === 1) {
        return yup.object().shape({
          addressLine1: yup.string().when('addressCountry', {
            is: value => value === 'United States' || value === 'Mexico' || value === 'Japan' || value === 'South Korea' || value === 'Canada',
            then: yup.string().required('Address Line 1 is a required field.'),
            otherwise: yup.string(),
          }),
          addressLine2: yup.string(),
          addressCity: yup.string(),
          addressState: yup.string(),
          addressPostal: yup.string(),
          addressProvince: yup.string(),
          addressSubdivision: yup.string(),
          addressPrefecture: yup.string(),
          addressUnformatted: yup.string(),
        });
      }
      else if (stage === 2) {
        return yup.object().shape({
          phoneEmergency: yup.string().validPhoneNumber('Must be a valid phone number.'),
          phoneFax: yup.string().validPhoneNumber('Must be a valid phone number.'),
          phoneMobile: yup.string().validPhoneNumber('Must be a valid phone number.'),
          phonePrimary: yup.string().validPhoneNumber('Must be a valid phone number.'),
        });
      }
      else if (stage === 3) {
        return yup.object().shape({
          accountManager: yup.object().nullable().exists('Manager is a required field.'),
          industry: yup.string().required('Industry is a required field.'),
        });
      }
    })(),
    onSubmit: (values, actions) => {
      if (stage !== 3) {
        setStage(stage + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
        return;
      }
      const dataToSubmit = createDataForFirestore(values);
      const leadRef = selectedLead ? Firestore.collection('CRM-Leads').doc(selectedLead.LeadId) :
        Firestore.collection('CRM-Leads').doc();

      if (selectedLead) {
        dataToSubmit.System = {
          ...selectedLead.System,
          ModifiedOn: new Date(),
          ModifiedBy: currentUser.displayName,
        };
      }
      else {
        dataToSubmit.System = {
          CreatedBy: currentUser.displayName,
          CreatedOn: new Date(),
        };
      }

      leadRef.set(dataToSubmit, {merge: true}).then(() => {
        actions.setSubmitting(false);
        setFormError('');
        setStage(stage + 1);
      }).catch(error => {
        actions.setSubmitting(false);
        setFormError('There was an error during submission. Please try again.');
      });
    },
  };
  const countryField = {
    name: 'addressCountry',
    label: 'Country',
    options: countries,
    optionKey: 'value',
    onChange: ({form}) => {
      form.setFieldValue('addressLine1', '');
      form.setFieldValue('addressLine2', '');
      form.setFieldValue('addressCity', '');
      form.setFieldValue('addressState', '');
      form.setFieldValue('addressPostal', '');
      form.setFieldValue('addressProvince', '');
      form.setFieldValue('addressSubdivision', '');
      form.setFieldValue('addressPrefecture', '');
      form.setFieldValue('addressUnformatted', '');
    },
  };
  const accountManagerField = {
    name: 'accountManager',
    label: 'Account Manager',
    options: users,
    optionKey: 'displayName',
    required: true,
  };

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formikProps}>
        {() => (
          <Fragment>
            {stage !== 4 && <DialogTitle>{selectedLead ? 'Edit Lead' : 'Add Lead'}</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>Enter lead&#39;s primary contact information:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField name='contactName' label='Contact Name' placeholder={'First and/or Last'} required fast />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField name='contactEmail' label='Email' placeholder={'example@company.com'} fast />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name='accountName' label='Account Name' required fast />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField name='website' label='Website' placeholder={'http://www.domain.com/'} fast />
                  </Grid>
                  {selectedLead ? (
                    <Grid item xs={6}>
                      <SelectField name='active' label='Active' required fast>
                        <MenuItem key={true} value={true}>
                        True
                        </MenuItem>
                        <MenuItem key={false} value={false}>
                        False
                        </MenuItem>
                      </SelectField>
                    </Grid>
                  ) : null}
                </Grid>
              </Collapse>
              <Collapse in={stage === 1}>
                <DialogContentText>Enter lead&#39;s primary place of business (Not required):</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AutoComplete {...countryField} />
                  </Grid>
                  <AddressForm />
                </Grid>
              </Collapse>
              <Collapse in={stage === 2}>
                <DialogContentText>Enter lead&#39;s relevant phone numbers:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <PhoneNumberField name='phonePrimary' label='Primary No.' />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneNumberField name='phoneMobile' label='Mobile No.' />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneNumberField name='phoneEmergency' label='Emergency No.' />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneNumberField name='phoneFax' label='Fax No.' />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 3}>
                <DialogContentText>Enter lead&#39;s account manager and industry:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <AutoCompleteObject {...accountManagerField} fast />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectField name='industry' label='Industry' required fast>
                      {industries.map(industry => (
                        <MenuItem key={industry} value={industry}>
                          {industry}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} />
            </DialogContent>
            {stage === 4 ? (
              <DialogActions>
                <Button variant='text' onClick={close} color='primary'>Close</Button>
              </DialogActions>
            ) : (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <div>
                  <FormButton variant='text' color='primary' disabled={stage === 0} onClick={handleBack}>Back</FormButton>
                  <SubmitButton variant='text' color='primary'>{stage === 3 ? 'Submit' : 'Next'}</SubmitButton>
                </div>
              </DialogActions>
            )}

          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

function createDataForFirestore(values) {
  const submitData = {
    AccountName: values.accountName,
    Phone: {
      Primary: values.phonePrimary,
      Emergency: values.phoneEmergency,
      Fax: values.phoneFax,
      Mobile: values.phoneMobile,
    },
    Contact: {
      Name: values.contactName,
      Email: values.contactEmail,
    },
    Active: values.active,
    Converted: false,
    Website: values.website,
    Manager: {
      Name: values.accountManager.displayName,
      Email: values.accountManager.email,
      Uid: values.accountManager.uid,
    },
    Industry: values.industry,
  };
  if (values.addressCountry === 'United States' || values.addressCountry === 'Mexico') {
    submitData.Address = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      City: values.addressCity,
      State: values.addressState,
      PostalCode: values.addressPostal,
      Country: values.addressCountry,
    };
  }
  else if (values.addressCountry === 'Japan') {
    submitData.Address = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      Prefecture: values.addressPrefecture,
      Province: values.addressProvince,
      Country: values.addressCountry,
    };
  }
  else if (values.addressCountry === 'South Korea') {
    submitData.Address = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      City: values.addressCity,
      Subdivision: values.addressSubdivision,
      Province: values.addressProvince,
      Country: values.addressCountry,
    };
  }
  else if (values.addressCountry === 'Canada') {
    submitData.Address = {
      Line1: values.addressLine1,
      Line2: values.addressLine2,
      City: values.addressCity,
      Province: values.addressProvince,
      PostalCode: values.addressPostal,
      Country: values.addressCountry,
    };
  }
  else {
    submitData.Address = {
      Unformatted: values.addressUnformatted,
      Country: values.addressCountry,
    };
  }
  submitData.Address.SingleLine = formatSingleLineAddress(submitData.Address);
  submitData.Address.DoubleLine = formatDoubleLineAddress(submitData.Address);
  submitData.Address.TripleLine = formatTripleLineAddress(submitData.Address);

  return submitData;
}

// eslint-disable-next-line max-len
const urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

LeadInfoModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedLead: PropTypes.object,
};

export default LeadInfoModal;
