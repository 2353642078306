import React, {Fragment} from 'react';
import {Grid, Box, TextField} from '@material-ui/core';
import PropTypes from 'prop-types';

const ApprovedTypesTable = props => {
  const {selectedPurchaseOrder, classes, StyledTypography} = props;
  const arrayOfTypes = Object.values(selectedPurchaseOrder.ApprovedTypes).map(typeObj => Object.values(typeObj)).flat(1);

  function createTypeList(type, index) {
    return (
      <Grid container className={classes.avoidPageBreak} key={index}>
        <Box width={'7.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
          <StyledTypography variant='body2'>{type.LoadNumber}</StyledTypography>
        </Box>
        <Box width={'32.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
          <StyledTypography variant='body2'>{type.Material}</StyledTypography>
        </Box>
        <Box width={'27.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
          <StyledTypography variant='body2'>{`${type.TypeName} ${type.Flag && `(${type.Flag})`}`}</StyledTypography>
        </Box>
        <Box width={'12.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
          <StyledTypography variant='body2'>{type.EstWeight}</StyledTypography>
        </Box>
        <Box width={'10%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
          <StyledTypography variant='body2'>{type.BillType}</StyledTypography>
        </Box>
        <Box width={'10%'} className={classes.borderBottom}>
          <StyledTypography variant='body2'>
            {!isNaN(type.Price) && '$'}
            {type.Price}
          </StyledTypography>
        </Box>
      </Grid>
    );
  }
  const createTableContainer = (pageIndex) => {
    const tableCore = {
      pageDelimeterProps: {
        className: pageIndex === 0 ? 'firstTable' : 'secondTable newPage',
      },
    };
    return (
      <div key={pageIndex} {...tableCore.pageDelimeterProps}>
        <div
          className={`${classes.borderTop} ${classes.borderLeft} ${classes.newSection} ${classes.borderRight} ${
            classes.textChildrenPadding
          }`}
        >
          <Grid item xs={12}>
            <StyledTypography variant='subtitle2' className={`${classes.bold} ${classes.titleSection}`}>
              {pageIndex === 0 ? 'Pricing Details' : 'Pricing Details Continued'}
            </StyledTypography>
          </Grid>
          <Grid container className={classes.tableHeader}>
            <Box width={'7.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Loads
              </StyledTypography>
            </Box>
            <Box width={'32.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Material
              </StyledTypography>
            </Box>
            <Box width={'27.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Type
              </StyledTypography>
            </Box>
            <Box width={'12.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Est. Weight
              </StyledTypography>
            </Box>
            <Box width={'10%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Bill Type
              </StyledTypography>
            </Box>
            <Box width={'10%'} className={classes.borderBottom}>
              <StyledTypography variant='body2' className={classes.bold}>
                Amount
              </StyledTypography>
            </Box>
          </Grid>
          {arrayOfTypes.slice((pageIndex * 13), (pageIndex === 0 ? 13 : Infinity)).map((type, index) => {
            return createTypeList(type, index);
          })}
        </div>
      </div>);
  };
  const renderAllPages = () => {
    if (arrayOfTypes.length > 13) {
      return [createTableContainer(0), <div key='randomKeyString' className={classes.pageBreak} />, createTableContainer(1)];
    }
    else {
      return createTableContainer(0);
    }
  };
  const textField = adornment => ({
    fullWidth: true,
    style: {
      padding: '10px',
    },
    marign: 'dense',
    helperText: adornment,
    InputProps: {
      readOnly: true,
      classes: {input: classes.smallInputFont},
    },
  });

  return (
    <Fragment>
      <div
        className={`${classes.borderTop} ${classes.borderLeft} ${classes.newSection} ${classes.borderRight} 
        ${classes.textChildrenPadding} ${classes.borderBottom}`}
      >
        <Grid item xs={12}>
          <StyledTypography variant='subtitle2' className={`${classes.bold} ${classes.titleSection}`}>
        Signature
          </StyledTypography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField {...textField('KBI Signature')} />
          </Grid>
          <Grid item xs={6}>
            <TextField {...textField('Customer Signature')} />
          </Grid>
        </Grid>
      </div>
      {renderAllPages()}
    </Fragment>
  );
};

ApprovedTypesTable.propTypes = {
  selectedPurchaseOrder: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  StyledTypography: PropTypes.object.isRequired,
};

export default ApprovedTypesTable;
