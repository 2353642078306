import React from 'react';
import {
  ProfileToPdf,
  AccountDetails,
  Accounts,
  Contacts,
  ContactDetails,
  Leads,
  LeadDetails,
  Reports,
  PurchaseOrderToPdf,
} from './views/';
import {Businessman} from './components/';
import {Assessment, Business, Person} from '@material-ui/icons';

export const routes = [
  {component: () => <PurchaseOrderToPdf />, exact: true, strict: true, path: '/accounts/:AccountId/purchase-order/:PurchaseOrderId', pageTitle: 'Accounts - Purchase Order'},
  {component: () => <ProfileToPdf />, exact: true, strict: true, path: '/accounts/:AccountId/material-profile/:ProfileId', pageTitle: 'Accounts - Material Profile'},
  {component: () => <AccountDetails />, exact: true, strict: true, path: '/accounts/:AccountId', pageTitle: 'Accounts'},
  {component: () => <Accounts />, exact: true, strict: true, path: '/accounts', pageTitle: 'Accounts'},
  {component: () => <Contacts />, exact: true, strict: true, path: '/contacts', pageTitle: 'Contacts'},
  {component: () => <ContactDetails />, exact: true, strict: true, path: '/contacts/:ContactId', pageTitle: 'Contacts'},
  {component: () => <Leads />, exact: true, strict: true, path: '/leads', pageTitle: 'Leads'},
  {component: () => <LeadDetails />, exact: true, strict: true, path: '/leads/:LeadId', pageTitle: 'Leads'},
  {component: () => <Reports />, exact: true, strict: true, path: '/reports', pageTitle: 'Reports'},
];

export const menuItems = [
  {icon: () => <Business />, text: 'Accounts', link: '/accounts'},
  {icon: () => <Person />, text: 'Contacts', link: '/contacts'},
  {
    icon: () => <Businessman />, text: 'Leads', link: '/leads',
  },
  {icon: () => <Assessment />, text: 'Reports', link: '/reports'},
];

export const moduleMenuOptions = [
  {title: 'Entry', path: 'https://auth.kbi.works'},
  {title: 'EHS', path: 'https://ehs.kbi.works'},
  {title: 'Tracking', path: 'https://tracking.kbi.works'},
];
