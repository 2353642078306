import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import {Table} from '@kbi/component-library';
import {Search, CloudUpload} from '@material-ui/icons';
import {Storage} from 'config.js';

const FileViewModal = props => {
  const {open, close, files, selectedPurchaseOrder, classes, selectedInbound, selectedOutbound} = props;

  const core = {
    closeButton: {
      color: 'primary',
      onClick: () => {
        close();
      },
    },
    dialog: {
      open,
      scroll: 'body',
      transitionDuration: {exit: 0},
      fullWidth: true,
      maxWidth: 'md',
    },
    uploadButton: {
      variant: 'outlined',
      size: 'small',
      onClick: props.uploadButtonOnClick,
    },
    tableProps: {
      columns: [
        {accessor: 'Date', Header: 'Upload Date', type: 'datetime'},
        {accessor: 'FileName', Header: 'File Name'},
        {accessor: 'Owner.Name', Header: 'Owner'},
      ],
      sortInitial: [{id: 'Date', desc: false}],
      data: files,
      paginationActive: false,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View uploaded file',
          onClick: ({rowData}) => {
            let storageRef = null;
            if (selectedPurchaseOrder) {
              storageRef = Storage.ref(`Purchase_Orders/${selectedPurchaseOrder.id}/${rowData.id}.${rowData.FileName.split('.').pop()}`);
            }
            else if (selectedInbound) {
              storageRef = Storage.ref(`Inbound_Forms/${selectedInbound.id}/${rowData.FileName}`);
            }
            else if (selectedOutbound) {
              storageRef = Storage.ref(`Outbound_Forms/${selectedOutbound.id}/${rowData.FileName}`);
            }
            storageRef.getDownloadURL().then(url => {
              window.open(url, '__blank');
            });
          },
        },
      ],
      actionsBar: [
        {
          text: 'Upload',
          icon: CloudUpload,
          onClick: props.uploadButtonOnClick,
        },
      ],
    },
  };

  return (
    <Dialog {...core.dialog}>
      <DialogTitle>{selectedPurchaseOrder ? 'Uploaded Purchase Orders' : 'Supplemental Files'}</DialogTitle>
      <DialogContent>
        {files.length ? (
          <Fragment>
            <DialogContentText>Select a file you would like to view.</DialogContentText>
            <Table {...core.tableProps} />
          </Fragment>
        ) : (
          <Grid container justify='space-between'>
            <Typography variant='subtitle1'>
              {selectedPurchaseOrder ? 'No uploaded purchase orders found.' : 'No supplemental files found.'}
            </Typography>
            <Button {...core.uploadButton}>
              <CloudUpload className={classes.extendedIcon} />
                Upload
            </Button>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button {...core.closeButton}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

FileViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selectedPurchaseOrder: PropTypes.object,
  uploadButtonOnClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  selectedInbound: PropTypes.object,
  selectedOutbound: PropTypes.object,
  files: PropTypes.arrayOf(PropTypes.object), // this should be required, but if there are no files for the PO, null is passed as the prop
};
export default FileViewModal;
